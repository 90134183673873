export const useValidation = (formData, helpTexts) => {
  let errors = {};

  // Define constants for stake limits
  const MIN_STAKE = 10;
  const MAX_STAKE = 500000;

  // Updated validation rules using the constants
  const validation = {
    email: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
    password: /(?=(.*[0-9]))(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/,
    numbers: /^[0-9\b]+$/,
    limitedCharacters: /^.{42}$/,
    hash: /^[a-fA-F0-9]{32}/i,
    number: /^\d+(?:\.\d+)?$/,
    text: /^[-_a-zA-Z0-9]*[a-zA-Z]+[-_a-zA-Z0-9]*$/,
    address: /^0x[a-fA-F0-9]{40}$/,
    validStakeAmount: (num) => !isNaN(num) && num >= MIN_STAKE && num <= MAX_STAKE,
    invalid: (num) => false,
  };

  Object.keys(formData).forEach((key) => {
    const validationType = helpTexts[key]?.validationType;
    const validationRule = validation[validationType];
    const value = formData[key];

    // Ensuring that both validationType and validationRule exist
    if (!validationType || !validationRule) {
      console.error(`Validation type '${validationType}' for field '${key}' is not defined.`);
      return;
    }

    // Applying validation
    const isValid = typeof validationRule === "function" ?
                    validationRule(value) :
                    validationRule.test(value);

    if (value.length > 0) {
      errors[key] = isValid ? { success: helpTexts[key].success } : { failure: helpTexts[key].failure };
    }
  });

  return errors;
};
