import React, { useState, useEffect } from "react";
import { useResizable } from "react-resizable-layout";
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";

import styles from "./Trade.module.css";

const ChartResizer = ({ id = "drag-bar", dir, isDragging, ...props }) => {
  const [isFocused, setIsFocused] = useState(false);

  const cn = (...args) => args.filter(Boolean).join(" ");

  return (
    <div
      id={id}
      data-testid={id}
      tabIndex={0}
      className={cn(
        styles.dragBtn,
        "sample-drag-bar",
        dir === "horizontal" && "sample-drag-bar--horizontal",
        (isDragging || isFocused) && "sample-drag-bar--dragging"
      )}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      {...props}
    >
      <div></div>
      <div></div>
    </div>
  );
};

const TradeChart = () => {
  const {
    isDragging: isTerminalDragging,
    position: terminalH,
    separatorProps: terminalDragBarProps,
  } = useResizable({
    axis: "y",
    initial: 600,
    min: 300,
    max: 1700,
    reverse: true,
  });

  const [isChartEnabled, setIsChartEnabled] = useState(true);
  const [chart, setChart] = useState(null);

  const cn = (...args) => args.filter(Boolean).join(" ");

  useEffect(() => {
    const widgetChart = (
      <div
        className="mx-4 mb-14 mt-4 w-full xl:w-1/2 h-72 xl:pr-3 mb-4 mx-0 mt-0 md:w-full lg:px-3 md:px-1 px-3"
        style={{ pointerEvents: isChartEnabled ? "auto" : "none" }}
      >
        <AdvancedRealTimeChart theme="dark" autosize />
      </div>
    );
    setIsChartEnabled(!isTerminalDragging);
    setChart(widgetChart);
  }, [terminalH, isTerminalDragging, isChartEnabled]);

  return (
    <div
      className={cn(styles.chartContainer, isTerminalDragging && "dragging")}
      style={{
        height: terminalH,
      }}
    >
      <ChartResizer
        dir={"vertical"}
        isDragging={isTerminalDragging}
        {...terminalDragBarProps}
      />
      {chart}
    </div>
  );
};

export default TradeChart;
