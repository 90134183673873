import { useEffect, useState } from "react";
import { Scroll } from "scrollex";
import { MiniChart } from "react-tradingview-widget-components";
import { Link } from "react-router-dom";

import ABg from "../assets/img/landing/landingA.png";
import patternBg from "../assets/img/landing/patternBg.svg";
import patternBg2 from "../assets/img/landing/patternBg2.png";
import patternBg3 from "../assets/img/landing/pattern3.png";
import patternBg4 from "../assets/img/landing/pattern4.png";
import patternBg5 from "../assets/img/landing/pattern5.png";
import card1 from "../assets/img/landing/atr.svg";
import card2 from "../assets/img/landing/eth.svg";
import card3 from "../assets/img/landing/btc.svg";
import card4 from "../assets/img/landing/gold.svg";
import card5 from "../assets/img/landing/platinum.svg";
import contentBg1 from "../assets/img/landing/contentBg1.png";
import contentBg2 from "../assets/img/landing/contentBg2.png";
import contentBg3 from "../assets/img/landing/contentBg3.png";
import contentBg4 from "../assets/img/landing/contentBg4.png";
import contentBg5 from "../assets/img/landing/contentBg5.png";
import footerBg from "../assets/img/landing/footerBg.png";
import GetStartedBtnSvg from "../assets/svg/GetStartedBtnSvg";
import AlogoSvg from "../assets/svg/AlogoSvg";
import { keyframes, faq, howStart } from "../dummyComponents/landing";

import "./Landing.css";

const Landing = ({ handleConnect, translates }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeFaq, setActiveFaq] = useState(null);
  const [activeHowStart, setActiveHowStart] = useState(null);

  let openHowStart = (index) => {
    if (index !== activeHowStart) {
      setActiveHowStart(index);
    } else {
      setActiveHowStart(null);
    }
  };

  let openFaq = (index) => {
    if (index !== activeFaq) {
      setActiveFaq(index);
    } else {
      setActiveFaq(null);
    }
  };

  const spans = [
    translates?.gold.en,
    translates?.stocks.en,
    translates?.crypto.en,
    translates?.forex.en,
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % spans.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [spans.length]);

  return (
    <>
      <Scroll.Container
        style={{ height: "100vh" }}
        scrollAxis="y"
        className="h-screen"
      >
        <Scroll.Section className="h-screen center bg-1">
          <div className="landingSlideContainer">
            <img src={patternBg} alt="paternbg" className="patternBg" />
            <div style={{ pointerEvents: "none" }}>
              <Scroll.Item keyframes={keyframes.background}>
                <div className="landingSlideContainerLogoBg">
                  <img src={ABg} alt="bg" />
                </div>
              </Scroll.Item>
            </div>
            <div className="landingSlideContainerInner">
              <Scroll.Item keyframes={keyframes.opacity}>
                <div className="landingSlideContainerTitle">
                  <h1>
                    <span className="ttl">{translates?.landing_main.en}</span>
                    <span className="ttl">{translates?.landing_main2.en}</span>
                  </h1>
                  <div
                    className="landingSlideContainerBtn"
                    onClick={handleConnect}
                  >
                    <GetStartedBtnSvg />
                  </div>
                </div>
              </Scroll.Item>
            </div>
          </div>
          <div className="landingSlideContainerSecond">
            <Scroll.Item keyframes={keyframes.scaleOut}>
              <AlogoSvg />
              <p>{translates?.landing_second.en}</p>
            </Scroll.Item>
          </div>
          <div className="landingCardsContainer">
            <Scroll.Item keyframes={keyframes.opacityIn}>
              <div className="landingCardsContainerInner">
                <div className="landingCardBig landingCard">
                  <div className="logoWrap">
                    <p>AONE</p>
                    <img src={card1} alt="AONE_gard" />
                  </div>
                </div>
                <div className="landingCardLtls">
                  <div className="landingCard">
                    <img src={card2} alt="ltlcard1" />
                  </div>
                  <div className="landingCard">
                    <img src={card3} alt="ltlcard2" />
                  </div>
                  <div className="landingCard">
                    <img src={card4} alt="ltlcard3" />
                  </div>
                  <div className="landingCard">
                    <img src={card5} alt="ltlcard4" />
                  </div>
                </div>
              </div>
            </Scroll.Item>
          </div>
          <div className="landingLatestPrices landingLatestPricesReverse">
            <img src={patternBg4} alt="paternbg4" className="patternBg4" />
            <div className="landingLatestPricesDescr">
              <h2 className="ttl">{translates?.latest_market_prices.en}</h2>
              <p>{translates?.our_platform_provides_you_with_market.en}</p>
            </div>
            <div className="landingLatestPricesItems">
              <div className="landingLatestPricesItem">
                <MiniChart width="100%" symbol="BTCUSD"></MiniChart>
              </div>
              <div className="landingLatestPricesItem">
                <MiniChart width="100%" symbol="ETHUSD"></MiniChart>
              </div>
              <div className="landingLatestPricesItem">
                <MiniChart width="100%" symbol="XAUUSD"></MiniChart>
              </div>
              <div className="landingLatestPricesItem">
                <MiniChart width="100%"></MiniChart>
              </div>
            </div>
          </div>
          <div className="landingLatestPrices landingLatestPricesColumn">
            <div className="landingLatestPricesDescr">
              <h3 className="ttl">{translates?.embark_on_a.en}</h3>
              <h2 className="ttl">
                {translates?.limitless_trading_adventure.en}
              </h2>
              <p>{translates?.our_platform_is_your_steadfast_companion.en}</p>
            </div>
            <div className="landingLatestPricesItems">
              <div className="landingLatestPricesItem">
                <div className="heightOv">
                  <MiniChart width="100%"></MiniChart>
                </div>
              </div>
              <div className="landingLatestPricesItem">
                <div className="heightOv">
                  <MiniChart width="100%" symbol="XAUUSD"></MiniChart>
                </div>
              </div>
              <div className="landingLatestPricesItem">
                <div className="heightOv">
                  <MiniChart width="100%" symbol="TSLA"></MiniChart>
                </div>
              </div>
              <div className="landingLatestPricesItem">
                <div className="heightOv">
                  <MiniChart width="100%" symbol="NDX"></MiniChart>
                </div>
              </div>
              <div className="landingLatestPricesItem">
                <div className="heightOv">
                  <MiniChart width="100%" symbol="BTCUSD"></MiniChart>
                </div>
              </div>
            </div>
          </div>
          <div className="landingContentContainer landingContentContainerTxt">
            <div className="landingContentHalf">
              <div className="landingContentDescription">
                <h2 className="ttl">{translates?.welcome_to_a_new_era.en}</h2>
                <p>{translates?.we_represent_a_movement_dedicated.en}</p>
              </div>
            </div>
            <div className="landingContentHalf">
              <div className="landingContentDescription">
                <h2 className="ttl">
                  {translates?.erase_boundaries_embrace_defi.en}
                </h2>
                <p>{translates?.with_our_decentralized_finance.en}</p>
              </div>
            </div>
          </div>
          <div className="landingColumns">
            <img src={patternBg3} alt="paternbg3" className="patternBg3" />
            <div className="landingColumn">
              <h3 className="ttl">{translates?.novice_navigator.en}</h3>
              <h4 className="ttl">{translates?.entry_level_efficiency.en}</h4>
              <p>{translates?.this_account_is_crafted_for.en}</p>
            </div>
            <div className="landingColumn">
              <h3 className="ttl">{translates?.stellar_standard.en}</h3>
              <h4 className="ttl">{translates?.balance_brilliance.en}</h4>
              <p>{translates?.the_stellar_standard_account_is.en}</p>
            </div>
            <div className="landingColumn">
              <h3 className="ttl">{translates?.expert_edge.en}</h3>
              <h4 className="ttl">{translates?.professional_prowess.en}</h4>
              <p>{translates?.this_account_caters_to.en}</p>
            </div>
            <div className="landingColumn">
              <h3 className="ttl">{translates?.platinum_privilege.en}</h3>
              <h4 className="ttl">{translates?.vip_versatility.en}</h4>
              <p>{translates?.platinum_privilege_our_vip_account.en}</p>
            </div>
          </div>
          <div className="landingContentContainer">
            <img src={patternBg2} alt="paternbg2" className="patternBg2" />
            <div className="landingContentHalf image">
              <Scroll.Item keyframes={keyframes.zoomOut1}>
                <div className="landingContentHalfImg">
                  <img src={contentBg1} alt="contentBg1" />
                </div>
              </Scroll.Item>
            </div>
            <div className="landingContentHalf">
              <div className="landingContentDescription">
                <h2 className="ttl">{translates?.elevate_your_earnings.en}</h2>
                <h3 className="ttl">
                  {translates?.discover_the_power_of.en}{" "}
                  <span>{translates?.staking.en}</span>
                </h3>
                <p>{translates?.make_your_assets_work.en}</p>
              </div>
            </div>
          </div>
          <div className="landingContentContainer rightImg">
            <div className="landingContentHalf image">
              <Scroll.Item keyframes={keyframes.zoomOut2}>
                <div className="landingContentHalfImg">
                  <img src={contentBg2} alt="contentBg1" />
                </div>
              </Scroll.Item>
            </div>
            <div className="landingContentHalf">
              <div className="landingContentDescription">
                <h2 className="ttl">
                  {translates?.maximize_your_market_moves.en}
                </h2>
                <h3 className="ttl">
                  {translates?.achieve_more_with.en}{" "}
                  <span>{translates?.margin_trading.en}</span>
                </h3>
                <p>{translates?.empower_your_financial_journey.en}</p>
              </div>
            </div>
          </div>
          <div className="landingContentContainer">
            <div className="landingContentHalf image">
              <Scroll.Item keyframes={keyframes.zoomOut3}>
                <div className="landingContentHalfImg">
                  <img src={contentBg3} alt="contentBg1" />
                </div>
              </Scroll.Item>
            </div>
            <div className="landingContentHalf">
              <div className="landingContentDescription">
                <h2 className="ttl">
                  {translates?.trade.en}
                  <div className="ttlAnimated">
                    <div className="ttlAnimatedIn">
                      {spans.map((span, index) => (
                        <span
                          key={index}
                          className={
                            index === currentIndex
                              ? "animated active"
                              : "animated"
                          }
                        >
                          {span}
                        </span>
                      ))}
                    </div>
                  </div>
                  {translates?.without_borders.en}
                </h2>
                <p>{translates?.experience_the_potency_of_hybrid.en}</p>
              </div>
            </div>
          </div>
          <div className="landingContentContainer rightImg">
            <div className="landingContentHalf image">
              <Scroll.Item keyframes={keyframes.zoomOut4}>
                <div className="landingContentHalfImg">
                  <img src={contentBg4} alt="contentBg1" />
                </div>
              </Scroll.Item>
            </div>
            <div className="landingContentHalf">
              <div className="landingContentDescription">
                <h2 className="ttl">
                  {translates?.hybrid_trading_platform.en}
                </h2>
                <h3 className="ttl">{translates?.beyond_space_and_time.en}</h3>
                <p>
                  {translates?.crafted_by_market_professionals.en}
                  <br />
                  <br />
                  {translates?.exceptional_features_for.en}
                </p>
                <ul>
                  <li>{translates?.competitive_low_trading.en}</li>
                  <li>{translates?.cutting_edge_low_latency.en}</li>
                  <li>{translates?.exceptional_leverage_ratio.en}</li>
                  <li>
                    {translates?.robust_and_user_friendly_trading_interfaces.en}
                  </li>
                  <li>{translates?.swift_and_secure_deposit.en}</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="landingContentContainer">
            <div className="landingContentHalf image">
              <Scroll.Item keyframes={keyframes.zoomOut4}>
                <div className="landingContentHalfImg">
                  <img src={contentBg5} alt="contentBg1" />
                </div>
              </Scroll.Item>
            </div>
            <div className="landingContentHalf">
              <div className="landingContentDescription">
                <h2 className="ttl">
                  <span>100+ </span> {translates?.trading_instruments.en}
                </h2>
                <ul>
                  <li>{translates?.trade_a_broad_spectrum_of_assets.en}</li>
                  <li>{translates?.accurate_and_stable_order_execution.en}</li>
                  <li>{translates?.no_re_quotes.en}</li>
                </ul>
                <br />
                <br />
                <h2 className="ttl">
                  {translates?.unified_trading_experience.en}
                </h2>
                <p>{translates?.all_your_accounts_through.en}</p>
              </div>
            </div>
          </div>
          <div className="landingContentContainer landingContentContainerTxt faqContainer">
            <img src={patternBg5} alt="paternbg5" className="patternBg5" />
            <div className="landingContentHalf">
              <h2 className="ttl"> {translates?.how_to_get_started.en}</h2>
            </div>
            <div className="landingContentHalf">
              <div className="faqElements">
                {howStart(translates).map((item, index) => {
                  return (
                    <div
                      className={`faqElement ${
                        activeHowStart === index ? "active" : ""
                      }`}
                      onClick={() => {
                        openHowStart(index);
                      }}
                      key={index}
                    >
                      <div className="ttl faqElementTtl">{item.question}</div>
                      <div className="faqElementAnswer">{item.answer}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="landingContentContainer landingContentContainerTxt borderedContainers">
            <div className="landingContentBordered landingContentHalf">
              <h2 className="ttl">{translates?.trading_mastery.en}</h2>
              <h3 className="ttl">{translates?.daily_trading_insights.en}</h3>
              <p>{translates?.acquire_valuable_tips.en}</p>
              <h3>{translates?.comprehensive_learning_resources.en}</h3>
              <p>{translates?.explore_our_free_trading.en}</p>
            </div>
            <div className="landingContentBordered landingContentHalf">
              <h2 className="ttl">
                {translates?.unleash_the_power_of_referrals.en}
              </h2>
              <p>{translates?.harness_the_potential_to_augment.en}</p>
            </div>
            <div className="landingContentBordered landingContentHalf">
              <h2 className="ttl">{translates?.peer_to_peer_lending.en}</h2>
              <p>{translates?.our_cutting_edge_peer_to_peer.en}</p>
              <h3 className="ttl">{translates?.for_lenders.en}</h3>
              <p>{translates?.our_platform_serves_as.en}</p>
              <h3 className="ttl">{translates?.for_borrowers.en}</h3>
              <p>{translates?.for_those_requiring_funds.en}</p>
            </div>
            <div className="landingContentBordered landingContentHalf">
              <h2 className="ttl">{translates?.atar_elite_membership.en}</h2>
              <p>{translates?.are_you_set_to_seize_command.en}</p>
              <h3 className="ttl">{translates?.elevate_your_savings.en}</h3>
              <p>{translates?.atar_elite_enriches.en}</p>
              <h3 className="ttl">
                {translates?.exclusive_access_to_premium.en}
              </h3>
              <p>{translates?.atar_elite_is_more_than.en}</p>
              <h3 className="ttl">{translates?.access_to_members_only.en}</h3>
              <p>{translates?.atar_elite_symbolizes.en}</p>
            </div>
          </div>
          <div className="landingContentContainer landingContentContainerTxt faqContainer">
            <div className="landingContentHalf">
              <h2 className="ttl">
                {translates?.frequently_asked_questions.en}
              </h2>
            </div>
            <div className="landingContentHalf">
              <div className="faqElements">
                {faq(translates).map((item, index) => {
                  return (
                    <div
                      className={`faqElement ${
                        activeFaq === index ? "active" : ""
                      }`}
                      onClick={() => {
                        openFaq(index);
                      }}
                      key={index}
                    >
                      <div className="ttl faqElementTtl">{item.question}</div>
                      <div className="faqElementAnswer">{item.answer}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="landingJoinContainer">
            <div className="footerBg">
              <img src={footerBg} alt="footerBg" />
            </div>
            <div className="landingJoinContainerHalf">
              <h3 className="ttl">
                {translates?.join_the_atar.en} <br />
                AONE
              </h3>
            </div>
            <div className="landingJoinContainerHalf">
              <div className="landingSlideContainerBtn" onClick={handleConnect}>
                <GetStartedBtnSvg />
              </div>
            </div>
          </div>

          <div className="landingFooter">
            <div>© 2024 All Rights Reserved</div>
            <Link to="/term">Terms of Use</Link>
            <Link to="/privacy">Privacy Policy</Link>
            <Link to="/pdf/AONE_FlexiChain_V01_Draft_Preview.pdf" target="_blank" rel="noopener noreferrer">White Paper</Link>
            AONE Gold (AONE) Address On BSC Chain: <Link to="https://bscscan.com/token/0x44802691ad07c165ae7b1c6f9403c41981ff860e" target="_blank" rel="noopener noreferrer">0x44802691AD07c165Ae7b1c6F9403c41981fF860E</Link>
          </div>
        </Scroll.Section>
      </Scroll.Container>
    </>
  );
};

export default Landing;
