import React, { useMemo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import useFormattedNumber from "../../../hooks/useFormattedNumber";
import { useMobileWidth } from "../../../hooks/useMobileWidth";
import translates from "../../../translatesNew.json";
import { Loader } from "../../UI/loader/Loader";
import Account from "../../../assets/svg/Account";
import AccountType from "../../../assets/svg/AccountType";
import SliderNextSvg from "../../../assets/svg/SliderNextSvg";
import SliderPrevSvg from "../../../assets/svg/SliderPrevSvg";

import "swiper/swiper-bundle.css";
import "./CardSlider.css";

export const CardSlider = ({
  accounts,
  cardImgs,
  handleWithdraw,
  handleExchange,
  handleTransfer,
  accountType,
  setAccountType,
  tier,
  extensions,
  stakedTotal,
  handleStake,
}) => {
  const { width } = useMobileWidth();
  const { formatNumber } = useFormattedNumber();

  let cardFooterData = [
    {
      title: translates?.transfer.en,
      svg: (
        <AccountType
          type={"transfer"}
          className="card-slider-card_footer-item-svg"
        />
      ),
    },
    {
      title: translates?.withdraw.en,
      svg: (
        <AccountType
          type={"withdraw"}
          className="card-slider-card_footer-item-svg"
        />
      ),
    },
    {
      title: translates?.exchange.en,
      svg: (
        <AccountType
          type={"exchange"}
          className="card-slider-card_footer-item-svg"
        />
      ),
    },
    {
      title: translates?.stake.en,
      svg: (
        <AccountType
          type={"stake"}
          className="card-slider-card_footer-item-svg-stake"
        />
      ),
    },
    {
      title: "trade",
      svg: (
        <AccountType
          type={"trade"}
          // className="card-slider-card_footer-item-svg"
          className={""}
        />
      ),
    },
  ];

  const accountsData = useMemo(() => {
    const data = accounts?.filter(
      (item) =>
        item?.account_category !== "external" &&
        item?.account_category !== "system" &&
        (item?.account_category === "main"
          ? extensions["dashboard"] === "true"
          : item?.account_category === "trade"
          ? extensions[`${item?.account_category}Admin`] === "true"
          : extensions[item?.account_category] === "true" &&
            extensions[`${item?.account_category}Admin`] === "true")
    );

    const sortedData = data?.sort((a, b) =>
      a?.account_category === "main"
        ? -1
        : b?.account_category === "main"
        ? 1
        : 0
    );

    return sortedData;
  }, [accounts, accountType, extensions]);

  const assets = useMemo(() => {
    if (accountType === "main") {
      return accountsData?.find((item) => item?.account_category === "main")
        ?.assets;
    } else {
      return [];
    }
  }, [accounts, accountType]);

  const chosenAcc = useMemo(() => {
    const item =
      accountsData &&
      accountsData?.find((item) => item?.account_category === accountType);
    return {
      address: item?.address,
      balance: item?.balance,
      account_category: item?.account_category,
    };
  }, [accounts, accountType]);

  const isAccountTypeFirstItem = useMemo(
    () => accountsData?.[0]?.account_category !== accountType,
    [accounts, accountType]
  );

  return (
    <div className="card-slider-wrapper">
      <div className="card-slider-navigation-wrapper">
        <div className="card-slider-navigation">
          {accountsData?.map((item, index) => {
            // const activeIndex = accountsData.findIndex(
            //   (acc) => accountType === acc.account_category
            // );
            // const isLeftOfActive = index === activeIndex - 1;
            // const isRightOfActive = index === activeIndex + 1;
            // const borderRadiusClass = isLeftOfActive
            //   ? "left-border-radius"
            //   : isRightOfActive
            //   ? "right-border-radius"
            //   : "";

            return (
              <div
                className={`card-slider-navigation_item_container ${
                  accountType === item?.account_category ? "active" : ""
                }`}
                onClick={() => setAccountType(item?.account_category)}
                key={index}
              >
                <p className="font-16">
                  {item?.account_category === "main"
                    ? "main"
                    : item?.account_category}{" "}
                  {width > 767 ? "account" : ""}
                </p>
              </div>
            );
          })}
        </div>
      </div>

      <div
        className={`card-slider-content ${
          isAccountTypeFirstItem && width > 767
            ? "card-slider-content-wrap"
            : ""
        }`}
      >
        <Swiper
          spaceBetween={10}
          slidesPerView={"auto"}
          className="card-slider-cards-container"
          modules={[Pagination, Navigation]}
          navigation={{
            nextEl: ".card-slider-cards-mover-icons_next",
            prevEl: ".card-slider-cards-mover-icons_prev",
          }}
          pagination={{
            type: "progressbar",
          }}
        >
          <SwiperSlide>
            <div
              className={`card-slider-card ${               
                chosenAcc?.balance === null ||
                chosenAcc?.balance === undefined ||
                Number(chosenAcc?.balance) === 0 
                  ? "card-slider-faded"
                  : ""
              }`}
            >
              <img src={cardImgs["atar"]} className="card-slider-bg-img" alt=" " />
              <div className="card-slider-card_header-container">
                <div className="card-slider-card_header">
                  <Account type={"atar"} />
                  <h4 className="font-16 title_wrapper_card">
                    <span className="font-16">
                      {accountType === "main"
                        ? "AONE"
                        : accountType?.toUpperCase()}
                    </span>
                    {tier && (
                      <span
                        className={`tier-card
                        ${tier === "Stellar Standard" ? "gold-tier" : ""}
                        ${tier === "Diamond VIP" ? "diamond-vip-tier" : ""}
                        ${tier === "Expert Edge" ? "diamond-tier" : ""} 
                        ${tier === "Platinum Privilege" ? "vip-tier" : ""}
                        ${tier === "Novice Navigator" ? "basic-tier" : ""}`}
                      >
                        {tier?.toUpperCase()}
                      </span>
                    )}
                  </h4>
                </div>
                <div className="main-card-content-wrapper">
                  {chosenAcc?.balance === null ||
                  chosenAcc?.balance === undefined ? (
                    <Loader loading="Loading..." />
                  ) : (
                    <p
                      className={`card-slider-card_content  ${
                        accountType === "trade"
                          ? "card-slider-trade_content"
                          : ""
                      }`}
                    >
                      {formatNumber(chosenAcc?.balance, 2)}
                    </p>
                  )}
                  {accountType === "trade" && (
                    <span
                      style={{
                        fontSize: "12px",
                        lineHeight: "130%",
                        color: "rgba(255,255,255,0.3)",
                      }}
                    >
                      {formatNumber(stakedTotal)}
                    </span>
                  )}
                </div>
              </div>
              <div className="card-slider-card_footer">
                {cardFooterData?.map((item, index) => {
                  if (item.title === "Stake") return null;
                  if (
                    accountType !== "main" &&
                    (item.title === "Withdraw" ||
                      item.title === "Exchange" ||
                      item.title === "Deposit")
                  )
                    return null;

                  if (accountType === "main" && item.title === "trade") return null;
                    
                  return (
                    <div
                      className={`${
                        item?.title === "Deposit"
                          ? "card-slider-card_footer-item active"
                          : item?.title !== "trade"
                          ? "card-slider-card_footer-item"
                          : "disabled-for-now"
                      }`}
                      key={index}
                      onClick={() => {
                        if (item.title === "Withdraw") {
                          handleWithdraw(chosenAcc, "ATAR");
                        } else 
                        if (item.title === "Transfer") {
                          handleTransfer(chosenAcc, "ATAR");
                        } else if (item.title === "Exchange") {
                          handleExchange(chosenAcc, "ATAR");
                        }
                      }}
                    >
                      {item.svg} 
                      <p className="font-10">{item.title}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </SwiperSlide>
          {assets &&
            Object.entries(assets)?.map(([key, value], index) => {
              if (key?.includes("Staked")) return null;
              return (
                <SwiperSlide key={index}>
                  <div
                    className={`card-slider-card ${
                      Number(value) === 0 || Number(value) < 0.0001 ? "card-slider-faded" : ""
                    }`}
                  >
                    <img src={cardImgs[key]} className="card-slider-bg-img" alt=" " />
                    <div className="card-slider-card_header-container">
                      <div className="card-slider-card_header">
                        <Account type={key} />
                        <h4 className="font-16">{key.toUpperCase()}</h4>
                      </div>
                      <div className="main-card-content-wrapper">
                        {assets === null || assets === undefined ? (
                          <Loader loading="Loading..." />
                        ) : (
                          <p
                            className={`card-slider-card_content ${
                              assets?.[`${key}Staked`] > 0
                                ? "card-slider-trade_content"
                                : ""
                            }`}
                          >
                            {formatNumber(value, key === "usdt" ? 2 : 4)}
                          </p>
                        )}
                        {assets?.[`${key}Staked`] > 0 && (
                          <span
                            style={{
                              fontSize: "12px",
                              lineHeight: "130%",
                              color: "rgba(255,255,255,0.3)",
                            }}
                          >
                            {formatNumber(assets?.[`${key}Staked`])}
                          </span>
                        )}
                      </div>
                    </div>
                    <div
                      className={`card-slider-card_footer card-slider-card_footer-currency`}
                    >
                      {cardFooterData?.map((item, footerIndex) => {
                        if (item.title === "trade") return null;
                        return (
                          <div
                            style={
                              (key !== "atar" && (item.title === "Withdraw" || item.title === "Stake")) ||
                              item.title === "Withdraw"
                                ? { display: "none" }
                                : { display: "flex" }
                            }
                            className={`card-slider-card_footer-item card-slider-card_footer-item-currency`}
                            key={footerIndex}
                            onClick={() => {
                              // if (item.title === "Withdraw") {
                              //   handleWithdraw(accountsData[index], key);
                              // } else 
                              if (item.title === "Exchange") {
                                handleExchange(accountsData[index], key);
                              } else if (item.title === "Transfer") {
                                handleTransfer(accountsData[index], key);
                              } else if (item.title === "Stake") {
                                handleStake(accountsData[index], key);
                              }
                            }}
                          >
                            {item.svg} 
                            <p className="font-10">{item.title}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
        <div className="card-slider-cards-mover">
          <div className="card-slider-cards-mover-icons">
            <SliderPrevSvg />
            <SliderNextSvg />
          </div>
        </div>
      </div>
    </div>
  );
};
