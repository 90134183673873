import { useEffect, useState } from "react";
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Buffer } from "buffer";
import { SignIn } from "./components/Auth/SignIn";
import { NoMetaMask } from "./components/UI/noMetaMask/NoMetaMask";
import { Header } from "./components/Layouts/header/Header";
import { ChangeNetwork } from "./components/UI/changeNetwork/ChangeNetwork";
import { DashboardSharedLayout } from "./components/Dashboard/DashboardSharedLayout/DashboardSharedLayout";
import { Popup } from "./components/UI/popup/Popup";
import { ToastContainer, toast } from "react-toastify";
import { socket } from "./api/socket";
import { useAccount } from "wagmi";

import { useConnectMeta } from "./hooks/use-connect";
import { useApp } from "./hooks/use-app";
import axios from "./api/axios";
import { useStake } from "./hooks/use-stake";
import translates from "./translates.json";

import Dashboard from "./components/Dashboard";
import Trade from "./components/Trade/Trade";
import Positions from "./components/Trade/positions/Positions";
import Orders from "./components/Trade/Orders";
import History from "./components/Trade/History";
import Signals from "./components/Trade/Signals";
import News from "./components/Trade/News";
import TradeChart from "./components/Trade/TradeChart";
import Loan from "./components/Loan";
import Referral from "./components/Referral";
import Staking from "./components/Staking";
import StakingOld from "./components/StakingOld";
import CreateAccount from "./components/CreateAccount";
import Extensions from "./components/Extensions";
import ExtensionItem from "./components/ExtensionItem";
import Transactions from "./components/Transactions";
import SideBar from "./components/Layouts/SideBar/SideBar";
import VerifyEmail from "./components/VerifyEmail/VerifyEmail";
import TopUp from "./components/TopUp/TopUp";
import Success from "./components/Deposit/Success";
import Cancel from "./components/Deposit/Cancel";
import Landing from "./components/Landing";
import LandingRegistration from "./components/LandingRegistration";
import ResetPassword from "./components/ResetPassword/ResetPassword";
//import { decryptEnv } from "./utils/decryptEnv";



import AtitleSvg from "./assets/svg/AtitleSvg";
import { links, tradeLinks, tradePriceData } from "./dummyComponents/app";
import { Logo } from "./assets/svg";
import useHandleSidebar from "./hooks/useHandleSidebar";

import "./App.css";
import "./assets/css/mainTheme.css";
import "react-toastify/dist/ReactToastify.css";

import TermsOfUse from "./components/Layouts/SideBar/components/TermsOfUse/TermsOfUse";
import PrivacyPolicy from "./components/Layouts/SideBar/components/Privacy/PrivacyPolicy";
import HelpSupport from "./components/Layouts/SideBar/components/HelpSupport/HelpSupport";
import StakingTable from "./components/StakingTable/StakingTable";
import UniLevelStagesTable from "./components/UniLevelStagesTable/UniLevelStagesTable";
import BinaryStagesTable from "./components/BinaryStagesTable/BinaryStagesTable";
//import ReferralTest from "./components/ReferralTest";

window.Buffer = window.Buffer || Buffer;

function App() {

  const dispatch = useDispatch();
  const { isConnected } = useAccount();

  useEffect(() => {
    dispatch({
      type: "SET_RATES",
      payload: { atr: { usd: 1 } },
    });
  
    const fetchRates = async () => {
      try {
        const response = await axios.get("/api/rates/get_latest_rates");
        const rates = response.data?.rates;
        if (rates) {
          dispatch({
            type: "SET_RATES",
            payload: rates,
          });
          console.log("Rates loaded successfully:", rates); // Log after rates are loaded
        }
      } catch (error) {
        console.error("Failed to fetch rates:", error);
      }
    };
  
    fetchRates();
  
    socket.on("connection", () => {
      console.log("Connected to WebSocket server");
    });
  
    socket.emit("join", "Hello from the client");
  
    socket.on("message", (message) => {
      console.log("Message received: ", message);
    });
  
    socket.on("update_rates", (data) => {
      dispatch({
        type: "SET_RATES",
        payload: data.rates,
      });
      console.log("Rates updated via WebSocket:", data.rates); // Log after rates are updated
    });
  
    socket.on("disconnect", () => {
      console.log("Disconnected from WebSocket server");
    });
  
    return () => {
      socket.off("connection");
      socket.off("message");
      socket.off("update_rates");
      socket.off("disconnect");
    };
  }, [dispatch]);

  // useEffect(() => {
  //   socket.on("connection", () => {
  //     console.log("Connected to WebSocket server");
  //   });

  //   socket.emit("join", "Hello from the client");

  //   socket.on("message", message => {
  //     console.log("Message received: ", message);
  //   });

  //   // socket.on("update_rates", data => {
  //   //   const rates = data.rates;

  //   //   dispatch({
  //   //     type: "SET_RATES",
  //   //     payload: rates,
  //   //   });
  //   // });

  //   socket.on("disconnect", () => {
  //     console.log("Disconnected from WebSocket server");
  //   });
  // }, []);

  const Router = process.env.REACT_APP_STAKING_CONTRACT_ADDRESS;
  const tokenAddress = process.env.REACT_APP_TOKEN_ADDRESS;

  const sideBarOpen = useSelector((state) => state.appState?.sideBarOpen);
  const sideBar = useSelector((state) => state.appState?.sideBar);
  const emailVerified = useSelector((state) => state.appState?.emailVerified);
  const exts = useSelector((state) => state.extensions?.activeExtensions);
  const connectState = useSelector((state) => state.connect);
  const providerType = useSelector((state) => state.connect.providerType);
  // const lastConnectionType = useSelector(
  //   (state) => state.connect.lastConnectionType
  // );
  const balance = useSelector((state) => state.appState.userData?.balance);
  const { activeExtensions } = useSelector((state) => state.extensions);
  const appState = useSelector((state) => state.appState);
  const { depositAmount } = useSelector((state) => state.stake);
  const isExtensionsLoaded = useSelector(
    (state) => state.appState?.isExtensionsLoaded
  );
  const usersData = useSelector((state) => state.appState?.userData);
  const mainAcc = useSelector((state) => state.appState?.userData);
  const AONEPrice = useSelector((state) => state.appState?.rates?.atr?.usd);
  const tokenBalance = useSelector((state) => state.connect.tokenBalance);
  const addressFromEmail = useSelector((state) => state.connect.address);

  const [showSignInModal, setShowSignInModal] = useState(false);
  const [resetPasswordState, setResetPasswordState] = useState({
    loading: false,
    error: "",
    success: "",
  });
  const [signInState, setSignInState] = useState({
    loading: false,
    error: false,
  });
  const [proceed2fa, setProceed2fa] = useState(false);
  const [otpState, setOtpState] = useState({ loading: false, error: false });
  const [initialRegister, setInitialRegister] = useState(false);
  const [step, setStep] = useState(1);
  const [showBalance, setShowBalance] = useState(false);
  const [eliteMemberLoading, setEliteMemberLoading] = useState(false);
  const [eliteMemberStatus, setEliteMemberStatus] = useState(false);
  const [helpSupport, setHelpSupport] = useState(false);

  const { checkAllowance } = useStake({
    Router,
    tokenAddress,
  });
  const location = useLocation();
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  // const { isConnected } = useAccount();
  const { updateState, logout } = useApp();
  const handleSidebarOpen = useHandleSidebar();
  const {
    switchToBscTestnet,
    account,
    chainId
  } = useConnectMeta();

  useEffect(() => {
    if (providerType === "walletConnect" && account) {
      const getBalances = async () => {
        await axios.post("/api/transactions/get_balances", {
          account
        })
        .then(res => {
          if (res && res.data.success) {
            const balances = res.data.balances;
  
            dispatch({
              type: "GET_TOKEN_BALANCE",
              payload: balances.usersTokenBalance,
            });

            dispatch({
              type: "GET_TREASURY_TOKEN_BALANCE",
              payload: balances.tokenTreasuryBalance,
            });

            dispatch({
              type: "GET_TREASURY_BNB_BALANCE",
              payload: balances.bnbTreasuryBalance,
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
      };
  
      getBalances();
    }
  }, [account, tokenAddress, providerType, tokenBalance, usersData]);

  // const fetchData = async () => {
  //   await axios
  //     .post("/api/accounts/login", {
  //       address: account,
  //     })
  //     .then((res) => {
  //       if (res?.data === "success") {
  //         updateState();
  //       }
  //     })
  //     .catch((err) => {});
  // };

  const handleConnect = () => {
    if (sideBarOpen) {
      dispatch({
        type: "SET_SIDE_BAR",
        payload: { sideBarOpen: !sideBarOpen },
      });
    } else {
      dispatch({
        type: "SET_SIDE_BAR",
        payload: { sideBarOpen: !sideBarOpen, sideBar: "connect" },
      });
    }
  };

// Temporary Debugger Logging, Must be removed in production
  console.log('App State:', appState);
  console.log('Extensions:', activeExtensions);
  console.log('Token Balance:', tokenBalance);
  console.log('Staked Amount:', depositAmount);
  console.log('Account Signed:', appState?.accountSigned);
  console.log('Extensions Loaded:', isExtensionsLoaded);
  console.log('Staking Active:', activeExtensions.staking);
  console.log('Staking Admin:', activeExtensions?.stakingAdmin);
  console.log('Referral Status:', appState?.userData?.referralStatus);
  console.log('V2 Account:', appState?.userData?.account_version);
// Temporary Debugger Logging, Must be removed in production


  const handleNotifications = () => {
    if (sideBarOpen && sideBar !== "notifications") {
      return dispatch({
        type: "SET_SIDE_BAR",
        payload: { sideBar: "notifications" },
      });
    }

    dispatch({
      type: "SET_SIDE_BAR",
      payload: { sideBarOpen: !sideBarOpen, sideBar: "notifications" },
    });
  };

  const loginWithEmail = (show) => {
    setShowSignInModal(show);
  };

  const handleSubmitSignIn = async ({ email, password }) => {
    if (email && password) {
      setSignInState((prev) => ({ ...prev, loading: true, error: "" }));

      await axios
        .post("/api/accounts/recovery/login", {
          email,
          password,
        })
        .then((res) => {
          setSignInState((prev) => ({ ...prev, loading: false }));

          if (res?.data) {
            dispatch({
              type: "SET_ADDRESS",
              payload: res.data.address,
            });
          }

          if (res.data.message === "proceed 2fa") return setProceed2fa(true);

          updateState();
          setProceed2fa(false);
          setShowSignInModal(false);
          dispatch({
            type: "SET_CONNECTION_TYPE",
            payload: "email",
          });
          dispatch({
            type: "SET_ACCOUNT_SIGNED",
            payload: true,
          });
          dispatch({
            type: "SET_LAST_CONNECTION_TYPE",
            payload: "email",
          });
          dispatch({
            type: "SET_SIDE_BAR",
            payload: { sideBar: "UserAccount" },
          });
          dispatch({
            type: "SET_LOGGED_WITH_EMAIL",
            payload: true,
          });
        })
        .catch((e) => {
          setSignInState((prev) => ({
            ...prev,
            loading: false,
            error: e.response?.data,
          }));
        });
    }
  };

  const validate2fa = async (token) => {
    setOtpState({ loading: true, error: "" });

    await axios
      .post("/api/accounts/otp/validate", {
        token,
        address: addressFromEmail,
      })
      .then((res) => {
        setOtpState({ loading: false, error: "" });
        dispatch({ type: "SET_SIDE_BAR", payload: { sideBar: "UserAccount" } });
        dispatch({
          type: "SET_ACCOUNT_SIGNED",
          payload: true,
        });
        dispatch({
          type: "SET_CONNECTION_TYPE",
          payload: "email",
        });
        dispatch({
          type: "SET_LAST_CONNECTION_TYPE",
          payload: "email",
        });
        dispatch({
          type: "SET_LOGGED_WITH_EMAIL",
          payload: true,
        });
        updateState();
        setProceed2fa(false);
        setShowSignInModal(false);
      })
      .catch((e) => {
        setOtpState({ loading: false, error: e.response.data });
      });
  };

  const handleDataChange = (e) => {
    let error = false;
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

    if (e.target.name === "email") {
      if (e.target.value && !emailRegex.test(e.target.value)) {
        error = "Invalid email";
      }

      if (!e.target.value) {
        error = "Email is required";
      }
    }

    setSignInState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
      error: error,
    }));
  };

  useEffect(() => {
    const getTiers = async () => {
      await axios.get("/api/transactions/get_tiers")
      .then(res => {
        if (res && res.data.success) {
          const tiers = res.data.tiers;

          dispatch({
            type: "SET_TIERS",
            payload: tiers,
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
    };

    getTiers();
  }, [providerType]);

  const handleResetPassword = (email) => {
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

    if (email && !emailRegex.test(email)) {
      setResetPasswordState((prev) => ({
        ...prev,
        error: "Invalid email",
      }));
    }

    if (!email) {
      setResetPasswordState((prev) => ({
        ...prev,
        error: "Email is required",
      }));
    }

    setResetPasswordState({ loading: true, success: "", error: "" });

    axios
      .post("/api/accounts/get-reset-password-email", {
        email,
      })
      .then((res) => {
        setResetPasswordState((prev) => ({
          ...prev,
          loading: false,
          success: res.data,
        }));
      })
      .catch((e) => {
        setResetPasswordState((prev) => ({
          ...prev,
          loading: false,
          error: e?.response?.data,
        }));
      });
  };

  // handle email reconnect after refresh
  useEffect(() => {
    if (connectState?.lastConnectionType === "email") {
      updateState(() => {
        dispatch({
          type: "SET_ACCOUNT_SIGNED",
          payload: true,
        });
        dispatch({
          type: "SET_CONNECTION_TYPE",
          payload: "email",
        });
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (account && isConnected) {
      axios
        .post("/api/accounts/web3_connect", {
          address: account,
          walletConnect: true,
        })
        .then(() => {
          dispatch({
            type: "SET_METAMASK_CONNECT_LOADING",
            payload: false,
          });
          dispatch({
            type: "SET_WALLETCONNECT_LOADING",
            payload: false,
          });
          updateState();

          dispatch({
            type: "SET_ACCOUNT_SIGNED",
            payload: true,
          });

          dispatch({
            type: "SET_CONNECTION_TYPE",
            payload: "web3",
          });

          dispatch({
            type: "SET_LAST_CONNECTION_TYPE",
            payload: "web3",
          });
        })
        .catch((e) => {
          dispatch({
            type: "SET_METAMASK_CONNECT_LOADING",
            payload: false,
          });
          dispatch({
            type: "SET_WALLETCONNECT_LOADING",
            payload: false,
          });
        });
    }
  }, [account, isConnected]);

  useEffect(() => {
    if (appState?.accountSigned && account) {
      init();
      dispatch({
        type: "SET_SIDE_BAR",
        payload: { sideBarOpen: false },
      });
    }
    // eslint-disable-next-line
  }, [appState?.accountSigned, account]);

  // useEffect(() => {
  //   if (account && active && triedReconnect) {
  //     fetchData();
  //   }

  //   // eslint-disable-next-line
  // }, [account, active, triedReconnect]);

  // async function handleWeb3Connection(receivedAddress, signature) {
  //   axios
  //     .post("/api/accounts/web3Connect", {
  //       address: receivedAddress,
  //       signature,
  //       message: "I confirm that this is my address",
  //       walletConnect: false,
  //     })
  //     .then(() => {
  //       dispatch({
  //         type: "SET_METAMASK_CONNECT_LOADING",
  //         payload: false,
  //       });

  //       dispatch({
  //         type: "SET_WALLETCONNECT_LOADING",
  //         payload: false,
  //       });

  //       updateState();

  //       dispatch({
  //         type: "SET_ACCOUNT_SIGNED",
  //         payload: true,
  //       });

  //       dispatch({
  //         type: "SET_CONNECTION_TYPE",
  //         payload: "web3",
  //       });

  //       dispatch({
  //         type: "SET_LAST_CONNECTION_TYPE",
  //         payload: "web3",
  //       });
  //     })
  //     .catch((error) => {
  //       dispatch({
  //         type: "SET_METAMASK_CONNECT_LOADING",
  //         payload: false,
  //       });
  //       dispatch({
  //         type: "SET_WALLETCONNECT_LOADING",
  //         payload: false,
  //       });
  //       if (error.response && error.response.data) {
  //         toast.error(error.response.data);
  //       } else {
  //         console.error("Error:", error.message);
  //         toast.error("An error occurred. Please try again later.");
  //       }
  //     });
  // }

  useEffect(() => {
    if (chainId && chainId !== 97 && chainId !== 56) {
      localStorage.removeItem("walletconnect");

      dispatch({
        type: "CONNECTION_ERROR",
        payload: "Please switch your network in wallet",
      });
    }

    // eslint-disable-next-line
  }, [chainId]);

  useEffect(() => {
    if (appState?.accountSigned) {
      checkAllowance();
    }
    // eslint-disable-next-line
  }, [appState?.accountSigned, depositAmount]);

  useEffect(() => {
    if (appState?.connectionType !== "email") {
      if (
        mainAcc?.step > 5 &&
        mainAcc?.account_owner === account?.toLowerCase()
      ) {
        setStep(6);
        dispatch({
          type: "UPDATE_ACTIVE_EXTENSIONS",
          payload: { dashboard: "true" },
        });
      } else if (
        mainAcc?.step > 2 &&
        mainAcc?.account_owner === account?.toLowerCase() &&
        providerType === "walletConnect"
      ) {
        setInitialRegister(true);
        dispatch({
          type: "UPDATE_ACTIVE_EXTENSIONS",
          payload: { dashboard: "false" },
        });

        if (AONEPrice && tokenBalance >= 100 / AONEPrice) {
          setStep(mainAcc?.step > 4 ? mainAcc?.step : 4);
        } else {
          setStep(mainAcc?.step);
        }
      } else if (mainAcc?.account_owner !== account?.toLowerCase()) {
        setStep(1);
      } else {
        setInitialRegister(true);
        dispatch({
          type: "UPDATE_ACTIVE_EXTENSIONS",
          payload: { dashboard: "false" },
        });
        setStep(2);
      }
    }
    // eslint-disable-next-line
  }, [
    appState?.connectionType,
    mainAcc?.step,
    mainAcc?.account_owner,
    account,
    providerType,
    AONEPrice,
  ]);

  useEffect(() => {
    if (!appState?.connectionType) {
      dispatch({
        type: "UPDATE_ACTIVE_EXTENSIONS",
        payload: {
          trade: "false",
          loan: "false",
          notify: "false",
          staking: "false",
          referral: "false",
          connect: "false",
          dashboard: "false",
        },
      });
      dispatch({
        type: "SET_SIDE_BAR",
        payload: {
          userData: null,
        },
      });
    }
    // eslint-disable-next-line
  }, [appState?.connectionType]);

  useEffect(() => {
    dispatch({
      type: "SET_SIDE_BAR",
      payload: { sideBarOpen: false },
    });
    // eslint-disable-next-line
  }, [location]);

  async function init() {
    await axios
      .post(
        "/api/accounts/activate-account",
        {},
        {
          timeout: 120000,
        }
      )
      .then((res) => {
        if (res.data?.account) {
          const manageExtensions = async () => {
            await axios
            .post("/api/accounts/manage_extensions", {
              address: account,
              setup: false,
              type: "global"
            })
            .then((res) => {
              if (res?.data?.account) {
                dispatch({
                  type: "UPDATE_ACTIVE_EXTENSIONS",
                  payload: res.data.account.extensions,
                });
              }

              updateState();
            })
            .catch((e) => {
              console.log(e?.response?.data?.message);
            });
          };

          // let x = res.data.account.x;
          // let y = res.data.account.y;
          // let extensions = res.data.account.extensions;
          // let activateExt = {
          //   referral: "false",
          //   referralAdmin: "false",
          // };

          // if (
          //   (x >= 0 || y >= 0) &&
          //   (extensions.referral === "false" ||
          //     extensions.referralAdmin === "false")
          // ) {
          //   activateExt = {
          //     referral: "true",
          //     referralAdmin: "true",
          //   };

          //   manageExtensions(activateExt);
          // }

          // if (
          //   (x < 0 || y < 0) &&
          //   (extensions.referral === "true" ||
          //     extensions.referralAdmin === "true")
          // ) {
          //   activateExt = {
          //     referral: "false",
          //     referralAdmin: "false",
          //   };

          //   manageExtensions(activateExt);
          // }
          manageExtensions();

          dispatch({
            type: "SET_SYSTEM_ACCOUNT_DATA",
            payload: res.data.account,
          });
        }
      })
      .catch((e) => {});
  }

  async function becomeEliteOnClick() {
    try {
      setEliteMemberLoading(true);
      await axios.post("/api/accounts/became-elite-member");
      setEliteMemberLoading(false);
      setEliteMemberStatus(true);
      toast.success("Elite Membership request sent successfully", {
        autoClose: 8000,
      });
      // throw new Error("Not implemented");
    } catch (e) {
      setEliteMemberLoading(false);
      toast.error(e?.response?.data ?? "Something went wrong", {
        autoClose: false,
      });
    }
  }

  const helpSupportClick = () => {
    setHelpSupport(true);
  };

  useEffect(() => {
    const getAllTransactionFees = async () => {
      await axios.get("/api/transactions/transaction_fees").then((res) => {
        dispatch({
          type: "SET_TRANSACTION_FEES",
          payload: res?.data,
        });
      });
    };
    if (appState?.accountSigned) {
      getAllTransactionFees();
    }
  }, [appState?.accountSigned]);

  useEffect(() => {
    const getTransactionAmounts = async () => {
      await axios
        .get("/api/transactions/get_transaction_amounts")
        .then((res) => {
          dispatch({
            type: "SET_TRANSACTION_AMOUNT",
            payload: res?.data,
          });
        });
    };
    if (appState?.accountSigned) {
      getTransactionAmounts();
    }
  }, [appState?.accountSigned]);

  useEffect(() => {
    const getRanges = async () => {
      await axios.get("/api/accounts/get_ranges").then((res) => {
        dispatch({
          type: "SET_RANGES",
          payload: res?.data?.data?.ranges,
        });
      });
    };

    getRanges();
  }, [appState?.accountSigned]);

  return (
    <main>
      <div className={`main-container ${sideBarOpen ? "sideOpen" : ""}`}>
        {showSignInModal && (
          <div className="signInContainer">
            <SignIn
              onClick={handleSubmitSignIn}
              sideBarClose={() => loginWithEmail(false)}
              signInState={signInState}
              otpEnabled={proceed2fa}
              otpState={otpState}
              handleTFA={(code) => validate2fa(code)}
              resetPasswordState={resetPasswordState}
              handleResetPassword={handleResetPassword}
              handleDataChange={handleDataChange}
            />
          </div>
        )}
        <Header
          title={<AtitleSvg />}
          logoSvg={<Logo />}
          isSigned={appState?.accountSigned}
          A1Price={AONEPrice}
          onLogoClick={() => navigate("/")}
          modules={exts}
          AccountVersion = {appState?.userData?.account_version}
          account={
            appState?.userData?.address &&
            account &&
            appState.connectionType !== "email"
              ? account
              : ""
          }
          showBalance={showBalance}
          setShowBalance={setShowBalance}
          handleShowBalance={() => setShowBalance(!showBalance)}
          location={location}
          tradePriceData={tradePriceData}
          sideBarOpen={sideBarOpen}
          sideBar={sideBar}
          handleConnect={handleConnect}
          handleNotifications={handleNotifications}
          verified={emailVerified}
          amount={balance ?? 0}
          initialRegister={step < 6}
          setInitialRegister={setInitialRegister}
          loginWithEmail={loginWithEmail}
          loggedWithEmail={appState.connectionType === "email"}
          handleDeposit={handleSidebarOpen}
          handleWithdraw={(a, b) => handleSidebarOpen("withdraw", b)}
          handleTransfer={(a, b) => handleSidebarOpen("transfer", b)}
        />
        {initialRegister && step < 6 && (
          <LandingRegistration
            translates={translates}
            step={step}
            setStep={setStep}
            setInitialRegister={setInitialRegister}
          />
        )}
        <ToastContainer />
        <Routes>
          <Route path="/term" element={<TermsOfUse />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
        </Routes>
        {account || appState.connectionType === "email" ? (
          <Routes>
            <Route
              path="/"
              element={
                <Landing
                  step={step}
                  setStep={setStep}
                  initialRegister={initialRegister}
                  setInitialRegister={setInitialRegister}
                  translates={translates}
                  handleConnect={() => {
                    if (step < 6 && appState.connectionType !== "email") {
                      setInitialRegister(true);
                    } else {
                      handleConnect();
                    }
                  }}
                />
              }
            />
            <Route
              path="/dashboard"
              element={
                <DashboardSharedLayout
                  disabledAccount={
                    !appState?.userData?.active &&
                    +appState?.userData?.step === 6
                  }
                  links={links}
                  children={appState?.accountSigned && <Dashboard />}
                  becomeEliteOnClick={becomeEliteOnClick}
                  helpSupportClick={helpSupportClick}
                  eliteMemberBtnLabel={
                    eliteMemberLoading
                      ? "Loading..."
                      : appState?.userData?.elite_member === "pending" ||
                        eliteMemberStatus
                      ? "Elite Membership Requested"
                      : "Become Elite Member"
                  }
                  eliteMemberBtnDisabled={
                    !appState?.userData ||
                    appState?.userData?.elite_member === "pending" ||
                    eliteMemberLoading ||
                    eliteMemberStatus
                  }
                />
              }
            />
            <Route
              path="/transactions"
              element={
                <DashboardSharedLayout
                  disabledAccount={
                    !appState?.userData?.active &&
                    +appState?.userData?.step === 6
                  }
                  links={links}
                  children={appState?.accountSigned && <Transactions />}
                  becomeEliteOnClick={becomeEliteOnClick}
                  helpSupportClick={helpSupportClick}
                  eliteMemberBtnLabel={
                    eliteMemberLoading
                      ? "Loading..."
                      : appState?.userData?.elite_member === "pending" ||
                        eliteMemberStatus
                      ? "Elite Membership Requested"
                      : "Become Elite Member"
                  }
                  eliteMemberBtnDisabled={
                    !appState?.userData ||
                    appState?.userData?.elite_member === "pending" ||
                    eliteMemberLoading ||
                    eliteMemberStatus
                  }
                />
              }
            />
            <Route
              path="/top-up"
              element={
                <DashboardSharedLayout
                  disabledAccount={
                    !appState?.userData?.active &&
                    +appState?.userData?.step === 6
                  }
                  links={links}
                  children={
                    appState?.accountSigned && <TopUp translates={translates} />
                  }
                  becomeEliteOnClick={becomeEliteOnClick}
                  helpSupportClick={helpSupportClick}
                  eliteMemberBtnLabel={
                    eliteMemberLoading
                      ? "Loading..."
                      : appState?.userData?.elite_member === "pending" ||
                        eliteMemberStatus
                      ? "Elite Membership Requested"
                      : "Become Elite Member"
                  }
                  eliteMemberBtnDisabled={
                    !appState?.userData ||
                    appState?.userData?.elite_member === "pending" ||
                    eliteMemberLoading ||
                    eliteMemberStatus
                  }
                />
              }
            />
            <Route
              path="/loan"
              element={
                isExtensionsLoaded &&
                activeExtensions.loan === "false" &&
                activeExtensions?.loanAdmin === "false" ? (
                  <Navigate to="/" />
                ) : (
                  <Loan helpSupportClick={helpSupportClick} />
                )
              }
            />
            <Route
              path="/trade"
              element={
                isExtensionsLoaded &&
                activeExtensions.trade === "false" &&
                activeExtensions?.tradeAdmin === "false" ? (
                  <Navigate to="/" />
                ) : (
                  <DashboardSharedLayout
                    disabledAccount={
                      !appState?.userData?.active &&
                      +appState?.userData?.step === 6
                    }
                    links={tradeLinks}
                    children={
                      <>
                        <Trade translates={translates} />
                        <TradeChart />
                      </>
                    }
                    becomeEliteOnClick={becomeEliteOnClick}
                    helpSupportClick={helpSupportClick}
                    eliteMemberBtnLabel={
                      eliteMemberLoading
                        ? "Loading..."
                        : appState?.userData?.elite_member === "pending" ||
                          eliteMemberStatus
                        ? "Elite Membership Requested"
                        : "Become Elite Member"
                    }
                    eliteMemberBtnDisabled={
                      !appState?.userData ||
                      appState?.userData?.elite_member === "pending" ||
                      eliteMemberLoading ||
                      eliteMemberStatus
                    }
                  />
                )
              }
            />
            <Route
              path="/positions"
              element={
                isExtensionsLoaded &&
                activeExtensions.trade === "false" &&
                activeExtensions?.tradeAdmin === "false" ? (
                  <Navigate to="/" />
                ) : (
                  <DashboardSharedLayout
                    disabledAccount={
                      !appState?.userData?.active &&
                      +appState?.userData?.step === 6
                    }
                    links={tradeLinks}
                    children={
                      <>
                        <Positions translates={translates} />
                        <TradeChart />
                      </>
                    }
                    becomeEliteOnClick={becomeEliteOnClick}
                    helpSupportClick={helpSupportClick}
                    eliteMemberBtnLabel={
                      eliteMemberLoading
                        ? "Loading..."
                        : appState?.userData?.elite_member === "pending" ||
                          eliteMemberStatus
                        ? "Elite Membership Requested"
                        : "Become Elite Member"
                    }
                    eliteMemberBtnDisabled={
                      !appState?.userData ||
                      appState?.userData?.elite_member === "pending" ||
                      eliteMemberLoading ||
                      eliteMemberStatus
                    }
                  />
                )
              }
            />
            <Route
              path="/orders"
              element={
                isExtensionsLoaded &&
                activeExtensions.trade === "false" &&
                activeExtensions?.tradeAdmin === "false" ? (
                  <Navigate to="/" />
                ) : (
                  <DashboardSharedLayout
                    disabledAccount={
                      !appState?.userData?.active &&
                      +appState?.userData?.step === 6
                    }
                    links={tradeLinks}
                    children={
                      <>
                        <Orders translates={translates} />
                        <TradeChart />
                      </>
                    }
                    becomeEliteOnClick={becomeEliteOnClick}
                    helpSupportClick={helpSupportClick}
                    eliteMemberBtnLabel={
                      eliteMemberLoading
                        ? "Loading..."
                        : appState?.userData?.elite_member === "pending" ||
                          eliteMemberStatus
                        ? "Elite Membership Requested"
                        : "Become Elite Member"
                    }
                    eliteMemberBtnDisabled={
                      !appState?.userData ||
                      appState?.userData?.elite_member === "pending" ||
                      eliteMemberLoading ||
                      eliteMemberStatus
                    }
                  />
                )
              }
            />
            <Route
              path="/history"
              element={
                isExtensionsLoaded &&
                activeExtensions.trade === "false" &&
                activeExtensions?.tradeAdmin === "false" ? (
                  <Navigate to="/" />
                ) : (
                  <DashboardSharedLayout
                    disabledAccount={
                      !appState?.userData?.active &&
                      +appState?.userData?.step === 6
                    }
                    links={tradeLinks}
                    children={<History translates={translates} />}
                    becomeEliteOnClick={becomeEliteOnClick}
                    helpSupportClick={helpSupportClick}
                    eliteMemberBtnLabel={
                      eliteMemberLoading
                        ? "Loading..."
                        : appState?.userData?.elite_member === "pending" ||
                          eliteMemberStatus
                        ? "Elite Membership Requested"
                        : "Become Elite Member"
                    }
                    eliteMemberBtnDisabled={
                      !appState?.userData ||
                      appState?.userData?.elite_member === "pending" ||
                      eliteMemberLoading ||
                      eliteMemberStatus
                    }
                  />
                )
              }
            />
            <Route
              path="/signals"
              element={
                isExtensionsLoaded &&
                activeExtensions.trade === "false" &&
                activeExtensions?.tradeAdmin === "false" ? (
                  <Navigate to="/" />
                ) : (
                  <DashboardSharedLayout
                    disabledAccount={
                      !appState?.userData?.active &&
                      +appState?.userData?.step === 6
                    }
                    links={tradeLinks}
                    children={<Signals translates={translates} />}
                    becomeEliteOnClick={becomeEliteOnClick}
                    helpSupportClick={helpSupportClick}
                    eliteMemberBtnLabel={
                      eliteMemberLoading
                        ? "Loading..."
                        : appState?.userData?.elite_member === "pending" ||
                          eliteMemberStatus
                        ? "Elite Membership Requested"
                        : "Become Elite Member"
                    }
                    eliteMemberBtnDisabled={
                      !appState?.userData ||
                      appState?.userData?.elite_member === "pending" ||
                      eliteMemberLoading ||
                      eliteMemberStatus
                    }
                  />
                )
              }
            />
            <Route
              path="/news"
              element={
                isExtensionsLoaded &&
                activeExtensions.trade === "false" &&
                activeExtensions?.tradeAdmin === "false" ? (
                  <Navigate to="/" />
                ) : (
                  <DashboardSharedLayout
                    disabledAccount={
                      !appState?.userData?.active &&
                      +appState?.userData?.step === 6
                    }
                    links={tradeLinks}
                    children={<News translates={translates} />}
                    becomeEliteOnClick={becomeEliteOnClick}
                    helpSupportClick={helpSupportClick}
                    eliteMemberBtnLabel={
                      eliteMemberLoading
                        ? "Loading..."
                        : appState?.userData?.elite_member === "pending" ||
                          eliteMemberStatus
                        ? "Elite Membership Requested"
                        : "Become Elite Member"
                    }
                    eliteMemberBtnDisabled={
                      !appState?.userData ||
                      appState?.userData?.elite_member === "pending" ||
                      eliteMemberLoading ||
                      eliteMemberStatus
                    }
                  />
                )
              }
            />
            <Route
              path="/staking"
              element={
                appState?.accountSigned &&
                isExtensionsLoaded &&
                activeExtensions.staking === "true" &&
                activeExtensions?.stakingAdmin === "true" && (
                  <Staking
                    translates={translates}
                    helpSupportClick={helpSupportClick}
                  />
                )
              }
            />
            <Route
              path="/staking-old"
              element={
                appState?.accountSigned &&
                isExtensionsLoaded &&
                activeExtensions.staking === "true" &&
                activeExtensions?.stakingAdmin === "true" &&
                appState?.userData?.account_version !== 2 &&
                 (
                  <StakingOld
                    translates={translates}
                    helpSupportClick={helpSupportClick}
                  />
                )
              }
            />
            <Route
              path="/referral"
              element={
                appState?.accountSigned &&
                isExtensionsLoaded &&
                activeExtensions.referral === "true" &&
                activeExtensions.referralAdmin === "true" &&
               (
                  <Referral
                    translates={translates}
                    helpSupportClick={helpSupportClick}
                  />
                )
              }
            />
            {/* <Route
              path="/referralTest123"
              element={
                appState?.accountSigned &&
                isExtensionsLoaded &&
                activeExtensions.referral === "true" &&
                activeExtensions.referralAdmin === "true" &&
                appState?.userData?.tier?.value !== "Novice Navigator" && (
                  <ReferralTest
                    translates={translates}
                    helpSupportClick={helpSupportClick}
                  />
                )
              }
            /> */}
            <Route
              path="/extensions"
              element={
                <Extensions
                  translates={translates}
                  helpSupportClick={helpSupportClick}
                />
              }
            />
            <Route
              path="/extensions/:id"
              element={<ExtensionItem helpSupportClick={helpSupportClick} />}
            />
            <Route path="/verify/:id" element={<VerifyEmail />} />
            <Route path="/reset-password/:code" element={<ResetPassword />} />
            <Route
              path="/create-account"
              element={
                <DashboardSharedLayout
                  disabledAccount={
                    !appState?.userData?.active &&
                    +appState?.userData?.step === 6
                  }
                  links={links}
                  children={<CreateAccount />}
                  becomeEliteOnClick={becomeEliteOnClick}
                  helpSupportClick={helpSupportClick}
                  eliteMemberBtnLabel={
                    eliteMemberLoading
                      ? "Loading..."
                      : appState?.userData?.elite_member === "pending" ||
                        eliteMemberStatus
                      ? "Elite Membership Requested"
                      : "Become Elite Member"
                  }
                  eliteMemberBtnDisabled={
                    !appState?.userData ||
                    appState?.userData?.elite_member === "pending" ||
                    eliteMemberLoading ||
                    eliteMemberStatus
                  }
                />
              }
            />
            <Route path="/deposit/:hash" element={<Success />} />
            <Route path="/coinbase/cancel" element={<Cancel />} />
            <Route
              path="/staking-details"
              element={
                appState?.accountSigned &&
                isExtensionsLoaded && <StakingTable />
              }
            />
            <Route
              path="/uni-level-stages"
              element={
                appState?.accountSigned &&
                isExtensionsLoaded && <UniLevelStagesTable />
              }
            />
            <Route
              path="/binary-stages"
              element={
                appState?.accountSigned &&
                isExtensionsLoaded && <BinaryStagesTable />
              }
            />
          </Routes>
        ) : (
          <Routes>
            <Route
              path="/"
              element={
                <Landing
                  step={step}
                  setStep={setStep}
                  initialRegister={initialRegister}
                  setInitialRegister={setInitialRegister}
                  translates={translates}
                  helpSupportClick={helpSupportClick}
                  handleConnect={() => {
                    if (step < 6 && appState.connectionType !== "email") {
                      setInitialRegister(true);
                    } else {
                      handleConnect();
                    }
                  }}
                />
              }
            />
            <Route path="/reset-password/:code" element={<ResetPassword />} />
            <Route
              path="/extensions"
              element={<Extensions helpSupportClick={helpSupportClick} />}
            />
            <Route
              path="/extensions/:id"
              element={<ExtensionItem helpSupportClick={helpSupportClick} />}
            />
            <Route path="/verify/:id" element={<VerifyEmail />} />
            <Route
              path="/dashboard"
              element={
                <DashboardSharedLayout
                  disabledAccount={
                    !appState?.userData?.active &&
                    +appState?.userData?.step === 6
                  }
                  links={links}
                  children={<Dashboard />}
                  becomeEliteOnClick={becomeEliteOnClick}
                  helpSupportClick={helpSupportClick}
                  eliteMemberBtnLabel={
                    eliteMemberLoading
                      ? "Loading..."
                      : appState?.userData?.elite_member === "pending" ||
                        eliteMemberStatus
                      ? "Elite Membership Requested"
                      : "Become Elite Member"
                  }
                  eliteMemberBtnDisabled={
                    !appState?.userData ||
                    appState?.userData?.elite_member === "pending" ||
                    eliteMemberLoading ||
                    eliteMemberStatus
                  }
                />
              }
            />
          </Routes>
        )}
      </div>
      <SideBar />
      {appState?.connectionError === "No MetaMask detected" && (
        <Popup
          popUpElement={<NoMetaMask />}
          label={"Metamask is not installed"}
          handlePopUpClose={() => {
            dispatch({
              type: "CONNECTION_ERROR",
              payload: "",
            });
          }}
          popupBGclass={"cover-most-bg"}
        />
      )}
      {helpSupport && (
        <Popup
          popUpElement={
            <HelpSupport
              setHelpSupport={setHelpSupport}
              helpSupport={helpSupport}
            />
          }
          label={"Help & Support"}
          handlePopUpClose={() => {
            setHelpSupport(false);
          }}
          popupBGclass={"cover-most-bg"}
        />
      )}
      {appState?.connectionError === "Please switch your network in wallet" && (
        <Popup
          popUpElement={
            <ChangeNetwork
              disconnect={() => {
                logout();
                dispatch({
                  type: "CONNECTION_ERROR",
                  payload: "",
                });
              }}
              handleNetworkChange={() => {
                switchToBscTestnet([
                  {
                    chainId: "0x61",
                    chainName: "BSC Testnet",
                    nativeCurrency: {
                      name: "tBNB",
                      symbol: "tBNB",
                      decimals: 18,
                    },
                    rpcUrls: [process.env.REACT_APP_WEB3_PROVIDER_URL],
                    blockExplorerUrls: [process.env.REACT_APP_BLOCK_EXPLORER],
                  },
                ]);
              }}
            />
          }
          handlePopUpClose={() => {
            dispatch({
              type: "CONNECTION_ERROR",
              payload: "",
            });
          }}
          label={"Check Your Network"}
          customStyles={{
            zIndex: "1002 !important",
            backgroundColor: "red !important",
          }}
          popupBGclass={"cover-most-bg"}
        />
      )}
    </main>
  );

}

// const projectId = decryptEnv("U2FsdGVkX189MekQgGTz85aj1ofI7iabpoYQtO6Ti0WyrHKdXESamx6HHTXFInFB");
// //const projectId = decryptEnv(process.env.REACT_APP_TOKEN_HOLDER_TREASURY_PRIVATE_KEY);
// console.log(projectId);




export default App;
