import React from "react";

import styles from "./TermsOfUse.module.css";

const TermsOfUse = () => {
  return (
    <div
      className={styles.TermsOfUseContainer}
      style={{ listStyleType: "dot" }}
    >
      <h1>Terms of Use</h1>
      <p className={styles.dateOfUpdate}>Last updated: Feb 26, 2024</p>
      <p>
        The A1(AONE) app and website (as defined below) is strictly prohibited
        for use by individuals or entities residing in, located in, incorporated
        in, or having a registered office in the United States of America or
        Canada (collectively, "Blocked Persons"). Furthermore, no services (as
        defined below) are extended to individuals or entities residing in,
        holding citizenship in, located in, incorporated in, or having a
        registered office in any Restricted Territory (as defined below), and
        any such individual or entity from a Restricted Territory is deemed a
        "Restricted Person." No exceptions will be entertained. Hence, if you
        are a Blocked Person, refrain from attempting to use the A1 Interface,
        and if you are a Restricted Person, do not make any attempt to use any
        of the Services. Any attempt to circumvent these restrictions using a
        Virtual Private Network ("VPN") is strictly prohibited. These terms of
        use, along with any documents and additional terms they explicitly
        reference, which includes any other terms and conditions or agreements
        that A1 and its affiliates ("A1," "we," "us," and "our") publicly post
        or make available to you or the company or other legal entity you
        represent ("you" or "your") (collectively, these "Terms"), govern your
        utilization of, and access to:
      </p>
      <ul className={styles.orderedList}>
        <li>
          A1's websites, including A1.gold (and their respective subdomains);
          web applications; mobile applications; and all associated sites linked
          by A1 (collectively, along with any materials and services available
          therein, and successor website(s) or application(s) thereto, the
          "Site");
        </li>
        <li>
          all products and features accessible via the Site, encompassing the
          order book, matching engine, smart contracts, decentralized
          applications, APIs, and all other software developed by A1 or a third
          party for trading blockchain-based assets (collectively, "Digital
          Assets"), including engaging in perpetual contracts ("Perpetual
          Contracts") related to Digital Assets(collectively, the "Interface");
        </li>
        <li>
          non-fungible tokens ("NFTs") accessible in connection with the
          Interface or registration for or attendance at events sponsored or
          hosted by A1 (collectively with the Site and the Interface, the
          "Services").
        </li>
      </ul>
      <p>
        We strongly advise you to carefully peruse these Terms, as they
        unequivocally delineate your rights and obligations, in addition to our
        disclaimers and legal liability limitations, pertaining to your usage
        of, and access to, the Services. By clicking "I agree" (or equivalent
        language) to these Terms, acknowledging these Terms through alternative
        means, or in any other way accessing or utilizing the Services, you
        unconditionally accept and agree to abide by these Terms. Should you
        disagree with these Terms, you must refrain from accessing or using the
        Services. Prior to using any service provided by A1, we urge you to
        meticulously review the disclosures and disclaimers presented in Section
        12 in their entirety. Section 12 contains pivotal information regarding
        the legal obligations associated with your utilization of the Services.
        By accessing or utilizing the Services, you acknowledge that A1 does not
        provide execution, settlement, or clearing services of any kind, and is
        not liable for the execution, settlement, or clearing of transactions
        automated through the Services.
      </p>
      <h2>1. CHANGES TO THESE TERMS</h2>
      <p>
        We retain the authority, at our sole discretion, to amend these Terms
        periodically. In the event of any modifications, we will notify you of
        such alterations, either by communicating through the Services or
        updating the "Last Updated" date displayed at the beginning of these
        Terms. Unless otherwise specified in our notice, all such changes take
        immediate effect, and your continued utilization of the Services
        following such notification will signify your acceptance of the
        revisions. If you disagree with the revised Terms, you must discontinue
        your use of the Site and the Services.
      </p>
      <h2>2. UTILIZATION OF SERVICES</h2>
      <p>
        <strong>2.1.</strong> Before gaining access to or using the Services,
        you affirm and warrant to A1 the following: If you are entering into
        these Terms as an individual, you certify that you are of legal age in
        your residing jurisdiction and possess the legal capacity to enter into
        and abide by these Terms. If you are entering into these Terms on behalf
        of an entity, you must have the legal authority to accept these Terms on
        behalf of that entity. In such cases, the term "you" (excluding this
        paragraph) pertains to the said entity. If you intend to access the
        Interface or plan to do so in the future, you confirm that you are not a
        Blocked Person and are not accessing the Interface from within the
        United States or Canada, collectively referred to as "Blocked
        Countries." However, it should be noted that all existing Interface
        users as of April 7, 2023 (referred to as the "Wind-Down Date") who
        reside in, are situated in, are incorporated in, or have a registered
        office in Canada will be allowed to access the Interface until seven (7)
        days after the Wind-Down Date. Afterward, any associated accounts will
        be permanently restricted. You must not be a resident, citizen, or agent
        of, nor be incorporated in, nor have a registered office in North Korea,
        Iran, Cuba, Syria, Myanmar (Burma), the regions of Crimea, Donetsk, or
        Luhansk, or any other country or region subject to comprehensive
        country-wide or regionwide economic sanctions by the United States,
        collectively referred to as "Restricted Territories.” Beyond the
        Restricted Territories facing comprehensive sanctions, we also restrict
        access for individuals and entities from [Spain, Italy, Germany,
        Nigeria, China, Russia, Vietnam, Georgia, Japan Malaysia and Norway].
        These restrictions arise from distinct considerations, tailored to align
        with our internal policies and broader operational guidelines.
        Accordingly, residents, citizens, or agents affiliated with these
        countries, as well as organizations registered or headquartered there,
        may find certain activities or transactions constrained under our
        framework. This approach ensures compliance with our standards and the
        integrity of our operations across all jurisdictions. You must not be
        subject to economic or trade sanctions imposed or enforced by any
        governmental authority, or be designated on any list of prohibited or
        restricted parties, including the list maintained by the Office of
        Foreign Assets Control of the U.S. Department of the Treasury,
        collectively referred to as "Sanctioned Person.” You do not intend to
        engage in transactions with any Restricted Person or Sanctioned Person.
        You will not use a VPN or any other privacy or anonymization tools or
        techniques to evade or attempt to evade any restrictions that apply to
        the Services. Your access to the Services (a) is not prohibited by and
        does not contravene any domestic or foreign laws, rules, statutes,
        regulations, by-laws, orders, protocols, codes, decrees, or other
        directives, requirements, or guidelines that are applicable to or
        intended to govern or regulate any individual, property, transaction,
        activity, event, or any other matter, including any rules, orders,
        judgments, directives, or other requirements or guidelines issued by any
        domestic or foreign federal, provincial or state, municipal, local, or
        other governmental, regulatory, judicial, or administrative authority
        having jurisdiction over A1, you, the Services, or as otherwise duly
        enacted and enforceable by law, common law, or equity, collectively
        referred to as "Applicable Laws"; and (b) does not contribute to or
        facilitate any unlawful activities.
      </p>
      <p>
        <strong>2.2.</strong> As a condition for accessing or using the
        Services, you acknowledge, comprehend, and consent to the following:
      </p>
      <ul className={styles.orderedList}>
        <li>
          Periodically, the Services may become inaccessible or nonfunctional
          for various reasons, including: (a) delays, inaccessibility, or
          malfunctions in equipment, technology, or other infrastructure; (b)
          routine maintenance procedures or repairs performed by A1 or any of
          our suppliers or contractors; (c) unforeseeable causes beyond A1's
          control; (d) disruptions and temporary or permanent unavailability of
          underlying blockchain infrastructure; or (e) unavailability of
          third-party service providers or external partners for any reason.
          Without limiting any other provision in these Terms, and as detailed
          below, A1 bears no responsibility or liability for any losses or
          injuries resulting from such events.
        </li>
        <li>
          We retain the right to disable or alter access to the Services, such
          as restricting certain features, at any time in the event of a breach
          of these Terms, including situations where we reasonably believe that
          any of your representations and warranties may be inaccurate,
          misleading, or untrue. We are not liable to you for any losses or
          damages you may incur due to the inaccessibility of the Services to
          you at any time or for any reason.
        </li>
        <li>
          The Services may evolve, meaning that A1 can make changes, replace, or
          permanently discontinue the Services at our sole discretion.
        </li>
        <li>
          The pricing information and other data presented on the Site do not
          constitute (i) an offer, solicitation of an offer, or recommendation
          to engage in a transaction with A1, other than the payment of fees to
          A1, or (ii) any advice pertaining to a transaction conducted using the
          Site and the Services.
        </li>
        <li>
          A1 does not serve as an agent for you or any other user of the
          Services.
        </li>
        <li>
          You are solely responsible for your use of the Services, including all
          transfers of Digital Assets.
        </li>
        <li>
          To the maximum extent allowed by Applicable Law, we do not owe any
          fiduciary duties or liabilities to you or any other party. To the
          extent that such duties or liabilities may exist in law or equity, you
          hereby irrevocably disclaim, waive, and release those duties and
          liabilities.
        </li>
        <li>
          You are solely responsible for reporting and paying any taxes
          associated with your use of the Services.
        </li>
        <li>
          We have no control over, nor liability for, the delivery, quality,
          safety, legality, or any other aspect of any Digital Assets that you
          may transfer to or receive from a third party. We are not responsible
          for ensuring that the entity you transact with completes the
          transaction or has the necessary authorization to do so. If you
          encounter any issues with transactions involving Digital Assets using
          the Services, you assume the entire risk.
        </li>
        <li>
          We may occasionally run contests, promotions, sweepstakes, or other
          activities or offer referral programs ("Promotions and Referrals"),
          which may be subject to separate terms and conditions and rules that
          include specific eligibility criteria. You are responsible for
          reviewing all terms, conditions, and rules governing Promotions and
          Referrals to determine your eligibility to participate. If you enter
          or participate in any Promotions and Referrals, you agree to abide by
          and comply with all the terms, conditions, and rules of such
          Promotions and Referrals. All Promotions and Referrals will be
          voluntary, so you should not participate if you do not agree to adhere
          to all such terms, conditions, and rules.
        </li>
        <li>
          If you receive fee discounts from any Promotions and Referrals not
          governed by separate terms and conditions and rules, A1 reserves the
          right to add, modify, or eliminate the discounts and any other aspects
          of such Promotions and Referrals.
        </li>
      </ul>
      <ul className={styles.orderedList}>
        <p>
          <strong>2.3</strong>
          As a condition for accessing or using the Services, you pledge to A1
          the following:
        </p>
        <li>
          While using the Services, you will only transfer legally acquired
          Digital Assets that are your property.
        </li>
        <li>
          You will comply with all Applicable Laws in connection with your use
          of the Services and will refrain from using the Services if such use
          is prohibited by the laws of your country or any other Applicable
          Law..
        </li>
        <li>
          Any Digital Assets utilized in connection with the Services are either
          owned by you or you possess valid authorization to conduct actions
          using such Digital Assets.
        </li>
      </ul>
      <h2>3.FEES AND ESTIMATED PRICING</h2>
      <p>
        As you engage with the Services provided by A1, unless otherwise
        explicitly indicated as part of an A1 promotion, you are responsible for
        covering all expenses associated with blockchain interactions, which
        encompass "gas" charges and any additional fees displayed on the Site at
        the moment you utilize the Services. While we strive to offer precise
        fee details, please note that the provided information serves as our
        approximations of fees, which could deviate from the actual costs
        incurred during your usage of the Services and engagement with the
        blockchain.
      </p>
      <h2>4.NO PROFESSIONAL/FINANCIAL ADVICE OR FIDUCIARY RESPONSIBILITIES</h2>
      <p>
        All information presented in connection with your utilization of the
        Services provided by A1 is intended solely for informational purposes
        and should not be interpreted as professional guidance. You should
        neither take nor refrain from taking any actions based on the
        information available on the Site or any other materials we offer,
        including blog posts, data, articles, links to third-party content,
        Discord and Telegram communications, news feeds, tutorials, tweets, and
        videos. Prior to making any financial, legal, or other decisions related
        to the Services, it is advisable to seek independent professional advice
        from a licensed and qualified expert in the relevant field. These Terms
        are not designed to, and do not, establish or impose any fiduciary
        obligations upon us. Furthermore, you acknowledge that our only duties
        and commitments to you are explicitly outlined in these Terms.
      </p>
      <h2>5. PROHIBITED ACTIVITIES</h2>
      <ul className={styles.orderedList}>
        <li>
          When using the Services provided by A1, it is imperative that you
          abstain from engaging in the types of activities outlined below
          ("Prohibited Activities"). The following activities are indicative but
          not exhaustive in defining Prohibited Activities. If you are uncertain
          about whether your use of the Services constitutes a Prohibited
          Activity or have questions regarding how these requirements pertain to
          your situation, please reach out to us at legal@a1.gold. By using the
          Services, you affirm that you will refrain from participating in any
          of the following Prohibited Activities:
        </li>
        <li>
          Violate any Applicable Laws, including relevant anti-money laundering
          and anti-terrorist financing laws and sanctions programs such as the
          Bank Secrecy Act and the U.S. Department of Treasury's Office of
          Foreign Asset Controls.
        </li>
        <li>
          Engage in transactions involving items that infringe upon or violate
          any copyright, trademark, right of publicity or privacy, or any other
          proprietary right under Applicable Law. This includes sales,
          distribution, or access to counterfeit music, movies, software, or
          other licensed materials without proper authorization from the rights
          holder. Additionally, unauthorized use of A1's or our licensors'
          intellectual property, name, or logo, including the use of A1's trade,
          service, or licensed marks, without explicit consent from A1 or in a
          manner that detrimentally affects A1 is prohibited.
        </li>
        <li>
          Partake in improper or abusive trading practices, including but not
          limited to fraudulent activities designed to deceive, mislead, or
          defraud others, trading ahead of another user, fraudulent trading,
          accommodation trading, fictitious transactions, pre-arranged or
          non-competitive transactions, cornering or attempted cornering of
          Perpetual Contracts or other Digital Assets, bid or offer violations,
          wash trading (i.e., generating trading volume by placing buy and sell
          orders at or about the same price, volume, and time), manipulation
          (i.e., trading to affect the market price of a Digital Asset and
          create an artificial price), spoofing (i.e., placing buy or sell
          orders without genuine intent to transact and with the intention to
          cancel before execution), intentionally making bids or offers to
          manipulate market prices, or entering orders to engage in transactions
          without a net change in positions but resulting in profit for one
          party and loss for another party, commonly referred to as a "money
          pass." Additionally, any other trading activity that, in A1's
          reasonable judgment, is deemed abusive, improper, or disruptive to the
          Interface's operation is prohibited.
        </li>
        <li>
          Utilize the Services in a manner that may disrupt, interfere with,
          negatively impact, or hinder other users from fully enjoying the
          Services, or that could harm, impair, overburden, or disable the Site
          or the Services in any way.
        </li>
        <li>
          Attempt to bypass content-filtering techniques, security measures, or
          access controls implemented by A1 on the Site, including through the
          use of a VPN.
        </li>
        <li>
          Employ any robot, spider, crawler, scraper, or other automated means
          or interface not provided by us to access the Services, extract data,
          or introduce malware, viruses, Trojan horses, worms, logic bombs,
          drop-dead devices, backdoors, shutdown mechanisms, or other harmful
          materials into the Site or the Services.
        </li>
        <li>
          Provide false, inaccurate, or misleading information while utilizing
          the Services or engage in activities aimed at defrauding A1, other
          Services users, or any other individuals.
        </li>
        <li>
          Use or access the Services to transmit or exchange Digital Assets
          derived directly or indirectly from any criminal or fraudulent
          activity, including terrorism or tax evasion.
        </li>
        <li>
          Utilize the Services in a manner that, in our sole discretion, is
          considered libelous, defamatory, profane, obscene, pornographic,
          sexually explicit, indecent, lewd, vulgar, suggestive, harassing,
          stalking, hateful, threatening, offensive, discriminatory, bigoted,
          abusive, inflammatory, fraudulent, deceptive, or otherwise
          objectionable. This includes content or activities likely or intended
          to incite, threaten, facilitate, promote, or encourage hate, racial
          intolerance, or violent acts against others.
        </li>
        <li>
          Access the Services from a jurisdiction (including an IP address in a
          jurisdiction) that we have, at our sole discretion, determined to be a
          location where the use of the Site, the Interface, or the Services is
          prohibited. This includes any Blocked Countries or Restricted
          Territories.
        </li>
        <li>
          Harass, abuse, or cause harm to another person, including A1's
          employees and service providers.
        </li>
        <li>
          Impersonate another user of the Services or engage in
          misrepresentation of your identity.
        </li>
        <li>
          Participate in or attempt to engage in, or encourage, induce, or
          assist any third party to participate in or attempt to engage in any
          of the activities prohibited under this Section 5 or any other
          provision of these Terms.
        </li>
      </ul>
      <h2>6. CONTENT USAGE</h2>
      <p>
        By using A1, you grant us a worldwide, non-exclusive, perpetual,
        irrevocable, transferable, sub- licensable (through multiple tiers),
        fully paid-up, royalty-free license to use, reproduce, modify, create
        derivative works from, display, perform, publish, and distribute any
        content that becomes accessible to other users due to your use of the
        Site or the Services, collectively referred to as "Your Content." This
        license includes the right to employ Your Content in various forms,
        mediums, or manners, including its utilization for the promotion of A1
        or the Services. You confirm and assure us that (a) you possess
        ownership of Your Content or hold the necessary rights to grant the
        rights and licenses as outlined in these Terms, and (b) Your Content,
        when used in accordance with the licensed terms herein, does not and
        will not infringe upon, misappropriate, or violate any third party's
        rights.
      </p>
      <h2>7. PROPRIETARY RIGHTS</h2>
      <p>
        <strong>7.1.</strong> You acknowledge that certain components of the
        Services may utilize, incorporate, or reference specific open-source
        elements, and your use of the Services is subject to compliance with any
        applicable open-source licenses governing such components (collectively
        referred to as the "Open-Source Licenses"). Without limiting the scope
        of the foregoing, you shall not (a) resell, lease, lend, share,
        distribute, or allow any third party to utilize the Services; (b) employ
        the Services for time-sharing or service bureau purposes; or (c) utilize
        the Services in a manner contravening the terms of the Open-Source
        Licenses.
      </p>
      <p>
        <strong>7.2.</strong> Excluding third-party software integrated into the
        Services, A1 retains ownership of the Services, encompassing all
        technology, content, and other materials presented, displayed, or
        provided on the Site or in association with the Services (including all
        related intellectual property rights, whether or not subject to the
        Open-Source Licenses). Hereby, A1 grants you a restricted, non-
        exclusive, revocable, non-transferable, non-sub-licensable license to
        access and use those segments of the Site and the Services that are A1's
        proprietary assets and not obtainable pursuant to the Open-Source
        Licenses.
      </p>
      <p>
        <strong>7.3.</strong>
        All product or service names, logos, and other marks associated with A1
        and employed on the Site or as part of the Services, including A1's name
        and logo, are trademarks owned by A1 or our licensors. Usage,
        replication, or imitation of these marks without prior written consent
        from A1 or the applicable licensors is prohibited under these Terms.
        Furthermore, these Terms do not confer any rights to you in relation to
        these trademarks. Legal notices displayed on the Site or alongside the
        Services may not be removed, obscured, or altered.
      </p>
      <h2>8. LINKS</h2>
      <p>
        The Services or third parties may offer links to other websites,
        applications, or accessible resources on the World Wide Web. You
        acknowledge and consent that A1 assumes no responsibility for the
        accessibility of these external websites, applications, or resources,
        and does not endorse, warrant, or accept responsibility or liability for
        any content, advertising, products, or other materials presented on or
        obtainable from such websites or resources. Furthermore, you acknowledge
        and consent that A1 shall not be held responsible or liable, either
        directly or indirectly, for any damage or loss incurred or alleged to be
        incurred as a result of using or relying on any such content, goods, or
        services accessible through any such website or resource.
      </p>
      <h2>9. MODIFICATIONS, SUSPENSIONS, CESSATION AND MAINTENANCE</h2>

      <p>
        <strong>9.1.</strong> A1 reserves the sole discretion to, at any time
        and with or without prior notice to you, make adjustments, suspend, or
        deactivate (either temporarily or permanently) the Services, either in
        full or in part, for any rationale, including the restriction of
        allowing only open Perpetual Contracts to be concluded. When your access
        is terminated, your authorization to utilize the Services will promptly
        cease. A1 will not bear responsibility for any losses incurred by you
        due to any changes made to the Services or due to any modifications,
        suspensions, or terminations of your access to all or any segment of the
        Services, for any grounds whatsoever.
      </p>

      <p>
        <strong>9.2.</strong> A1 may undergo scheduled maintenance to ensure the
        system's stability and performance. Users will be notified in advance of
        any planned maintenance activities, which may result in temporary
        service interruptions.
      </p>

      <p>
        <strong>9.3.</strong> A1 may deploy hot fixes to address urgent issues
        or vulnerabilities. Hot fixes may be applied without prior notice to
        ensure the security and integrity of the system. Users may experience
        brief disruptions during the application of hot fixes or urgent
        maintenance times.
      </p>

      <p>
        <strong>9.4.</strong> A1 will conduct regular system integrity checks to
        maintain the security and reliability of its services. These checks may
        include but are not limited to, security scans, data integrity
        verifications, and performance assessments. In the event that a system
        integrity check identifies an issue, A1 may take necessary actions,
        including temporary service suspensions or restrictions, to resolve the
        issue and ensure system stability.
      </p>

      <p>
        <strong>9.5.</strong> In the event of any exploitation, misuse, hacking,
        or security vulnerability or breach, A1 reserves the right to
        immediately halt all operations and transactions. This action is to
        ensure the security and integrity of the system and its users.
      </p>

      <p>
        <strong>9.6.</strong> Upon detecting any security issue, A1 will
        activate a safeguard status. This includes the implementation of
        protective measures both on-chain and across all related services.
      </p>

      <p>
        <strong>9.7.</strong> A temporary freeze may be placed on any accounts
        and affected external wallets. This measure aims to prevent further
        unauthorized access or misuse. If the exploit or security issue impacts
        referral-connected accounts, A1 may temporarily disable all connected
        accounts. This precautionary measure can extend up to 10 levels of
        connection. The temporary disablement is to facilitate recovery,
        rollback, and system integrity checks.
      </p>

      <p>
        <strong>9.8.</strong> A1 automatically detects and temporarily suspends
        accounts associated with blacklisted IP addresses, emails, and wallets.
        Users whose accounts are suspended for this reason will need to contact
        the support team at the provided support email to resolve the issue.
      </p>

      <p>
        <strong>9.9.</strong> In cases where suspicious activities or access
        attempts from restricted countries are detected, A1 may freeze the
        account as a preventative measure. A notification may be sent to the
        user; however, this notification might be issued after the account has
        been frozen.
      </p>

      <h2>10. RISKS</h2>
      <p>
        <strong>10.1.</strong> When you access or utilize the Services, you
        acknowledge and consent to the inherent risks linked with cryptographic
        systems and blockchain-based networks, including Digital Assets such as
        ETH, BNB, smart contract-based tokens like fungible tokens and NFTs, and
        systems that interact with blockchain-based networks. A1 neither owns
        nor controls the underlying software that forms blockchain networks. In
        general, the software powering blockchain networks, including the
        Ethereum blockchain, is open source, allowing anyone to use, modify, and
        distribute it. By using the Services, you recognize and consent to the
        following: (a) A1 is not responsible for the operation of the
        blockchain-based software and networks underpinning the Services; (b)
        there is no assurance of the functionality, security, or availability of
        said software and networks; and (c) the underlying blockchain-based
        networks may undergo sudden changes in operational rules, such as
        "forks," which can significantly impact the Services. These blockchain
        networks employ public and private key cryptography, and it is solely
        your responsibility to secure your private key(s). We do not have access
        to your private key(s). Losing control of your private key(s) means
        irreversible loss of access to your Digital Assets on the Ethereum
        blockchain or other blockchain-based networks, with no means of
        recovery. If your private key(s) are lost, you will be unable to
        transfer your Digital Assets to any other blockchain address or wallet,
        rendering your held Digital Assets inaccessible.
      </p>
      <p>
        <strong>10.2.</strong> You recognize and understand that regulatory
        inquiries or actions may affect the Services and your Digital Assets,
        potentially hindering A1's ability to provide our proprietary software
        and your ability to access or use the Services.
      </p>
      <p>
        <strong>10.3.</strong> You acknowledge and understand that cryptography
        is an advancing field, with developments in code-breaking and other
        technological advancements, such as the emergence of quantum computers,
        which may pose risks to Digital Assets and the Services, potentially
        leading to theft or loss of Digital Assets. Although the smart contracts
        available on the Interface will be updated, where possible, to address
        risks associated with technological advancements and incorporate
        additional security measures, such efforts do not guarantee full
        security of the Services.
      </p>
      <p>
        <strong>10.4.</strong> You comprehend that the blockchain technology is
        still under development, introducing technological and security risks
        when utilizing the Services, alongside uncertainties regarding Digital
        Assets and related transactions. You acknowledge that the cost of
        Ethereum blockchain transactions is variable and subject to change,
        which can impact any activities conducted on the Ethereum blockchain,
        potentially resulting in price fluctuations or increased transaction
        costs when using the Services.{" "}
      </p>
      <p>
        <strong>10.5.</strong> You accept that the Services may contain flaws,
        and you are solely responsible for evaluating any code provided in
        connection with the Services. These warnings and others provided in
        these Terms do not represent an ongoing duty to alert you to all
        potential risks associated with using the Services.
      </p>
      <p>
        <strong>10.6.</strong> While we aim to provide accurate and timely
        information and data on the Site and during your use of the Services,
        the Site and other information accessible through the Services may not
        always be entirely accurate, complete, or up-to-date. They may also
        contain technical inaccuracies or typographical errors. To ensure the
        accuracy of the information, you should verify all information before
        relying on it, as all decisions made based on information contained on
        the Site or through the Services are your sole responsibility. We make
        no representations regarding the accuracy, completeness, or suitability
        for any specific purpose of any pricing information provided via the
        Site or through the Services. Prices and pricing information may differ
        from those available on similar service platforms.
      </p>
      <p>
        <strong>10.7.</strong> The use or interaction with the Services requires
        a comprehensive understanding of applied cryptography and computer
        science to grasp the inherent risks, including those listed above. You
        affirm that you possess the necessary knowledge and expertise. Any
        reference to a specific type of Digital Asset on the Site or through the
        Services does not imply our endorsement or disapproval of the technology
        on which that Digital Asset relies and should not serve as a substitute
        for your comprehension of the risks unique to each Digital Asset type.
      </p>
      <p>
        <strong>10.8.</strong> Using the Services, particularly for engaging in
        Trade and Perpetual Contracts, carries financial risk. Digital Assets,
        especially when linked to Perpetual Contracts, are inherently
        experimental, risky, and volatile. Transactions executed through the
        Services are irrevocable and final, with no option for refunds. By using
        the Services, you declare and warrant that you have, are, and will be
        solely responsible for conducting your independent assessment and
        investigations into the risks associated with any given transaction and
        the underlying Digital Assets, including Perpetual Contracts. You affirm
        that you possess sufficient knowledge, market sophistication,
        professional guidance, and experience to evaluate the merits and risks
        of any transaction conducted through the Services or involving any
        Digital Asset. You accept all consequences of using the Services,
        including the risk of losing access to your Digital Assets indefinitely.
        All transaction decisions are entirely your responsibility. Regardless
        of the terms in these Terms, we assume no responsibility for, and will
        never be liable to you for, any loss or harm incurred by you or any
        third parties in connection with your use of the Services or the
        execution of Digital Asset transactions, including entering into
        Perpetual Contracts.
      </p>
      <p>
        <strong>10.9.</strong> We are obligated to comply with Applicable Law,
        which may require us to take specific actions or provide information
        upon government agency request. You acknowledge and understand that A1
        may, at its sole discretion, take any action deemed appropriate to
        cooperate with government agencies or adhere to Applicable Law.
      </p>
      <p>
        <strong>10.10.</strong> You comprehend that the Service remains under
        development, introducing technological, trading, and other risks when
        utilizing the Services. These risks may include delays in trades,
        withdrawals, and deposits due to the unavailability of A1's servers
        Services operator's servers; incorrect display of information on the
        Site due to server errors; or the potential rollback of transactions
        conducted through the Services due to server errors. You acknowledge
        that these risks may significantly impact your transactions through the
        Services, potentially resulting in unfulfilled transactions or
        transactions executed at prices different from your intended price or
        not executed at all.
      </p>
      <p>
        <strong>10.11.</strong> You understand that you are solely responsible
        for all trades you place, including any orders filled erroneously due to
        your mistakes.
      </p>
      <p>
        <strong>10.12.</strong> By accessing the Services, you assume the risks
        outlined in this Section 10 and Section 2, and acknowledge and consent
        that A1 bears no responsibility or liability for the risks detailed in
        this Section 10. You hereby irrevocably waive, release, and discharge
        all claims, whether known or unknown to you, against A1 and our
        shareholders, members, directors, officers, employees, agents,
        representatives, suppliers, and contractors ("Representatives") related
        to any of the risks specified in this Section 10 and Section 2.
      </p>
      <h2>11. REWARDS AND TRANSACTIONS SCHEDULES AND FEES</h2>
      <p>
        <strong>11.1.</strong> Rewards for LayerLink will be disbursed on a
        daily basis. A1 shall calculate and distribute these rewards to eligible
        users’ accounts at the end of each day.
      </p>

      <p>
        <strong>11.2.</strong> Rewards for PairLink will be disbursed on the 1st
        of every month. A1 shall calculate and distribute these rewards to
        eligible users’ accounts at the beginning of each month.
      </p>

      <p>
        <strong>11.3.</strong> A1 reserves the right to implement flush-out
        mechanisms to ensure the integrity and balance of the rewards system.
        These mechanisms are designed to prevent the accumulation of
        disproportionate rewards and maintain system stability.
      </p>

      <p>
        <strong>11.4.</strong> Flush-out mechanisms may include, but are not
        limited to, resetting reward calculations, adjusting reward distribution
        rates, or other necessary actions to maintain fair and balanced reward
        distribution.
      </p>

      <p>
        <strong>11.5.</strong> A1 employs Key Performance Indicators (KPIs) to
        evaluate user activities and contributions. These indicators may
        influence the calculation of rewards, potentially increasing or
        decreasing payouts based on performance metrics. Users will be assessed
        on various KPIs, such as transaction volume, referral activities, and
        overall engagement.
      </p>

      <p>
        <strong>11.6.</strong> Staking rewards, PairLink rewards, and LayerLink
        rewards are dynamic and may be adjusted based on several factors,
        including but not limited to Key Performance Indicators (KPIs), total
        transaction volume, demand and supply conditions, and overall market
        conditions.
      </p>

      <p>
        <strong>11.7.</strong> A1 reserves the right to modify reward structures
        and payout rates to reflect these factors and to maintain the system's
        health and sustainability. Users will be notified of any such changes
        through official communication channels.
      </p>

      <p>
        <strong>11.8.</strong> Withdrawals, deposits, or transfers involving the
        system's native token (AONE) are not subject to any limitation and can
        be performed without any restrictions.
      </p>

      <p>
        <strong>11.9.</strong> Fees associated with deposits, withdrawals, and
        participation in referral programs will vary based on the user's account
        tier. The applicable fees are detailed in the official system fees
        structure. Network gas fees may apply as per the prevailing rates
        outlined in the system's official fee schedule.
      </p>

      <p>
        <strong>11.10.</strong> Each account may be entitled to limited-time
        offers allowing for currency withdrawals without incurring fees. These
        offers can be based on special events, campaigns, or at the discretion
        of system administration.
      </p>

      <h2>12. DISCLOSURES; DISCLAIMERS</h2>
      <p>
        <strong>12.1.</strong>
        A1 is a decentralized software platform and does not operate as a
        digital asset or derivatives exchange platform, nor does it provide
        trade execution or clearing services. A1 has no oversight, involvement,
        or control over your transactions carried out using the Services. All
        transactions occurring within the Interface are executed directly
        between users' Ethereum addresses through a smart contract in a
        peer-to-peer manner. You are responsible for ensuring compliance with
        all Applicable Laws governing your Digital Assets. Please note that, due
        to restrictions outlined in the Commodity Exchange Act and associated
        regulations from the U.S. Commodity Futures Trading Reward ("CFTC"),
        individuals designated as Blocked Persons are prohibited from
        participating in Perpetual Contracts using the Services.
      </p>
      <p>
        <strong>12.2.</strong>
        You acknowledge that A1 is not registered or licensed by any regulatory
        agency or authority. No such agency or authority has reviewed or granted
        approval for the use of the Services.
      </p>
      <p>
        <strong>12.3.</strong> To the fullest extent permissible under
        Applicable Law, the Services (including all content and functionality
        provided by us or on our behalf) are offered on an "AS IS" and "AS
        AVAILABLE" basis. We explicitly disclaim, and you hereby waive, any and
        all representations, conditions, or warranties of any nature, whether
        express or implied, legal, statutory, or otherwise. These disclaimed
        warranties may arise from statute, other areas of law, prior dealings,
        or industry practices. The disclaimed implied or legal warranties and
        conditions include, but are not limited to, merchantability,
        merchantable quality, quality, fitness for a particular purpose, title,
        security, availability, reliability, accuracy, quiet enjoyment, and
        non-infringement of third-party rights. Furthermore, we do not assert or
        guarantee that the Services, including any associated data, will be
        uninterrupted, available at specific times, or free of errors. We do not
        represent that errors in the Services are correctable or will be
        corrected.
      </p>
      <p>
        <strong>12.4.</strong> You shall safeguard, indemnify, and exonerate A1
        and our Representatives (collectively referred to as the "Indemnified
        Parties") from any claims, demands, lawsuits, actions, proceedings,
        investigations, liabilities, damages, losses, costs, or expenses,
        including reasonable legal fees, arising from or connected to (a) your
        utilization of the Services; (b) Digital Assets linked to your Ethereum
        address; (c) any feedback or user-generated content you supply to A1
        concerning the Services, if applicable; (d) your breach of these Terms;
        or (e) your infringement or misappropriation of the rights of any other
        individual or entity. In the event you have an obligation to indemnify
        any Indemnified Party, A1 (or, at our sole discretion, the relevant
        Indemnified Party) shall possess the authority, at our or their sole
        discretion, to oversee any legal action or proceeding and decide whether
        A1 intends to reach a settlement, and if so, the terms of such
        settlement. You hereby consent to cooperate with A1 in the defense of
        any such claims.
      </p>
      <h2>
        13. LIMITATION OF DAMAGES; EXCLUSION OF CONSEQUENTIAL AND RELATED
        DAMAGES
      </h2>
      <p>
        <strong>13.1.</strong> Without limiting any other provisions within
        these Terms, you explicitly agree that A1 and any of its Representatives
        shall bear no responsibility or liability for any loss or harm incurred
        by you or any third parties due to the following: (i) Delays,
        inaccessibility, or malfunctions of equipment, technology, or other
        infrastructure. (ii) Routine maintenance procedures or repairs that A1,
        our suppliers, or contractors may periodically undertake. (iii)
        Unforeseeable causes beyond A1's control or those that A1 could not
        reasonably anticipate. (iv) Disruptions and temporary or permanent
        unavailability of the underlying blockchain infrastructure. (v)
        Unavailability of third-party service providers or external partners for
        any reason. Under no circumstances will A1 or its Representatives be
        held accountable for any such loss or harm resulting from these events,
        including any obligation to compensate or reimburse for damages or
        losses caused by such occurrences. You expressly acknowledge that any
        risk of loss stemming from these events is your sole responsibility, and
        you willingly assume all such risks.
      </p>
      <p>
        <strong>13.2.</strong> In no event shall A1, our suppliers, contractors,
        or any stockholders, members, directors, officers, managers, employees,
        attorneys, agents, representatives, suppliers, or contractors associated
        with A1 (collectively referred to as the "Risk Limited Parties"), be
        liable for any incidental, indirect, special, punitive, consequential,
        or similar damages or liabilities (including damages for loss of fiat,
        assets, data, information, revenue, opportunities, use, goodwill,
        profits, or any other business or financial benefits) arising from or in
        connection with the Services, including their content and functionality,
        transaction execution or settlement, performance or non-performance of
        the Services, your Digital Assets, Perpetual Contracts, or any other
        product, service, or item provided by or on behalf of A1. This includes
        claims under contract, tort (including negligence), civil liability
        statutes, strict liability, breach of warranties, or any other theory of
        liability, whether or not we were informed of, knew of, or should have
        known of the possibility of such damages. Furthermore, this exclusion
        applies regardless of any failure of the essential purpose of these
        Terms or any limited remedy provided herein. A1 assumes no
        responsibility for the execution or settlement of transactions conducted
        between users of the Services.
      </p>
      <p>
        <strong>13.3.</strong> Without negating any provision within these
        Terms, should A1 or any affiliated party be deemed liable under these
        Terms, the combined liability of A1 (along with our equity owners,
        members, directors, managers, officers, employees, attorneys, agents,
        representatives, suppliers, or contractors), arising from or connected
        to your utilization of the Services (including their content and
        functionality), any actions or failures to act regarding the Services,
        your Digital Assets, Perpetual Contracts, or any other product, service,
        or item offered by A1 or on our behalf, whether grounded in contract,
        tort (including negligence), civil liability, statutory regulation,
        strict liability, or any other legal theory, shall not surpass the total
        fees paid by you to A1 under these Terms, if any, during the two (3)
        months immediately preceding the occurrence that led to the liability
        claim.
      </p>
      <h2>14. SUPPORT</h2>

      <p>
        <strong>14.1.</strong> Users shall direct any queries or issues related
        to their accounts, transactions, or other functionalities of A1 to the
        support team via the support email “support@a1.gold”.
      </p>

      <p>
        <strong>14.2.</strong> The support team shall be available during
        weekdays from 9:00 AM to 6:00 PM CET. Support availability may be
        subject to change during holidays or special events, and users will be
        informed of any such changes.
      </p>

      <p>
        <strong>14.3.</strong> The support team shall endeavor to respond to all
        queries within seventy-two (72) hours. The response time may vary based
        on the severity and volume of the queries received. A1 does not
        guarantee a fixed response time and shall not be liable for any delays
        beyond this period.
      </p>

      <p>
        <strong>14.4.</strong> While A1 aims to provide timely support, there
        may be instances where response times are delayed due to high query
        volumes, technical issues, or other unforeseen circumstances. Users
        shall be notified of any anticipated delays in response times.
      </p>

      <p>
        <strong>14.5.</strong> In the event a user’s query remains unresolved
        within a reasonable timeframe, the user may request an escalation. The
        support team shall prioritize escalated issues and shall address them in
        an expedited manner.
      </p>

      <p>
        <strong>14.6.</strong> A1 employs a Support Credit System to manage and
        prioritize user support requests. Each user is allocated a finite number
        of support credits, which are deducted with each support request
        submitted.
      </p>

      <p>
        <strong>14.7.</strong> The support team may flag repetitive support
        requests. Should a user submit multiple requests for the same issue,
        these requests may be designated as repetitive. For flagged repetitive
        requests, the support team may impose a waiting period before issuing a
        response. Users shall be notified if their request has been flagged and
        informed of the anticipated waiting period.
      </p>

      <p>
        <strong>14.8.</strong> Users with a higher balance of support credits
        may receive prioritized response times. The support credit system is
        designed to ensure equitable and efficient handling of all user queries.
        Support credits may be replenished periodically, and users can view
        their current support credit balance in their account settings. Details
        on the support credit system and replenishment policies are available in
        the app's help center.
      </p>

      <h2>15. GENERAL TERMS</h2>
      <p>
        <strong>15.1.</strong> Please consult our privacy policy, which is
        integrated into this agreement and can be found at
        https://a1.gold/privacy/. It contains details about how we gather,
        employ, share, and manage your information.
      </p>
      <p>
        <strong>15.2.</strong> You agree to receive all our communications,
        agreements, documents, receipts, notices, and disclosures (collectively
        referred to as "Communications") electronically in connection with these
        Terms or any Services. We may provide these Communications by posting
        them on our website, sending them to the email address associated with
        your use of the Services, or via Telegram using your provided username
        during your use of the Services. It's important to retain copies of our
        Communications, either by printing them or saving electronic copies. If
        you have questions, complaints, or claims about the Services, you can
        contact us at legal@A1.gold.
      </p>
      <p>
        <strong>15.3.</strong> Any right or remedy granted to A1 in these Terms
        is supplementary to, not a replacement for, any other right or remedy,
        whether specified in these Terms, under Applicable Law, at law, or in
        equity. The failure or delay of A1 in exercising any right, power, or
        privilege under these Terms will not constitute a waiver of that right.
      </p>
      <p>
        <strong>15.4.</strong> If any provision of these Terms is determined to
        be invalid or unenforceable, it will not affect the validity or
        enforceability of the remaining provisions, which will continue to be in
        full force and effect.
      </p>
      <p>
        <strong>15.5.</strong> We are not responsible or liable for any failure
        or delay in the performance of any Services or any losses or damages you
        may incur due to circumstances or events beyond our control. Such events
        may include natural disasters like floods, extreme weather conditions,
        earthquakes, acts of God, fires, wars, uprisings, riots, labor disputes,
        accidents, government actions, communication failures, power outages, or
        equipment or software malfunctions.
      </p>
      <p>
        <strong>15.6.</strong> You are not permitted to assign or transfer your
        rights or obligations under these Terms, including your right to use the
        Services, without our express prior written consent, whether through
        operation of law or as part of a change of control. We reserve the right
        to assign or transfer all or part of our rights or obligations under
        these Terms, with or without notice or your consent or approval.
      </p>
      <p>
        <strong>15.7.</strong> For the purposes of these Terms, unless otherwise
        specified or required by context: (a) section headings are provided for
        convenience and do not limit or interpret the sections; (b) the use of
        "include," "includes," or "including" is followed by "without
        limitation"; and (c) the use of "or" is not intended to be exclusive.
      </p>
      <p>
        <strong>15.8.</strong> These Terms constitute the entire agreement
        between you and A1, superseding all previous and contemporaneous
        understandings between the parties concerning the Services.
      </p>
      <p>
        <strong>15.9.</strong> In the event of a conflict between these Terms
        and any other agreement you have with us, these Terms will prevail,
        unless the other agreement explicitly references these Terms and states
        that it supersedes them.
      </p>
      <p>
        <strong>15.10.</strong> Except as expressly provided otherwise in this
        Agreement, there are no third-party beneficiaries to this Agreement
        apart from the Indemnified Parties.
      </p>
    </div>
  );
};

export default TermsOfUse;
