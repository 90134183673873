import { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useAccount, useChainId } from "wagmi";

export const useConnectMeta = () => {
  const [connectionLoading, setConnectionLoading] = useState(false);

  const lastConnectionType = useSelector(
    (state) => state.connect.lastConnectionType
  );
  const addressFromEmail = useSelector((state) => state.connect.address);

  const { address } = useAccount();
  const chainId = useChainId();

  // let account;

  // if (providerType === "walletConnect") account = address;
  // if (lastConnectionType === "email") {
  //   account = addressFromEmail;
  // }
  // console.log(providerType, "providerType");

  const switchToBscTestnet = async (params = []) => {
    // try {
    //   if (window.ethereum) {
    //     await window.ethereum.request({
    //       method: "wallet_addEthereumChain",
    //       params: params,
    //     });
    //     dispatch({
    //       type: "CONNECTION_ERROR",
    //       payload: "",
    //     });
    //   } else {
    //     console.log("Can't setup the BSC Testnet on BSC network");
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const values = useMemo(
    () => ({
      account: lastConnectionType === "email" ? addressFromEmail : address,
      connectionLoading,
      chainId,
      switchToBscTestnet
    }),
    [address, connectionLoading, chainId]
  );

  return values;
};
