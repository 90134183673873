import { useState, useEffect } from "react";
import { Button } from "../components/UI/button/Button";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useConnect, useAccount } from "wagmi";

import { Input } from "./UI/input/Input";
import { HelpText } from "./UI/helpText/HelpText";
import { HelpCard } from "./UI/helpCard/HelpCard";

import { useConnectMeta } from "../hooks/use-connect";
import { useApp } from "../hooks/use-app";
import MetaMask from "../assets/svg/MetaMask2";
import WalletConnect from "../assets/svg/WalletConnect2";

import axios from "../api/axios";

import "./LandingRegistration.css";

const LandingRegistration = ({
  step,
  setStep,
  setInitialRegister,
  translates,
}) => {
  const triedReconnect = useSelector((state) => state.appState?.triedReconnect);
  const appState = useSelector((state) => state.appState);
  const providerType = useSelector((state) => state.connect.providerType);
  const metaMaskConnectionLoading = useSelector((state) => state.appState?.metaMaskConnectionLoading);
  const walletconnectLoading = useSelector((state) => state.appState?.walletconnectLoading);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isConnected } = useAccount();
  const { account } = useConnectMeta();
  const { updateState, logout } = useApp();
  const { connect, connectors, error, status, variables } = useConnect();

  const [finishLoading, setFinishLoading] = useState(false);
  const [emailResend, setEmailResend] = useState(false);
  const [registrationState, setRegistrationState] = useState({
    loading: false,
    fullnameError: "",
    emailError: "",
    referralError: "",
    emailSent: false,
  });
  const [formData, setFormData] = useState({
    fullName: "",
    email: ""
  });

  useEffect(() => {
    if (account && triedReconnect) {
      setRegistrationState({
        loading: false,
        fullnameError: "",
        emailError: "",
        referralError: "",
        emailSent: false,
      });
    }
  }, [account, triedReconnect]);

  useEffect(() => {
    setEmailResend(registrationState.emailSent);
  }, [registrationState.emailSent]);

  useEffect(() => {
    if (appState?.userData) {
      setFormData({
        fullName: appState?.userData?.meta?.name ?? "",
        email: appState?.userData?.meta?.email ?? "",
      });
    }
  }, [appState?.userData]);

  const handleRegistration = async ({ fullName, email }) => {
    const errors = {};
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

    if (!fullName || /^\s*$/.test(fullName)) {
      errors.fullNameError = "Full Name is required";
    }

    if (email && !emailRegex.test(email)) {
      errors.emailError = "Invalid email";
    }

    if (!email) {
      errors.emailError = "Email is required";
    }

    if (Object.keys(errors).length > 0) {
      setRegistrationState({
        ...registrationState,
        ...errors,
      });
      return;
    }

    setRegistrationState({
      ...registrationState,
      loading: true,
    });

    const checkEmail = async () => {
      try {
        const res = await axios.post("/api/accounts/check-email", {
          email: email,
        });
        let { status, msg } = res?.data;

        if (!status) {
          setRegistrationState({
            ...registrationState,
            emailError: msg,
          });
          setRegistrationState({
            ...registrationState,
            emailError: msg,
            loading: false,
          });
          return false;
        }
        return true;
      } catch (e) {
        setRegistrationState({
          ...registrationState,
          emailError:
            "This email provider is not supported. Please use a different email provider.",
          loading: false,
        });
        return false;
      }
    };

    const emailIsValid = await checkEmail();

    if (!emailIsValid) {
      return;
    }

    async function updateProfile() {
      if (providerType === "walletConnect") {
        axios
          .post("/api/accounts/update_profile", {
            address: account,
            name: fullName,
            email,
          })
          .then((res) => {
            if (res?.data === "email sent") {
              setRegistrationState((prev) => ({
                ...prev,
                emailSent: true,
                loading: false,
              }));
            }

            handleFinish();
          })
          .catch((err) => {
            if (err?.response?.data === "email already exists & is verified") {
              setRegistrationState((prev) => ({
                ...prev,
                loading: false,
              }));
              toast.error("Email is already in use.", {
                autoClose: false,
              });
            }
          });
      }
    }

    updateProfile();
  };

  const resendEmail = async () => {
    axios.post("/api/accounts/resend-email", {
      address: account,
    });
    // .then((res) => {
    //   console.log(res.response);
    // })
    // .catch((e) => {
    //   console.log(e.response);
    // });
  };

  const handleWalletConnectSignin = () => {
    dispatch({
      type: "SET_WALLETCONNECT_LOADING",
      payload: true,
    });
    dispatch({
      type: "SET_ATTEMPT_SIGN",
      payload: {},
    });
  };

  const handleMetamaskSignin = () => {
    dispatch({
      type: "SET_METAMASK_CONNECT_LOADING",
      payload: true,
    });
    dispatch({
      type: "SET_ATTEMPT_SIGN",
      payload: {},
    });
  };

  const handleFinish = () => {
    setFinishLoading(true);

    axios
      .post("/api/accounts/handle-step", {
        active: true,
        address: account,
        step: 6,
      })
      .then((res) => {
        setStep(6);

        if (
          res.data.emailSent &&
          res.data.emailSent.message &&
          res.data.emailSent.message === "Email not sent"
        ) {
          toast.error(res.data.emailSent.message, {
            autoClose: false,
          });
        }

        axios
        .post("/api/accounts/manage_extensions", {
          address: account,
          setup: true,
          type: null
        })
        .then((res) => {
          setFinishLoading(false);
          setTimeout(() => {
            navigate("/dashboard");
          }, 1000);
          if (res?.data?.account) {
            dispatch({
              type: "UPDATE_ACTIVE_EXTENSIONS",
              payload: res.data.account.extensions,
            });
          }
          
          updateState();
        })
        .catch((e) => {
          setFinishLoading(false);
          toast.error(e?.response?.data?.message ?? "Something went wrong!", {
            autoClose: false,
          });
        });
      })
      .catch((err) => {
        setFinishLoading(false);
        toast.error(err?.response?.data?.message ?? "Something went wrong!", {
          autoClose: false,
        });
      });
  };

  const handleInputChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;

    if (name === "email") {
      let error = "";
      const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

      if (!value) {
        error = "Email is required";
      }

      if (value && !emailRegex.test(value)) {
        error = "Invalid email";
      }

      setRegistrationState({
        ...registrationState,
        emailError: error,
      });
    }

    if (name === "fullName") {
      let error = "";

      if (!value) {
        error = "Full Name is required";
      }

      setRegistrationState({
        ...registrationState,
        fullNameError: error,
      });
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleNextStep = () => {
    if (step === 2) {
      return handleRegistration(formData);
    }

    setStep(6);
  };


  return (
    <div 
      className="LandingSteps__container"
    >
      <div className="LandingSteps_main-body">
        <span onClick={() => setInitialRegister(false)} className="closeButton">
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ display: "flex" }}
          >
            <path
              d="M14.9999 29.1663C22.7916 29.1663 29.1666 22.7913 29.1666 14.9997C29.1666 7.20801 22.7916 0.833008 14.9999 0.833008C7.20825 0.833008 0.833252 7.20801 0.833252 14.9997C0.833252 22.7913 7.20825 29.1663 14.9999 29.1663Z"
              stroke="#c38c5c"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.9907 19.0086L19.0091 10.9902"
              stroke="#c38c5c"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.0091 19.0086L10.9907 10.9902"
              stroke="#c38c5c"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        {((!isConnected && !account) || step === 1) && (
          <div className="LandingSteps__step">
            <div className="LandingSteps__step__title main_ttl">
              {translates?.connect_wallet.en}
            </div>
            <div className="LandingSteps__step__content LandingSteps__step__content--wallet">
              <div
                className={`${
                  metaMaskConnectionLoading ||
                  walletconnectLoading
                    ? "LandingSteps__wallet-option-disabled"
                    : ""
                } LandingSteps__wallet-option`}
                onClick={() => {
                  dispatch({
                    type: "UPDATE_STATE",
                    isConnected: true,
                    providerType: "walletConnect",
                  });
        
                  handleMetamaskSignin();
                  
                  connect({ connector: connectors[0], chainId: 97 })
                }}
              >
                {metaMaskConnectionLoading ? (
                  <p>Loading...</p>
                ) : (
                  <MetaMask className="LandingSteps__walletmetamaskIcon" />
                )}
                MetaMask
              </div>
              <div
                className="LandingSteps__wallet-option"
                onClick={() => {
                  dispatch({
                    type: "UPDATE_STATE",
                    isConnected: true,
                    providerType: "walletConnect",
                  });
        
                  handleWalletConnectSignin();
        
                  connect({ connector: connectors[1], chainId: 56 })
                }}
              >
                {walletconnectLoading ? (
                  <p>Loading...</p>
                ) : (
                  <WalletConnect />
                )}
                Wallet Connect
              </div>
            </div>
          </div>
        )}
        {isConnected && account && step === 2 && (
          <div className="LandingSteps__step">
            <div className="LandingSteps__step__title main_ttl">
              {translates?.registration.en}
            </div>
            <div className="LandingSteps__step__content LandingSteps__step__content--register">
              <div
                className={`email_sent ${emailResend ? "email_active" : ""}`}
              >
                <HelpCard
                  status={"warning"}
                  color={"#FFA726"}
                  body={"long"}
                  active={emailResend}
                  title={"Help Text"}
                  onClick={resendEmail}
                  handleClose={() => setEmailResend(false)}
                  className={"LandingSteps__emailSent"}
                />
              </div>
              <Input
                type={"default"}
                icon={false}
                inputType={"text"}
                placeholder={translates?.enter.en}
                label={translates?.full_name.en}
                value={formData.fullName}
                onChange={handleInputChange}
                customStyles={{ width: "100%" }}
                name={"fullName"}
              />
              {registrationState?.fullNameError && (
                <HelpText
                  status={"error"}
                  title={registrationState?.fullNameError}
                  color={"#FF0C46"}
                />
              )}
              <Input
                type={"default"}
                icon={false}
                inputType={"Email"}
                placeholder={translates?.enter.en}
                label={translates?.email.en}
                value={formData.email}
                onChange={handleInputChange}
                customStyles={{ width: "100%" }}
                name={"email"}
              />
              {registrationState?.emailError && (
                <HelpText
                  status={"error"}
                  title={registrationState?.emailError}
                  color={"#FF0C46"}
                />
              )}
            </div>
            {registrationState?.error && (
              <HelpText
                status={"error"}
                title={registrationState?.error}
                color={"#FF0C46"}
              />
            )}
            <div className="LandingSteps__buttonsWrap">
              <Button
                label={"Disconnect"}
                size={"btn-lg"}
                type={"btn-secondary"}
                arrow={"arrow-none"}
                element={"button"}
                onClick={() => logout()}
                customStyles={{ margin: "0" }}
                disabled={registrationState?.loading}
              />
              <Button
                label={registrationState?.loading ? "Loading..." : "Continue"}
                size={"btn-lg"}
                type={"btn-primary"}
                arrow={"arrow-none"}
                element={"button"}
                onClick={() => handleNextStep()}
                customStyles={{ margin: "0" }}
                disabled={
                  !!registrationState?.loading ||
                  !!registrationState?.emailError ||
                  !!registrationState?.fullNameError
                }
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LandingRegistration;