import React, { useState, useMemo } from "react";
import translates from "../../translates.json";
import useFormattedNumber from "../../hooks/useFormattedNumber";
import { useValidation } from "../../hooks/useValidation";

// components
import { HelpText } from "../UI/helpText/HelpText";
import { Button } from "../UI/button/Button";
import { Input } from "../UI/input/Input";
import { Popup } from "../UI/popup/Popup";

// styles
import "./Calculator.css";

// Constants for stake limits
const MIN_STAKE = 10;
const MAX_STAKE = 500000;

export const Calculator = ({
  handleCalculatorSubmit,
  durationOptions,
  handleMaxClick,
  handleMaxClickBallance,
  customStyles,
  loading,
  isAllowance,
  account,
  timeperiod,
  handleTimePeriod,
  depositAmount,
  handleDepositAmount,
  timeperiodDate,
  handleTimeperiodDate,
  approveResponse,
  stakingLoading,
  isActive,
  handleWalletSubmit,
  hasReferralActive,
  rates,
  apyPercent,
  tokenBalance,
  walletBalance,
  exchangeRate,
  currentTier,
  rangeName,
  referralState,
  setReferralState,
  referralCodeChecked,
  checkReferralCodeState,
}) => {
  const [emptyField, setEmptyField] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [showTerms, setShowTerms] = useState(false);

  const { formatNumber } = useFormattedNumber();

  const handleChange = (e) => {
    if (e.target.value.length > 0) {
      setEmptyField(false);
    }
    handleDepositAmount(e.target.value);
  };

  function countViaRate(amount) {
    return Number((amount / Number(rates?.["atr"]?.usd))?.toFixed(2));
  }

  function handleReferralChange(event) {
    let value = event.target.value;
    let spread = {};
    if (referralState.message === "empty" && value.length > 0) {
      spread = {
        message: "",
      };
    }
    setReferralState((prev) => ({ ...prev, value: value, ...spread }));
  }

  const conditions = {
    validStakeAmount: (num) =>
      !isNaN(num) && num >= MIN_STAKE && num <= MAX_STAKE,
    invalid: (num) => false,
  };

  const helpTexts = useMemo(() => {
    if (conditions.validStakeAmount(depositAmount)) {
      return {
        amount: {
          validationType: "validStakeAmount",
          success: "Amount is valid",
          failure:
            "Amount must be less than or equal to $500,000",
        },
      };
    } else {
      return {
        amount: {
          validationType: "invalid",
          success: null,
          failure: "Amount must be between $10 and $500,000.",
        },
      };
    }
  }, [depositAmount]);

  const validationErrors = useValidation(
    {
      amount: depositAmount || "",
    },
    helpTexts
  );

  const handleSubmit = () => {
    if (depositAmount?.length < 1) {
      setEmptyField(true);
    } else {
      setEmptyField(false);
      handleCalculatorSubmit();
    }
  };

  const [stakeType, setStakeType] = useState("My Wallet");

  return (
    <div
      className={`calculator-scrollable-wrapper ${
        Number(depositAmount) >= MIN_STAKE && !isAllowance && "scroll"
      }`}
    >
      <div className={`calculator-container`} style={customStyles}>
        <div className="calculator-type-wrapper">
          <Input
            type={"label-input-select"}
            defaultData={[
              { name: "My Wallet", value: "My Wallet" },
              { name: "AONE Balance", value: "AONE Balance" },
            ]}
            customStyles={{ marginBottom: "12px" }}
            icon={false}
            emptyFieldErr={false}
            value={stakeType}
            label={translates?.choose_balance.en}
            selectHandler={(type) => setStakeType(type)}
          />
          {stakeType === "My Wallet" ? (
            <HelpText
              status="info"
              title={translates?.stake_from_your_wallet.en}
              fontSize={"font-12"}
              icon={true}
            />
          ) : (
            <HelpText
              status="warning"
              title={translates?.tokens_will_be_deducted.en}
              fontSize={"font-12"}
              icon={true}
            />
          )}
        </div>
        {isAllowance && depositAmount === "" ? (
          <></>
        ) : (
          <>
     <HelpText
              status={"warning"}
              title={`${translates?.your_currently_possess.en} ${
                stakeType === "My Wallet"
                  ? formatNumber(+tokenBalance, 2)
                  : formatNumber(walletBalance, 2)
              } ${translates?.a_worth.en} ${
                stakeType === "My Wallet"
                  ? formatNumber(tokenBalance * exchangeRate, 2)
                  : formatNumber(walletBalance * exchangeRate, 2)
              } USD.`}
              color={"#6A6D76"}
              icon={true}
              customStyles={{ marginBottom: "5px" }}
            />
          </>
        )}
        <div className={"calculator-input"}>
          <Input
            type={"number"}
            inputType={"number"}
            placeholder={"0"}
            label={"Amount (AONE)"}
            disabled={stakingLoading}
            onChange={handleChange}
            emptyFieldErr={emptyField}
            value={depositAmount}
            statusCard={
              validationErrors?.amount && (
      <HelpText
                  status={validationErrors.amount.failure ? "error" : "success"}
                  title={
                    validationErrors.amount.failure ||
                    validationErrors.amount.success
                  }
                  fontSize={"font-12"}
                  icon={true}
                />
              )
            }
          />
          <span className={"font-12"}>AONE</span>
        </div>
        {showTerms && (
          <Popup
            popUpElement={
              <div className="change-network-body">
                {translates?.by_using_this_website.en}
                <br />
                {translates?.binary_referral_position.en}
                <Button
                  label={"Accept"}
                  size={"btn-lg"}
                  type={"btn-primary"}
                  arrow={"arrow-none"}
                  element={"button"}
                  onClick={() => {
                    setAcceptedTerms(true);
                    setShowTerms(false);
                  }}
                  customStyles={{ margin: "0", width: "100%" }}
                />
              </div>
            }
            label={translates?.terms.en}
            handlePopUpClose={() => {
              setShowTerms(false);
            }}
          />
        )}
        {Number(depositAmount) >= MIN_STAKE && Number(depositAmount) <= MAX_STAKE && !hasReferralActive && (
          <div style={{ width: "100%" }}>
            <Input
              type={"default"}
              icon={false}
              inputType={"default"}
              placeholder={translates?.enter.en}
              label={translates?.referral_code.en}
              value={referralState?.value}
              onChange={(e) => handleReferralChange(e)}
              customStyles={{ width: "100%", marginTop: "5px" }}
              name={"referral"}
              emptyFieldErr={referralState?.message === "empty" ? true : false}
            />
            {referralState?.status && (
              <HelpText
                status={referralState?.status}
                title={referralState?.message}
              />
            )}
            {checkReferralCodeState?.status && (
              <HelpText
                status={checkReferralCodeState?.status}
                title={checkReferralCodeState?.message}
                icon={true}
                customStyles={{ marginBottom: "5px" }}
              />
            )}
          </div>
        )}
        <div className="calculator__buttons">
          {durationOptions.map((item, index) => (
            <Button
              key={index}
              label={item.title}
              element={"calculator-button"}
              onClick={() => {
                handleTimePeriod(item.time);
                handleTimeperiodDate(item.period);
              }}
              customStyles={{ width: "100%" }}
              active={item.time === timeperiod}
            />
          ))}
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          <div className="exchange-rate-card">
            <p className="font-14">{`${
              depositAmount ? depositAmount : 0
            } $ = ${countViaRate(depositAmount)} AONE`}</p>
          </div>
          <HelpText
            title={
              timeperiod === 0
                ? apyPercent + "% APY. Locked until " + timeperiodDate
                : timeperiod === 1
                ? apyPercent + "% APY. Locked until " + timeperiodDate
                : timeperiod === 2
                ? apyPercent + "% APY. Locked until " + timeperiodDate
                : timeperiod === 3
                ? apyPercent + "% APY. Locked until " + timeperiodDate
                : timeperiod === 4
                ? apyPercent + "% APY. Locked until " + timeperiodDate
                : timeperiod === 5
                ? apyPercent + "% APY. Locked until " + timeperiodDate
                : apyPercent + "% APY. Locked until " + timeperiodDate
            }
            status="info"
            color="#6A6D76"
            icon={true}
          />
        </div>
        {currentTier && (
          <HelpText
            title={
              <p>
                Your current tier is{" "}
                {
                  <span className="current-tier-color">
                    {currentTier?.toUpperCase()}.
                  </span>
                }{" "}
                {rangeName && (
                  <span>
                    It will be upgraded to{" "}
                    <span className="new-tier-color">
                      {rangeName?.toUpperCase()}
                    </span>
                  </span>
                )}
              </p>
            }
          />
        )}
        {isAllowance && (
    <HelpText
            title={
              "Staking AONE is unapproved, please approve AONE before staking"
            }
            status="info"
            color="#6A6D76"
            icon={true}
          />
        )}
        {approveResponse && (
<HelpText
            status={approveResponse?.status}
            title={approveResponse?.message}
            fontSize={"font-12"}
            icon={true}
          />
        )}
  {Number(depositAmount) >= MIN_STAKE && Number(depositAmount) <= MAX_STAKE && (
          <Input
            type={"checkbox"}
            label={translates?.i_agree.en}
            onChange={(e) =>
              e.target.checked ? setShowTerms(true) : setAcceptedTerms(false)
            }
            value={acceptedTerms}
            name={"checkbox"}
          />
        )}
        <Button
          element={"button"}
          label={
            account
              ? loading
                ? "Please wait, Loading.."
                : stakeType === "My Wallet"
                ? stakingLoading
                  ? "Loading..."
                  : isAllowance
                  ? "Enable"
                  : translates?.stake.en
                : translates?.withdraw_and_stake.en
              : translates?.connect_wallet.en
          }
          size={"btn-lg"}
          type={"btn-primary"}
          arrow={"arrow-none"}
          customStyles={{
            width: "100%",
          }}
          onClick={
            stakeType === "My Wallet"
              ? !account || (account && isAllowance)
                ? handleCalculatorSubmit
                : handleSubmit
              : handleWalletSubmit
          }
          disabled={
            (
              Number(depositAmount) < MIN_STAKE || Number(depositAmount) > MAX_STAKE ||
              (
                !acceptedTerms && 
                (!hasReferralActive ||
                !referralCodeChecked ||
                checkReferralCodeState?.loading) 
              )
            ) ||
            (validationErrors?.amount?.failure && account) ||
            stakingLoading ||
            !isActive
          }
        />
      </div>
    </div>
  );
};