import React from "react";
import MetaMask from "../../../assets/svg/MetaMask2";

import "./NoMetaMask.css";

export const NoMetaMask = ({ url }) => {
  return (
    <div className="no-metamask-wrap">
      <p className="no-metamask">Metamask is not installed</p>
      <div className="metamask-icon">
        <MetaMask className={"metamask-logo"} />
      </div>
      <a
        className="install-metamask"
        href="https://metamask.io/download/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Install
      </a>
    </div>
  );
};
