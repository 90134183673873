import React from "react";
import Logo from "../assets/svg/Logo";
import BitcoinLogo from "../assets/svg/BitcoinLogo";
import UsdtLogo from "../assets/svg/UsdtLogo";
import EtheriumLogo from "../assets/svg/EtheriumLogo";
import BnbLogo from "../assets/svg/BnbLogo";
import GoldLogo from "../assets/svg/GoldLogo";
import TronLogo from "../assets/svg/TronLogo";
import UsersSvg from "../assets/svg/UsersSvg";
import OwnAccountSvg from "../assets/svg/OwnAccountSvg";

export const generateCurrencyData = (mainAccount) => [
  {
    svg: <Logo className={"exchange-a1-logo"} />,
    title: "ATAR",
    price: mainAccount?.balance,
  },
  {
    svg: <BitcoinLogo />,
    title: "BTC",
    price: mainAccount?.assets?.["btc"] ?? undefined,
  },
  {
    svg: <UsdtLogo />,
    title: "USDT",
    price: mainAccount?.assets?.["usdt"] ?? undefined,
  },
  {
    svg: <EtheriumLogo />,
    title: "ETH",
    price: mainAccount?.assets?.["eth"] ?? undefined,
  },
  {
    svg: <BnbLogo />,
    title: "BNB",
    price: mainAccount?.assets?.["bnb"] ?? undefined,
  },
  {
    svg: <GoldLogo />,
    title: "GOLD",
    price: mainAccount?.assets?.["gold"] ?? undefined,
  },
  {
    svg: <TronLogo />,
    title: "TRX",
    price: mainAccount?.assets?.["trx"] ?? undefined,
  },
];

export let externalTransferObj = {
  name: "Transfer to another user",
  value: "external",
  svg: <UsersSvg />,
};

export let internalTransferObj = {
  name: "Transfer to own account",
  value: "internal",
  svg: <OwnAccountSvg />,
};

export const depositInputs = (setCurrentObject, depositLoading) => [
  {
    title: "Amount",
    name: "amount",
    type: "number",
    inputType: "number",
    disabled: depositLoading,
    placeholder: "0",
    defaultAny: "",
    rightText: "AONE",
    onChange: (e) => {
      setCurrentObject((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    },
  },
];

export const transferInputs = (
  translates,
  transferInputsSelectOpts,
  setCurrentObject,
  currentObject,
  internalTransferOptions,
  exchangeAccountType
) => [
  {
    title: translates.select_transfer_type.en,
    name: "transferType",
    type: "label-input-select",
    editable: true,
    options: transferInputsSelectOpts,
    defaultAny: "Select",
    onChange: (e) =>
      setCurrentObject((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      })),
  },
  {
    title:
      currentObject.transferType === "external"
        ? "Account Address Or Email"
        : "Select Account",
    name:
      currentObject.transferType === "external" ? "transferAddress" : "account",
    type:
      currentObject.transferType === "external"
        ? "default"
        : "label-input-select",
    options: internalTransferOptions,
    defaultAny:
      currentObject.transferType === "external" ? undefined : "Select",
    placeholder:
      currentObject.transferType === "external" ? "Enter" : undefined,
    onChange: (e) =>
      setCurrentObject((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      })),
  },
  {
    title: "Transfer amount",
    name: "amount",
    type: "number",
    inputType: "number",
    placeholder: "0",
    defaultAny: "",
    rightText:
      exchangeAccountType?.toUpperCase() === "GOLD"
        ? "XAU"
        : // : exchangeAccountType?.toUpperCase() === "PLATINUM"
        // ? "XPT"
        exchangeAccountType?.toUpperCase() === "ATAR"
        ? "AONE"
        : exchangeAccountType?.toUpperCase(),
    onChange: (e) =>
      setCurrentObject((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      })),
  },
];

export const generateExchangeInputs = (
  exchangeLoading,
  exchangeAccountType,
  setCurrentObject,
  ratedExchange,
  card
) => [
  {
    title: "Transfer amount",
    name: "transfer_amount",
    type: "number",
    inputType: "number",
    disabled: exchangeLoading,
    placeholder: "0",
    defaultAny: "",
    rightText:
      exchangeAccountType === "ATAR"
        ? "AONE"
        : exchangeAccountType?.toUpperCase(),
    onChange: (e) => {
      setCurrentObject((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
      if (e.target.value === "") {
        setCurrentObject((prev) => ({
          ...prev,
          receive_amount: "",
        }));
        return;
      }
      if (Number(ratedExchange) !== 0) {
        setCurrentObject((prev) => ({
          ...prev,
          receive_amount:
            Math.round((e.target.value / ratedExchange) * 10 ** 6) / 10 ** 6,
        }));
      } else {
        setCurrentObject((prev) => ({
          ...prev,
          receive_amount: "",
        }));
      }
    },
  },
  {
    title: "Receive amount",
    name: "receive_amount",
    type: "number",
    inputType: "number",
    disabled: exchangeLoading,
    placeholder: "0",
    defaultAny: "",
    rightText: card.title === "ATAR" ? "AONE" : card.title.toUpperCase(),
    onChange: (e) => {
      setCurrentObject((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
      if (e.target.value === "") {
        setCurrentObject((prev) => ({
          ...prev,
          transfer_amount: "",
        }));
        return;
      }
      setCurrentObject((prev) => ({
        ...prev,
        transfer_amount:
          Math.round(e.target.value * ratedExchange * 10 ** 6) / 10 ** 6,
      }));
    },
  },
];

export const generateWithdrawInputs = (
  translates,
  setCurrentObject,
  exchangeAccountType
) => [
  {
    title: translates.withdraw_amount.en,
    name: "withdraw_amount",
    type: "number",
    inputType: "number",
    defaultAny: "",
    placeholder: "0",
    rightText:
      exchangeAccountType === "ATAR"
        ? "AONE"
        : exchangeAccountType?.toUpperCase(),
    onChange: (e) => {
      setCurrentObject((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    },
  },
];
