import React from "react";

import styles from "./StakingTable.module.css";

const StakingTable = () => {
  const data = [
    {
      stage: "Novice Navigator",
      range: "10 - 4999",
      duration: ["2 Years", "3 Years"],
      apy: ["24%", "26.3%"],
    },
    {
      stage: "Stellar Standard",
      range: "5000 – 19,999",
      duration: ["2 Years", "3 Years"],
      apy: ["27.2%", "28.7%"],
    },
    {
      stage: "Expert Edge",
      range: "20,000 – 49,999",
      duration: ["2 Years", "3 Years"],
      apy: ["30.3%", "31.8%"],
    },
    {
      stage: "Platinum Privilege",
      range: "50,000 – 500,000",
      duration: ["2 Years", "3 Years"],
      apy: ["33%", "34.5%"],
    },
  ];

  return (
    <div className={styles.stakingTable}>
      <h1 className={styles.tableTitle}>AONE Staking Tiers:</h1>
      <table className={styles.table}>
        <thead>
          <tr className={styles.tr}>
            <th className={styles.th}>TIER</th>
            <th className={styles.th}>STAKING RANGE (USD)</th>
            <th className={styles.th}>DURATION</th>
            <th className={styles.th}>APR %</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <React.Fragment key={index}>
              <tr className={styles.tr}>
                <td className={styles.tdStage} rowSpan={item.duration.length}>
                  {item.stage}
                </td>
                <td className={styles.td} rowSpan={item.duration.length}>
                  {item.range}
                </td>
                <td className={styles.td}>{item.duration[0]}</td>
                <td className={styles.td}>{item.apy[0]}</td>
              </tr>
              {item.duration.slice(1).map((duration, i) => (
                <tr className={styles.tr} key={i}>
                  <td className={styles.td}>{duration}</td>
                  <td className={styles.td}>{item.apy[i + 1]}</td>
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <div className={styles.instructions}>
        <ul className={styles.bulletPoints}>
          <li>
            Staking rewards can be harvested at anytime from the Staking menu.
          </li>
          <li>
            Staking tiers are based in USD equivalent of AONE token.
          </li>
          <li>Staking rewards are in form of AONE token.</li>
        </ul>
      </div>
    </div>
  );
};

export default StakingTable;
