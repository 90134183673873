import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import QRCode from "qrcode";
import Web3 from "web3";
import BigNumber from "bignumber.js";
import { Deposit } from "../../ManageBalance/deposit/Deposit";
import { Exchange } from "../../ManageBalance/exchange/Exchange";
import { TransferFromAcc } from "../../ManageBalance/transferFromAcc/TransferFromAcc";
import { StakeCurrency } from "../../Staking/stakeCurrency/StakeCurrency";
import { ResetPassword } from "../../Auth/resetPassword/ResetPassword";
import { TwoFactorAuthentication } from "../../Auth/twoFactorAuthentication/TwoFactorAuthentication";
import { UserOptions } from "../../User/userOptions/UserOptions";
import { UserAccount } from "../../User/userAccount/UserAccount";
import { SideBar } from "../../UI/sideBar/SideBar.js";
import { Popup } from "../../UI/popup/Popup.js";
import ConfirmList from "./components/ConfirmList/ConfirmList.js";
import TradeSideBar from "./components/TradeSideBar/TradeSideBar";
import { toast } from "react-toastify";
import { writeContract, readContract } from "wagmi/actions";
import { parseEther } from "viem";

import { useApp } from "../../../hooks/use-app";
import { useConnectMeta } from "../../../hooks/use-connect";

import axios from "../../../api/axios";
import tokenAbi from "../../../abi/tokenAbi.json";
import translates from "../../../translates.json";

import { formatNumber } from "../../../utils/FormatNUmber.js";
import { config } from "../../../utils/wagmiConfig";
import {
  generateCurrencyData,
  externalTransferObj,
  internalTransferObj,
  depositInputs,
  transferInputs,
  generateExchangeInputs,
  generateWithdrawInputs,
} from "../../../dummyComponents/sidebar.js";

const tokenAddress = process.env.REACT_APP_TOKEN_ADDRESS;
const treasuryAddress = process.env.REACT_APP_TOKEN_HOLDER_TREASURY_ADDRESS;
const web3 = new Web3(process.env.REACT_APP_WEB3_PROVIDER_URL);

const SideBarRight = () => {
  const appState = useSelector((state) => state.appState);
  const userMetaData = useSelector((state) => state.appState?.userData?.meta);
  const userBalances = useSelector((state) => state.appState?.accountsData);
  const sideBar = useSelector((state) => state.appState.sideBar);
  const sideBarOpen = useSelector((state) => state.appState.sideBarOpen);
  const providerType = useSelector((state) => state.connect.providerType);
  const lastConnectionType = useSelector(
    (state) => state.connect.lastConnectionType
  );
  const rates = useSelector((state) => state.appState?.rates);
  const { activeExtensions } = useSelector((state) => state.extensions);
  const accountType = useSelector(
    (state) => state.appState?.dashboardAccountType
  );
  const exchangeAccountType = useSelector(
    (state) => state.appState?.exchangeAccountType
  );
  const tokenBalance = useSelector((state) => state.connect.tokenBalance);
  const accountsData = useSelector((state) => state.appState?.accountsData);
  const transactionFee = useSelector((state) => state.appState?.fees);
  const addressFromEmail = useSelector((state) => state.connect.address);
  const transactionAmount = useSelector(
    (state) => state.appState?.transactionAmount
  );

  const [network, setNetwork] = useState({
    networkName: "BSC Smart Chain",
    tokenStandard: "BEP-20",
  });
  const [personalData, setPersonalData] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [twoFactorAuth, setTwoFactorAuth] = useState(false);
  const [activated, setActivated] = useState(false);
  const [base32, setBase32] = useState("");
  const [qrcodeUrl, setqrCodeUrl] = useState("");
  const [signInAddress, setSignInAddress] = useState("");
  const [twoFactorSetUpState, setTwoFactorSetUpState] = useState("");
  const [depositLoading, setDepositLoading] = useState(false);
  const [withdrawSubmitLoading, setWithdrawSubmitLoading] = useState(false);
  const [chosenAccount, setChosenAccount] = useState({});
  const [card, setCard] = useState(null);
  const [ratedExchange, setRatedExchange] = useState(null);
  const [exchangeLoading, setExchangeLoading] = useState(false);
  const [recepientName, setRecepientName] = useState("");
  const [stakingLoading, setStakingLoading] = useState(false);
  const [transferSubmitLoading, setTransferSubmitLoading] = useState(false);
  const [transferCodeLoading, setTransferCodeLoading] = useState(false);
  const [transactionConfirmCode, setTransactionConfirmCode] = useState("");
  const [verificationCodeModal, setVerificationCodeModal] = useState(false);
  const [minimumExchangeAmount, setMinimumExchangeAmount] = useState(0);
  const [personalDataState, setPersonalDataState] = useState({
    emailSent: false,
    loading: false,
    saved: false,
    error: "",
  });
  const [securityDataState, setSecurityDataState] = useState({
    emailSent: false,
    loading: false,
    saved: false,
    error: "",
  });
  const [resetPasswordStatus, setresetPasswordStatus] = useState({
    loading: false,
    error: "",
    success: "",
  });
  const [currentObject, setCurrentObject] = useState({
    amount: "",
    transfer_amount: "",
    receive_amount: "",
    transfer: "",
    clientId: "",
    address: "",
    transferAddress: "",
    type: "",
    account: "",
    transferType: "external",
    withdraw_amount: "",
    receive_withdraw_amount: "",
  });

  const { account } = useConnectMeta();
  const { updateState, logout } = useApp();
  const dispatch = useDispatch();

  useEffect(() => {
    setMinimumExchangeAmount(
      +transactionAmount?.find(
        (item) =>
          item?.currency?.toLowerCase() === exchangeAccountType.toLowerCase()
      )?.minimum_transaction_limit
    );
  }, [exchangeAccountType]);

  const isValidAddress = (address) => {
    if (typeof address !== "string") return false;

    const isAddress = /^(0x)?[0-9a-fA-F]{40}$/i.test(address);

    return isAddress;
  };

  const handleClose = () => {
    dispatch({ type: "SET_SIDE_BAR", payload: { sideBarOpen: false } });
    setTimeout(() => {
      dispatch({ type: "SET_EXCHANGE_ACCOUNT_TYPE", payload: "" });
    }, 500);
  };

  const handleUserAccount = () => {
    dispatch({ type: "SET_SIDE_BAR", payload: { sideBar: "UserAccount" } });
  };

  const handleSecurityData = (formData) => {
    setSecurityDataState((prev) => ({ ...prev, loading: true, error: "" }));

    axios
      .post("/api/accounts/update_profile_auth", {
        ...formData,
        address: account,
      })
      .then((res) => {
        setSecurityDataState((prev) => ({
          ...prev,
          loading: false,
          saved: true,
        }));
        dispatch({
          type: "SET_USER_AUTH",
          payload: res.data,
        });
        setTimeout(() => {
          setSecurityDataState((prev) => ({ ...prev, saved: false }));
        }, 3000);
      })
      .catch((e) => {
        setSecurityDataState((prev) => ({
          ...prev,
          loading: false,
          error: e?.response?.data,
        }));
      });
  };

  const handlePersonalData = (userData) => {
    let personalData = { ...userData };
    personalData.avatar = account;
    setPersonalDataState((prev) => ({ ...prev, loading: true, error: "" }));
    axios
      .post("/api/accounts/update_profile", {
        ...personalData,
        address: account,
      })
      .then((res) => {
        if (res.data === "email sent") {
          setPersonalDataState((prev) => ({ ...prev, emailSent: true }));
        }

        dispatch({
          type: "SET_META_DATA",
          payload: res.data,
        });
        setPersonalDataState((prev) => ({
          ...prev,
          loading: false,
          saved: true,
        }));
        setTimeout(() => {
          setPersonalDataState((prev) => ({ ...prev, saved: false }));
        }, 3000);
      })
      .catch((e) => {
        setPersonalDataState((prev) => ({
          ...prev,
          loading: false,
          error: e?.response?.data,
        }));
      });
  };

  const resendEmail = (e) => {
    axios.post("/api/accounts/resend-email", {
      address: account ? account : signInAddress,
    });
    // .then((res) => {
    //   console.log(res.response);
    // })
    // .catch((e) => {
    //   console.log(e.response);
    // });
  };

  const verifyOTP = (code) => {
    setTwoFactorSetUpState({ loading: false, error: "" });
    axios
      .post("/api/accounts/otp/verify", {
        address: account ? account : signInAddress,
        token: code,
      })
      .then((res) => {
        setTwoFactorSetUpState({ loading: false, error: "" });
        setActivated(false);
        updateState();
      })
      .catch((e) => {
        setTwoFactorSetUpState({ loading: false, error: e.response.data });
      });
  };

  async function generateOtp() {
    try {
      await axios
        .post("/api/accounts/otp/generate", {
          address: account ? account : signInAddress,
        })
        .then((res) => {
          const { base32, otpauth_url } = res.data;
          setBase32(base32);
          QRCode.toDataURL(otpauth_url).then((data) => setqrCodeUrl(data));
        });
    } catch (err) {
      toast.error("Could not generate OTP", { autoClose: false });
    }
  }

  const handleSetUpPassword = (opt) => {
    setresetPasswordStatus({ loading: true, error: "", success: "" });
    if (opt === "email") {
      axios
        .post("/api/accounts/get-reset-password-email", {
          email: userMetaData?.email,
        })
        .then((res) => {
          setresetPasswordStatus((prev) => ({
            ...prev,
            loading: false,
            success: res.data,
          }));
        })
        .catch((e) => {
          setresetPasswordStatus((prev) => ({
            ...prev,
            loading: false,
            error: e?.response?.data,
          }));
        });
    }
  };

  const disableOTP = () => {
    axios
      .post("/api/accounts/otp/disable", {
        address: account ? account : signInAddress,
      })
      .then((res) => {})
      .catch((e) => {});
  };

  const directDeposit = (hash) => {
    axios
      .post("/api/transactions/direct_deposit", {
        address: account,
        hash,
        amount: parseFloat(currentObject.amount)
      })
      .then(async (res) => {
        if (res?.data?.message) {
          dispatch({
            type: "SET_DASHBOARD_TRANSACTIONS_DATA_RELOAD",
            payload: {},
          });

          toast.success(res?.data?.message, {
            autoClose: false,
            closeButton: true,
          });
        }

        setCurrentObject((prev) => ({
          ...prev,
          type: "",
          account: "",
          amount: "",
          transferAddress: "",
        }));
        setDepositLoading(false);
      })
      .catch(async (e) => {
        toast.error("Transaction could not be registered.", {
          autoClose: false,
        });
        setDepositLoading(false);
      });
  };

  const handleDepositSubmit = async () => {
    setDepositLoading(true);

    const delay = new Promise((resolve) => setTimeout(resolve, 1000));
    if (!account) {
      toast.error("Please connect your wallet.", { autoClose: false });
      await delay;
      setDepositLoading(false);
      return;
    }

    if (
      isNaN(currentObject.amount) ||
      Number(currentObject.amount) <= 0 ||
      !currentObject.amount
    ) {
      toast.error("Please enter a valid amount.", { autoClose: false });
      await delay;
      setDepositLoading(false);
      return;
    }

    // if (providerType === "metaMask") {
    //   const fromAddress = account;

    //   const tokenContract = new library.eth.Contract(tokenAbi, tokenAddress);

    //   const amount = library.utils.toBN(
    //     library.utils.toWei(currentObject.amount.toString(), "ether")
    //   );

    //   const gasPrice = await library.eth.getGasPrice();

    //   const transferData = tokenContract.methods
    //     .transfer(treasuryAddress, amount.toString())
    //     .encodeABI();

    //   const transactionObject = {
    //     from: fromAddress,
    //     to: tokenAddress,
    //     data: transferData,
    //     gasPrice,
    //   };

    //   library.eth
    //     .sendTransaction(transactionObject)
    //     .then((receipt) => {
    //       if (receipt && receipt.status) {
    //         directDeposit(receipt?.transactionHash, "ATR");
    //       }
    //     })
    //     .catch(async (error) => {
    //       if (error.message.includes("User denied transaction signature")) {
    //         toast.error("Transaction rejected.", { autoClose: false });
    //         await delay;
    //         setDepositLoading(false);
    //         return;
    //       }
    //       toast.error("Transaction failed.", { autoClose: false });
    //       await delay;
    //       setDepositLoading(false);
    //     });
    // }

    if (providerType === "walletConnect") {
      const makeDepositAsync = async () => {
        try {
        let contract = new web3.eth.Contract(tokenAbi, tokenAddress);

        const decimals = await readContract(config, {
          address: tokenAddress,
          abi: tokenAbi,
          functionName: "decimals",
          args: [],
        });
        
        let pow = new BigNumber(10).pow(decimals);
        let amountIn = new BigNumber(currentObject.amount.toString()).multipliedBy(pow);
        let amountInString = amountIn.toFixed();

        const gasLimit = await contract.methods
        .transfer(treasuryAddress, amountInString)
        .estimateGas({ from: account }) * 1.2;
        const gasPrice = await web3.eth.getGasPrice();
        
        let hash = await writeContract(config, { 
          address: tokenAddress,
          abi: tokenAbi,
          functionName: "transfer",
          args: [treasuryAddress, parseEther(currentObject.amount)],
          gas: Math.ceil(gasLimit),
          gasPrice: gasPrice,
        });

        if (hash) {
          //console.log(hash);
         directDeposit(hash);
        }
      }
      catch (error) {
        // Handle and display different types of errors
        if (error.message.includes('User denied transaction')) {
          toast.error("Transaction was rejected by the user.");
        } else if (error.message.includes('insufficient funds')) {
          toast.error("Insufficient funds to complete the transaction.");
        } else if (error.message.includes('out of gas')) {
          toast.error("Transaction failed due to insufficient gas.");
        } else if (error.message.includes('gas limit')) {
          toast.error("Gas limit is too low. Please try again with a higher gas limit.");
        } else {
          toast.error("An unexpected error occurred: " + error.message);
        }
        console.error("Error during transaction:", error);
      }
  };

      makeDepositAsync();
    }
  };

  const handleWithdrawSubmit = async () => {
    const delay = new Promise((resolve) => setTimeout(resolve, 1000));

    if (
      !appState?.userData?.meta?.verified &&
      !appState?.userData?.meta?.email
    ) {
      toast.error("Please verify you email or connect account", {
        autoClose: false,
      });
      await delay;
      setWithdrawSubmitLoading(false);
      return;
    }

    if (!isValidAddress(account) && lastConnectionType === "web3") {
      toast.error("Address is not valid.", { autoClose: false });
      await delay;
      setWithdrawSubmitLoading(false);
      return;
    }

    if (isNaN(currentObject.withdraw_amount)) {
      toast.error("Please enter a valid amount.", { autoClose: false });
      await delay;
      setWithdrawSubmitLoading(false);
      return;
    }

    if (currentObject.withdraw_amount < +minimumExchangeAmount) {
      toast.error(`Minimum amount is ${minimumExchangeAmount}`, {
        autoClose: false,
      });
      await delay;
      setWithdrawSubmitLoading(false);
      return;
    }

    setWithdrawSubmitLoading(true);

    // wc
    const makeWithdrawal = () => {
      axios
        .post("/api/transactions/make_withdrawal", {
          address_to: account,
          amount: currentObject.withdraw_amount,
          accountType: exchangeAccountType,
          fee: 2,
          rate:
            exchangeAccountType === "ATAR"
              ? rates?.["atr"]?.usd
              : rates?.[exchangeAccountType]?.usd,
        })
        .then(async (res) => {
          //let hash = res.data.hash;

          if (res?.data.success) {
            toast.success(res?.data?.message, {
              autoClose: false,
              closeButton: true,
            });

            updateState();

            dispatch({
              type: "SET_DASHBOARD_TRANSACTIONS_DATA_RELOAD",
              payload: {},
            });

            setCurrentObject((prev) => ({
              ...prev,
              type: "",
              account: "",
              address: "",
              receive_withdraw_amount: "",
              withdraw_amount: "",
            }));
          }

          await delay;
          setWithdrawSubmitLoading(false);
        })
        .catch(async (e) => {
          let error;
          if (e?.response?.data?.message === "Main account is not active") {
            error = "This account is disabled. Please contact support.";
          } else if (e.response?.data?.message === "Insufficient funds") {
            error = "Insufficient balance";
          } else if (
            e.response?.data?.message ===
            "Withdrawal with this amount is not possible at the moment"
          ) {
            error =
              "Withdrawal with this amount is not possible at this moment";
          }
          toast.error(error ?? "Withdrawal failed.", { autoClose: false });
          await delay;
          setWithdrawSubmitLoading(false);
        });
    };

    makeWithdrawal();
  };

  const handleTransferSubmit = async () => {
    if (!confirm && currentObject.amount >= minimumExchangeAmount) {
      setConfirm(true);
      return;
    } else if (!confirm && currentObject.amount < minimumExchangeAmount) {
      toast.error(`Minimum amount is ${minimumExchangeAmount}`, {
        autoClose: false,
      });
      return;
    }

    setTransferSubmitLoading(true);

    const delay = new Promise((resolve) => setTimeout(resolve, 3000));

    let errorMsg = null;

    if (!currentObject.account && currentObject.transferType === "internal") {
      errorMsg = "Select account";
      toast.error(errorMsg, { autoClose: false });
      await delay;
      setTransferSubmitLoading(false);
      setConfirm(false);
      return;
    }

    if (
      Number(currentObject.amount) <= 0 ||
      isNaN(currentObject.amount) ||
      !currentObject.amount
    ) {
      errorMsg = "Incorrect amount";
      toast.error(errorMsg, { autoClose: false });
      await delay;
      setTransferSubmitLoading(false);
      setConfirm(false);
      return;
    }

    try {
      let transferPromise;
      if (accountType === "main" && exchangeAccountType !== "ATAR") {
        transferPromise = axios.post("/api/transactions/make_transfer", {
          to: currentObject.transferAddress,
          amount: currentObject.amount,
          currency: exchangeAccountType,
          tx_currency: "ether",
          account_category_from: "main",
          account_category_to: "main",
        });
      } else if (currentObject.transferType === "external") {
        transferPromise = axios.post("/api/transactions/make_transfer", {
          to: currentObject.transferAddress,
          amount: currentObject.amount,
          tx_currency: "ether",
          account_category_from: "main",
          account_category_to: "main",
        });
      } else if (currentObject.transferType === "internal") {
        transferPromise = axios.post("/api/transactions/make_transfer", {
          to: account ? account : appState?.userData?.meta?.address,
          amount: currentObject.amount,
          tx_currency: "ether",
          account_category_from: accountType,
          account_category_to: currentObject.account,
          tx_type: "Internal Transfer",
        });
      }

      const [res] = await Promise.all([transferPromise]);

      if (res?.data === "Verification code has been sent") {
        setVerificationCodeModal(true);
        setConfirm(false);
        toast.success("Verification code has been sent.", { autoClose: 8000 });
      } else if (res.data?.data?.updatedAcc) {
        if (currentObject.transferType === "external") {
          dispatch({
            type: "SET_SYSTEM_ACCOUNT_DATA",
            payload: res.data.data.updatedAcc,
          });
          dispatch({
            type: "SET_DASHBOARD_TRANSACTIONS_DATA_RELOAD",
            payload: {},
          });

          updateState();
          toast.success("Transfer was successful.", { autoClose: 8000 });

          setCurrentObject((prev) => ({
            ...prev,
            type: "",
            account: "",
            amount: "",
            transferAddress: "",
          }));
          setConfirm(false);
        } else if (currentObject.transferType === "internal") {
          updateState();

          dispatch({
            type: "SET_DASHBOARD_TRANSACTIONS_DATA_RELOAD",
            payload: {},
          });

          setConfirm(false);
          toast.success("Transfer was successful.", { autoClose: 8000 });

          setCurrentObject((prev) => ({
            ...prev,
            type: "",
            amount: "",
            transferAddress: "",
          }));
        }
      }
    } catch (e) {
      if (
        e?.response?.data ===
        "We dont have such address registered in our system."
      ) {
        errorMsg = "Incorrect to address";
      } else if (e?.response?.data === "Cannot transfer from this account") {
        errorMsg = "This account is disabled. Please contact support.";
      } else if (e?.response?.data === "Cannot transfer to this account") {
        errorMsg = "Recipient has not activated account";
      } else if (e?.response?.data === "Insufficient funds") {
        errorMsg = "Insufficient funds";
      } else if (e?.response?.data === "Insufficient funds or locked funds") {
        errorMsg = "Funds are insufficient or locked";
      } else if (e?.response?.data === "You can not trasnfer to same account") {
        errorMsg = "You can not trasnfer to same account";
      }

      setConfirm(false);

      toast.error(errorMsg ?? "Transfer failed.", { autoClose: false });
    }
    await delay;

    setTransferSubmitLoading(false);
  };

  const handleVerifyEmail = async () => {
    axios
      .post("/api/accounts/resend-email", {
        address: account,
      })
      .then((res) => {
        toast.success("Email sent successfully.", { autoClose: 8000 });
      })
      .catch((e) => {
        toast.error("Email could not be sent.", { autoClose: false });
      });
  };
  const handleExchangeSubmit = async () => {
    const delay = new Promise((resolve) => setTimeout(resolve, 1000));

    setExchangeLoading(true);
    if (confirm && currentObject.transfer_amount !== "") {
      await axios
        .post("/api/transactions/exchange", {
          address: account,
          fromAccType: exchangeAccountType,
          fromAmount: Number(currentObject.transfer_amount),
          toAccType: card.title === "ATAR" ? "ATAR" : card.title.toLowerCase(),
          toAmount: Number(currentObject.receive_amount),
        })
        .then(async () => {
          updateState();
          dispatch({
            type: "SET_DASHBOARD_TRANSACTIONS_DATA_RELOAD",
            payload: {},
          });
          toast.success("Exchange successful.", { autoClose: 8000 });
          await delay;
          setExchangeLoading(false);
          setConfirm(false);
          setCurrentObject((prev) => ({
            ...prev,
            address: "",
            type: "",
            account: "",
            amount: "",
            transferAddress: "",
            transfer_amount: "",
            receive_amount: "",
          }));
        })
        .catch(async (e) => {
          let error;
          if (e?.response?.data?.message === "main account is not active") {
            error = "This account is disabled. Please contact support.";
          } else if (e?.response?.data?.message === "insufficient balance") {
            error = "Insufficient balance.";
          }

          toast.error(error ?? "Exchange failed.", { autoClose: false });
          await delay;
          setExchangeLoading(false);
          setConfirm(false);
        });
    } else {
      setExchangeLoading(false);
      setConfirm(true);
      if (currentObject.transfer_amount === "") {
        toast.error("Please enter transfer amount.", { autoClose: false });
        setConfirm(false);
      }
    }
  };

  async function handleStakeCurrency() {
    try {
      setStakingLoading(true);
      await axios.post("/api/transactions/stake_currency", {
        address: account,
        amount: Number(currentObject.amount),
        duration: confirm,
        currency: exchangeAccountType,
        percentage:
          rates?.stakingAPY[exchangeAccountType][
            parseInt(confirm.split(" ")[0], 10)
          ],
      });
      setStakingLoading(false);
      setConfirm(false);
      updateState();
      toast.success("Staking successful.", { autoClose: 8000 });
    } catch (e) {
      toast.error("Staking failed.", { autoClose: false });
      setStakingLoading(false);
      setConfirm(false);
    }
  }

  useEffect(() => {
    if (appState.otp_verified) setTwoFactorAuth(appState.otp_verified);
  }, [appState.otp_verified]);

  useEffect(() => {
    if (userMetaData) {
      setSignInAddress(appState?.userData?.account_owner);
      setPersonalData({
        name: userMetaData.name ? userMetaData.name : "",
        email: userMetaData.email ? userMetaData.email : "",
        mobile: userMetaData?.mobile ? userMetaData?.mobile : "",
        date_of_birth: userMetaData.date_of_birth
          ? new Date(userMetaData.date_of_birth)
          : new Date(),
        nationality: userMetaData.nationality ? userMetaData.nationality : "",
        avatar: userMetaData.avatar ? userMetaData.avatar : "",
      });
    } else {
      setPersonalData({
        name: "",
        email: "",
        mobile: "",
        date_of_birth: new Date(),
        nationality: "",
        avatar: "",
      });
    }
  }, [userMetaData]);

  useEffect(() => {
    if (userBalances.length > 0) {
      const chosenAcc = userBalances?.find(
        (item) => item?.account_category === accountType
      );

      setChosenAccount(chosenAcc);
    }
  }, [userBalances, accountType]);

  useEffect(() => {
    if (accountType === "main") {
      setCurrentObject((prev) => ({
        ...prev,
        transferType: "external",
      }));
    } else {
      setCurrentObject((prev) => ({
        ...prev,
        transferType: "internal",
        account: "main",
      }));
    }
  }, [accountType, exchangeAccountType]);

  useEffect(() => {
    if (sideBarOpen) {
      dispatch({
        type: "SET_SIDE_BAR",
        payload: { sideBarOpen: false },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountType]);

  useEffect(() => {
    if (card && rates?.btc && exchangeAccountType) {
      setRatedExchange(
        Number(
          (card.title === "ATAR"
            ? rates?.["atr"]?.usd
            : rates[card.title.toLowerCase()].usd) /
            (exchangeAccountType === "ATAR"
              ? rates?.["atr"]?.usd
              : rates[exchangeAccountType].usd)
        ).toFixed(6)
      );
    }
  }, [
    exchangeAccountType,
    card,
    currentObject.transfer_amount,
    currentObject.receive_amount,
    rates,
  ]);

  useEffect(() => {
    if (card && card.title && exchangeAccountType) {
      setCurrentObject((prev) => ({
        ...prev,
        receive_amount: "",
        transfer_amount: "",
      }));
    }
  }, [card, exchangeAccountType]);

  useEffect(() => {
    if (exchangeAccountType) {
      setCard(null);
    }
  }, [exchangeAccountType]);

  useEffect(() => {
    if (currentObject.transferAddress) {
      axios
        .post("/api/accounts/get_recepient_name", {
          address: currentObject.transferAddress,
        })
        .then((res) => {
          setRecepientName(res.data.name);
        })
        .catch((err) => {
          setRecepientName("");
        });
    }
  }, [currentObject.transferAddress]);

  useEffect(() => {
    setTimeout(() => {
      setCurrentObject((prev) => ({
        amount: "",
        transfer_amount: "",
        receive_amount: "",
        transfer: "",
        clientId: "",
        address: "",
        transferAddress: "",
        type: "",
        account: "",
        transferType: "external",
      }));
    }, 500);
  }, [sideBar, sideBarOpen, accountType, exchangeAccountType]);

  const internalTransferOptions = useMemo(() => {
    const options = [];

    if (accountType === "main") {
      options.push({ name: "Trade", value: "trade" });
      if (activeExtensions.loan === "true") {
        options.push({ name: "Loan", value: "loan" });
      }
    } else {
      options.push({ name: "Main", value: "main" });
    }

    return options;
  }, [activeExtensions, accountType]);

  let transferInputsSelectOpts = [];

  if (accountType === "main") {
    if (exchangeAccountType !== "ATAR") {
      transferInputsSelectOpts.push(externalTransferObj);
    } else if (
      userBalances.filter(
        (acc) =>
          acc.account_category !== "main" &&
          acc.account_category !== "external" &&
          acc.account_category !== "system"
      ).length > 0
    ) {
      transferInputsSelectOpts.push(externalTransferObj);
      transferInputsSelectOpts.push(internalTransferObj);
    } else {
      transferInputsSelectOpts.push(externalTransferObj);
    }
  } else {
    transferInputsSelectOpts.push(internalTransferObj);
  }

  const mainAccount = useMemo(
    () => userBalances.find((acc) => acc.account_category === "main"),
    [userBalances]
  );

  const exchangeAccounts = useMemo(() => {
    const filteredArr = generateCurrencyData(mainAccount).filter(
      (item) => item.title !== exchangeAccountType?.toUpperCase()
    );

    return filteredArr;
  }, [mainAccount, exchangeAccountType]);

  const exchangeInputs = useMemo(() => {
    if (card) {
      let arr = generateExchangeInputs(
        exchangeLoading,
        exchangeAccountType,
        setCurrentObject,
        ratedExchange,
        card
      );

      return arr;
    }
  }, [card, exchangeAccountType, ratedExchange]);

  const withdrawInputs = useMemo(() => {
    return generateWithdrawInputs(
      translates,
      setCurrentObject,
      exchangeAccountType,
      withdrawSubmitLoading
      // transactionFee[0]?.fixed_fee
    );
  }, [currentObject, withdrawSubmitLoading]);

  async function handleTransferCodeSumbit(code) {
    try {
      setTransferCodeLoading(true);
      await axios.post("/api/transactions/verify_external_transaction", {
        code: code?.trim(),
      });
      setTransferCodeLoading(false);
      setTransactionConfirmCode("");
      setCurrentObject((prev) => ({
        ...prev,
        transferAddress: "",
        address: "",
        amount: "",
      }));
      updateState();
      setVerificationCodeModal(false);
      toast.success("Transaction confirmed", {
        autoClose: 8000,
      });
      dispatch({
        type: "SET_DASHBOARD_TRANSACTIONS_DATA_RELOAD",
        payload: {},
      });
    } catch (e) {
      setTransferCodeLoading(false);
      toast.error(e?.response?.data ?? "Something went wrong", {
        autoClose: false,
      });
    }
  }

  const accountBalance =
    exchangeAccountType === "ATAR"
      ? accountType === "main"
        ? formatNumber(chosenAccount?.balance)
        : formatNumber(chosenAccount?.balance - appState?.userData?.stakedTotal)
      : formatNumber(mainAccount?.assets?.[exchangeAccountType]);

  const accountBalanceSecond = `${
    exchangeAccountType === "ATAR"
      ? formatNumber(chosenAccount?.balance)
      : formatNumber(
          mainAccount?.assets?.[exchangeAccountType] +
            mainAccount?.assets?.[`${exchangeAccountType}Staked`]
        )
  }`;

  const cardImg = `/img/dashboard/${
    exchangeAccountType === "ATAR" ? "atar" : exchangeAccountType?.toLowerCase()
  }.png`;

  const fixedFee = transactionFee && transactionFee[0]?.fixed_fee;
  const exchaingCurrency =
    exchangeAccountType === "ATAR" ? "AONE" : exchangeAccountType;

  const reciveAmount =
    exchaingCurrency !== "AONE"
      ? (currentObject?.withdraw_amount * rates?.[exchangeAccountType]?.usd -
          fixedFee * rates?.atr?.usd) /
        rates?.[exchangeAccountType]?.usd
      : currentObject?.withdraw_amount - fixedFee;

  const withdrawWarningCondition = !currentObject?.withdraw_amount
    ? `Withdrawal from ${exchaingCurrency?.toUpperCase()} main account balance is immediate. Minimum withdrawal amount is ${minimumExchangeAmount} AONE. Withdrawal fee (including network gas fee) is ${fixedFee} AONE that will be deducted from your withdrawal amount.`
    : currentObject?.withdraw_amount >= minimumExchangeAmount
    ? `You will receive ${reciveAmount} ${exchaingCurrency.toUpperCase()}. Withdrawal Fee is ${fixedFee} AONE.`
    : currentObject?.withdraw_amount < minimumExchangeAmount &&
      currentObject?.withdraw_amount
    ? `Withdrawal amount is less than the minimum amount of ${minimumExchangeAmount} ${exchaingCurrency?.toUpperCase()}.`
    : ``;

  return (
    <>
      {twoFactorAuth && activated && (
        <Popup
          handlePopUpClose={() => setTwoFactorAuth(false)}
          popUpElement={
            <TwoFactorAuthentication
              confirmAuth={(code) => verifyOTP(code)}
              qrcode={qrcodeUrl}
              accountName={"AONE"}
              accountKey={base32}
              twoFactorSetUpState={twoFactorSetUpState}
              onClick={() => setTwoFactorAuth(false)}
            />
          }
        />
      )}
      {sideBar === "transfer" && confirm && (
        <Popup
          popUpElement={
            <ConfirmList
              recepientName={recepientName}
              currentObject={currentObject}
              handleTransferSubmit={handleTransferSubmit}
              transferSubmitLoading={transferSubmitLoading}
              sideBar={sideBar}
              fee={0}
            />
          }
          label={"Confirm your transaction"}
          handlePopUpClose={() => {
            setConfirm(false);
            setTransferSubmitLoading(false);
          }}
        />
      )}
      {verificationCodeModal && (
        <Popup
          popUpElement={
            <ConfirmList
              transactionConfirmCode={transactionConfirmCode}
              setTransactionConfirmCode={setTransactionConfirmCode}
              transferCodeLoading={transferCodeLoading}
              handleTransferCodeSumbit={handleTransferCodeSumbit}
              verificationCodeModal={verificationCodeModal}
            />
          }
          label={"Confirm your transaction"}
          handlePopUpClose={() => {
            setConfirm(false);
            setTransferCodeLoading(false);
            setVerificationCodeModal(false);
            setTransactionConfirmCode("");
          }}
        />
      )}
      {sideBar === "exchange" && confirm && (
        <Popup
          popUpElement={
            <ConfirmList
              exchangeAccountType={exchangeAccountType}
              currentObject={currentObject}
              card={card}
              handleExchangeSubmit={handleExchangeSubmit}
              sideBar={sideBar}
              exchangeLoading={exchangeLoading}
              ratedExchange={ratedExchange}
              accountType={exchangeAccountType}
            />
          }
          label={"Confirm your transaction"}
          handlePopUpClose={() => {
            setConfirm(false);
            setExchangeLoading(false);
          }}
        />
      )}
      {sideBar === "stake" && confirm && (
        <Popup
          popUpElement={
            <ConfirmList
              confirm={confirm}
              amount={currentObject.amount}
              exchangeAccountType={exchangeAccountType}
              rates={rates}
              stakingLoading={stakingLoading}
              handleStakeCurrency={handleStakeCurrency}
              sideBar={sideBar}
            />
          }
          label={"Confirm your transaction"}
          handlePopUpClose={() => {
            setConfirm(false);
            setTransferSubmitLoading(false);
          }}
        />
      )}
      <SideBar open={appState.sideBarOpen}>
        {sideBar === "connect" && (
          <UserOptions
            type={"Metamask"}
            warning={!appState.emailVerified}
            completeAccount={handleUserAccount}
            sideBarClose={handleClose}
            disconnect={() => logout()}
            userAccount={handleUserAccount}
            account={
              account?.toLowerCase() || appState?.userData?.account_owner
            }
            mainAccount={mainAccount?.address}
            tokenBalance={tokenBalance}
            userMetaData={userMetaData}
          />
        )}
        {sideBar === "UserAccount" && (
          <UserAccount
            sideBarClose={handleClose}
            goBack={() =>
              dispatch({
                type: "SET_SIDE_BAR",
                payload: { sideBar: "connect" },
              })
            }
            personalData={personalData}
            handlePersonalData={handlePersonalData}
            handleSecurityData={handleSecurityData}
            handleVerifyEmail={handleVerifyEmail}
            emailVerified={appState.emailVerified}
            personalDataState={personalDataState}
            securityDataState={securityDataState}
            resendEmail={(e) => resendEmail(e)}
            hasPasswordSet={appState.hasPasswordSet}
            imgValue={`https://cubitrix-node-server.onrender.com/images/${account}.png`}
            twoFactorAuth={twoFactorAuth}
            handleTwoFactorAuth={(val) => {
              setTwoFactorAuth(val);
              setActivated(val);
              if (!val) disableOTP();
              if (val) {
                generateOtp();
              }
            }}
            handleForgetPassword={() =>
              dispatch({
                type: "SET_SIDE_BAR",
                payload: { sideBar: "resetPassword" },
              })
            }
          />
        )}
        {sideBar === "resetPassword" && (
          <ResetPassword
            sideBarClose={handleClose}
            goBack={() =>
              dispatch({
                type: "SET_SIDE_BAR",
                payload: { sideBar: "UserAccount" },
              })
            }
            resetPasswordState={resetPasswordStatus}
            handleResetPassword={handleSetUpPassword}
            resetEmail={userMetaData?.email}
          />
        )}
        {sideBar === "notifications" && <div>notifications</div>}
        {sideBar === "withdraw" && (
          <TransferFromAcc
            label={"Withdraw"}
            sideBarClose={handleClose}
            inputs={withdrawInputs}
            currentObject={currentObject}
            cardImg={cardImg}
            handleSubmit={handleWithdrawSubmit}
            setNetwork={setNetwork}
            network={network}
            buttonLabel={withdrawSubmitLoading ? "Loading..." : "Withdraw"}
            withdrawSubmitLoading={withdrawSubmitLoading}
            accountType={exchangeAccountType}
            accountBalance={accountBalance}
            accountBalanceSecond={accountBalanceSecond}
            chosenAccount={chosenAccount?.account_category}
            transactionFee={transactionFee}
            account={account}
            type={"Withdraw"}
            helpTitle={withdrawWarningCondition}
          />
        )}
        {sideBar === "exchange" && (
          <Exchange
            label={"Exchange"}
            sideBarClose={handleClose}
            inputs={exchangeInputs}
            currentObject={currentObject}
            cardImg={cardImg}
            accounts={exchangeAccounts}
            handleSubmit={handleExchangeSubmit}
            buttonLabel={exchangeLoading ? "Loading..." : "Exchange"}
            exchangeLoading={exchangeLoading}
            accountType={
              exchangeAccountType === "ATAR" ? "AONE" : exchangeAccountType
            }
            setCard={setCard}
            card={card}
            ratedExchange={ratedExchange}
            accountBalance={accountBalance}
            accountBalanceSecond={accountBalanceSecond}
          />
        )}
        {sideBar === "deposit" && (
          <Deposit
            label={"Deposit"}
            sideBarClose={handleClose}
            inputs={depositInputs(setCurrentObject, depositLoading)}
            currentObject={currentObject}
            cardImg={"/img/dashboard/atar.png"}
            handleSubmit={handleDepositSubmit}
            buttonLabel={depositLoading ? "Loading..." : "Deposit"}
            depositLoading={depositLoading}
            accountType={"AONE"}
            accountBalance={formatNumber(chosenAccount?.balance)}
            accountBalanceSecond={`$${formatNumber(
              chosenAccount?.balance * rates?.["atr"]?.usd
            )}`}
            // library={library}
            account={account}
            tokenBalance={tokenBalance}
            providerType={providerType}
          />
        )}
        {sideBar === "transfer" && (
          <TransferFromAcc
            label={"Transfer From Account"}
            sideBarClose={handleClose}
            inputs={transferInputs(
              translates,
              transferInputsSelectOpts,
              setCurrentObject,
              currentObject,
              internalTransferOptions,
              exchangeAccountType
            )}
            currentObject={currentObject}
            cardImg={cardImg}
            setNetwork={setNetwork}
            network={network}
            handleSubmit={handleTransferSubmit}
            buttonLabel={transferSubmitLoading ? "Loading..." : "Transfer"}
            transferSubmitLoading={transferSubmitLoading}
            accountType={exchangeAccountType}
            accountBalance={accountBalance}
            accountBalanceSecond={accountBalanceSecond}
            chosenAccount={chosenAccount.account_category}
          />
        )}
        {sideBar === "stake" && (
          <StakeCurrency
            label={"Stake Currency"}
            sideBarClose={handleClose}
            inputs={depositInputs(setCurrentObject, depositLoading)}
            currentObject={currentObject}
            cardImg={cardImg}
            handleSubmit={(duration) => setConfirm(duration)}
            buttonLabel={stakingLoading ? "Loading..." : "Stake"}
            stakeLoading={stakingLoading}
            accountType={exchangeAccountType}
            accountBalance={accountBalance}
            accountBalanceSecond={accountBalanceSecond}
            stakingAPY={
              exchangeAccountType !== ""
                ? rates?.stakingAPY[exchangeAccountType]
                : ""
            }
          />
        )}
        {sideBar === "trade" && <TradeSideBar />}
      </SideBar>
    </>
  );
};

export default SideBarRight;
