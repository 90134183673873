import React from "react";

import styles from "./UniLevelStagesTable.module.css";

const CommissionTable = () => {
  const levels = [
    { level: 1, commission: "10%" },
    { level: 2, commission: "5%" },
    { level: 3, commission: "2%" },
    { level: 4, commission: "1%" },
    { level: 5, commission: "0.5%" },
    { level: 6, commission: "0.5%" },
    { level: 7, commission: "0.25%" },
    { level: 8, commission: "0.25%" },
    { level: 9, commission: "0.25%" },
    { level: 10, commission: "0.25%" },
  ];

  return (
    <div className={styles.greenBackground}>
      <div className={styles.tableContainer}>
        <h2 className={styles.header}>LayerLink Reward Distribution:</h2>
        <table className={styles.table}>
          <thead>
            <tr>
              <th className={styles.th}>LEVEL</th>
              <th className={styles.th}>REWARD</th>
            </tr>
          </thead>
          <tbody>
            {levels.map((item) => (
              <tr key={item.level} className={styles.tr}>
                <td className={styles.td}>{item.level}</td>
                <td className={styles.td}>{item.commission}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <p className={styles.note}>
          * Direct Referrals are unlimited. Have as many as you desire.
          <br />* LayerLink Referrals are credited the following day.
        </p>
      </div>
    </div>
  );
};

export default CommissionTable;
