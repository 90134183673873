import { NoHistoryIcon } from "../assets/svg";

export const transactionHeader = [
  {
    name: "Hash",
    width: 20,
    id: 0,
    height: "40px",
  },
  {
    name: "From",
    width: 20,
    id: 1,
    height: "40px",
  },
  {
    name: "to",
    width: 20,
    id: 2,
    height: "40px",
  },
  {
    name: "Type",
    width: 10,
    mobileWidth: 50,
    id: 3,
    height: "40px",
  },
  {
    name: "Amount",
    width: 10,
    mobileWidth: 50,
    id: 4,
    height: "40px",
  },
  {
    name: "Time",
    width: 10,
    id: 5,
    height: "40px",
  },
  {
    name: "Status",
    width: 10,
    id: 6,
    height: "40px",
  },
];

export const transactionsTableEmpty = {
  label: "No Transaction History",
  icon: <NoHistoryIcon />,
};

export const footer = {
  link: "/referral",
  label: "All Code",
};

export const inputs = (setFilterObject, setItemsLimit) => [
  {
    title: "Choose Account",
    name: "account",
    type: "label-input-select",
    options: [
      { name: "All", value: "all" },
      { name: "Main", value: "main" },
      { name: "Trade", value: "trade" },
      // { name: "Loan", value: "loan" },
    ],
    defaultAny: "Any Account",
    onChange: (e) =>
      setFilterObject((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      })),
  },
  {
    title: "Choose Type",
    name: "type",
    type: "label-input-select",
    options: [
      { name: "All", value: "all" },
      { name: "Payment", value: "payment" },
      { name: "Deposit", value: "deposit" },
      { name: "Transfer", value: "transfer" },
      { name: "Internal Transfer", value: "internal_transfer" },
      { name: "Withdraw", value: "withdraw" },
      { name: "Referral Reward", value: "bonus" },
      { name: "Currency Stake", value: "currency stake" },
    ],
    defaultAny: "Any Type",
    onChange: (e) =>
      setFilterObject((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      })),
  },
  {
    title: "Choose Time",
    name: "time",
    type: "date-picker-input",
    defaultAny: "Any Time",
    onChange: (e) =>
      setFilterObject((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      })),
  },
  {
    title: "Choose Items To Show",
    name: "type",
    type: "label-input-select",
    options: [
      { name: "5", value: "5" },
      { name: "10", value: "10" },
      { name: "15", value: "15" },
      { name: "20", value: "20" },
      { name: "25", value: "25" },
      { name: "30", value: "30" },
      { name: "35", value: "35" },
    ],
    defaultAny: 5,
    onChange: (e) => setItemsLimit(e.target.value),
  },
];
