import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import { Referral as ReferralUI } from "./Referral/Referral";
import { Popup } from "./UI/popup/Popup";
import { LevelSystem } from "./UI/levelSystem/LevelSystem";
import { axiosUtility, axiosMultyUtility } from "../utils/axiosUtility";
import { NoHistoryIcon } from "../assets/svg";
import RefTreeSvg from "../assets/svg/RefTreeSvg";
import RefBlockSvg from "../assets/svg/RefBlockSvg";
import {
  referralHistoryThUni,
  referralHistoryThBinary,
  referralTableTypeUni,
  referralTableTypeBinary,
} from "../dummyComponents/referral";

const Referral = (props) => {
  const [uniLVLData, setUniLVLData] = useState([]);
  const [referralLeftRight, setReferralLeftRight] = useState({});
  const [levelSystemPopupActive, setLevelSystemPopupActive] = useState(false);
  const [rebatesTableData, setRebatesTableData] = useState([]);
  const [referralBinaryType, setReferralBinaryType] = useState("visual");
  const [referralTableType, setReferralTableType] = useState("uni");
  const [referralTreeData, setReferralTreeData] = useState([]);
  const [referralTreeCalcs, setReferralTreeCalcs] = useState({});
  const [referralTableData, setReferralTableData] = useState([]);
  const [referralAddress, setReferralAddress] = useState(null);
  const [animateTree, setAnimateTree] = useState(false);
  const [lvlData, setLvlData] = useState(false);
  const [activeTreeUser, setActiveTreeUser] = useState({ user_address: "" });
  const [referralHistoryTableLoading, setReferralHistoryTableLoading] =
    useState(false);
  const [referralHistoryType, setReferralHistoryType] = useState("uni");
  const [rebatesCurrentPageUni, setRebatesCurrentPageUni] = useState(1);
  const [rebatesPaginationTotalUni, setRebatesPaginationTotalUni] = useState(1);
  const [referralTotal, setReferralTotal] = useState(false);
  const [tableFilterOutcomingData, setTableFilterOutcomingData] = useState({});
  const [parentOrChild, setParentOrChild] = useState("");
  const [parentName, setParentName] = useState("");

  const userData = useSelector((state) => state.appState?.userData);
  const isActive = useSelector((state) => state.appState?.userData?.active);
  const appState = useSelector((state) => state.appState);
  const account = useSelector((state) => state.connect.address);

  const tableFilterData = {
    search: {
      options: [],
    },
    selects: [
      {
        name: "User Level",
        value: "lvl",
        options: lvlData?.length
          ? lvlData?.map((item, index) => ({
              key: "uniqueKey_" + index,
              name: "Lvl " + (+index + 1),
              value: +index + 1,
            }))
          : [],
      },
    ],
  };

  let tableVisualType =
    referralTableType === "binary" ? (
      <div className={`referral-inner-table-more`}>
        <div
          className={`referral-table-more-svg ${
            referralBinaryType === "visual"
              ? "referral-table-more-svg_active"
              : ""
          }`}
          onClick={() => setReferralBinaryType("visual")}
        >
          <RefTreeSvg />
        </div>
        <div
          className={`referral-table-more-svg ${
            referralBinaryType === "table"
              ? "referral-table-more-svg_active"
              : ""
          }`}
          onClick={() => setReferralBinaryType("table")}
        >
          <RefBlockSvg />
        </div>
      </div>
    ) : (
      <div style={{ width: "152px", height: "10px" }}></div>
    );

  let tableType = (
    <div className={`referral-inner-table-more`}>
      <div
        className={`referral-table-more-svg ${
          referralTableType === "uni" ? "referral-table-more-svg_active" : ""
        }`}
        onClick={() => {
          setReferralTableType("uni");
          setReferralBinaryType("table");
        }}
      >
        LayerLink
      </div>
      <div
        className={`referral-table-more-svg ${
          referralTableType === "binary" ? "referral-table-more-svg_active" : ""
        }`}
        onClick={() => setReferralTableType("binary")}
      >
        PairLink
      </div>
    </div>
  );

  // function isValidResponseData(data) {
  //   return data !== null && typeof data === "object" && "parent_ref" in data;
  // }

  // let referralTreeUserBackClick = async () => {
  //   if (activeTreeUser.user_address !== referralAddress) {
  //     setAnimateTree(false);
  //     try {
  //       const response = await axiosUtility(
  //         "/api/referral/get_referral_parent_address",
  //         { address: activeTreeUser.user_address },
  //         "POST"
  //       );
  //       const { parent_ref, userName } = response?.data;

  //       if (isValidResponseData(response?.data)) {
  //         referralTreeUserClick({
  //           user_address: parent_ref?.referral_address,
  //         });
  //       } else {
  //         console.error("Invalid response data received");
  //       }

  //       setParentName(userName);
  //       setParentOrChild("parent");
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   } else {
  //     return false;
  //   }
  // };

  // let referralTreeUserClick = async (item, pOrCh) => {
  //   setAnimateTree(false);
  //   try {
  //     const response = await axiosUtility(
  //       "/api/referral/get_referral_tree",
  //       { second_address: item.user_address },
  //       "POST"
  //     );

  //     setTimeout(() => {
  //       let mergedResult = mergeArrays(
  //         Array.isArray(response?.data?.final_result)
  //           ? response.data.final_result
  //           : [],
  //         Array.isArray(response?.data?.uni_calcs)
  //           ? response.data.uni_calcs
  //           : [],
  //         Array.isArray(response?.data?.binary_calcs)
  //           ? response.data.binary_calcs
  //           : []
  //       );

  //       setReferralTreeData(mergedResult);
  //       setActiveTreeUser(item);

  //       if (pOrCh) {
  //         setParentOrChild(pOrCh);
  //       }

  //       setTimeout(() => {
  //         setAnimateTree(true);
  //       }, 500);
  //     }, 600);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  let referralTreeAdd = async (lvl, position) => {
    try {
      const response = await axiosUtility(
        "/api/referral/get_referral_code",
        {
          main_address: referralAddress,
          address: activeTreeUser.user_address,
          lvl: lvl,
          position: position,
        },
        "POST"
      );

      navigator.clipboard.writeText(response?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const referralTreeTableTh = useMemo(() => {
    if (referralTableType === "binary") {
      return referralTableTypeBinary;
    } else {
      return referralTableTypeUni;
    }
  }, [referralTableType]);

  // useEffect(() => {
  //   if (
  //     tableFilterOutcomingData?.selects?.lvl ||
  //     tableFilterOutcomingData?.search?.value
  //   ) {
  //     const getUniData = async () => {
  //       try {
  //         const response = await axiosUtility(
  //           "/api/referral/get_referral_data_uni",
  //           {
  //             limit: 5,
  //             page: rebatesCurrentPageUni,
  //             lvl:
  //               tableFilterOutcomingData?.selects?.lvl === "all"
  //                 ? undefined
  //                 : tableFilterOutcomingData?.selects?.lvl,
  //             search: tableFilterOutcomingData?.search?.value,
  //           },
  //           "POST"
  //         );

  //         setReferralTableData(response?.data);
  //       } catch (err) {
  //         console.log(err);
  //         toast.error(
  //           err?.response?.data?.message ?? "Could not apply filter",
  //           {
  //             autoClose: 3000,
  //           }
  //         );
  //       }
  //     };

  //     getUniData();
  //   }
  // }, [
  //   tableFilterOutcomingData?.selects?.lvl,
  //   tableFilterOutcomingData?.search?.value,
  // ]);

  // const generateTreeTableData = async (table, page) => {
  //   try {
  //     const response = await axiosUtility(
  //       `/api/referral/${
  //         table === "binary" ? "get_referral_data" : "get_referral_data_uni"
  //       }`,
  //       {
  //         limit: 20,
  //         page: page || 1,
  //       },
  //       "POST"
  //     );

  //     setReferralTableData(response?.data);
  //     setBinaryTreePageTotalUni(data?.total_page);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  
  // function mergeArrays(final_result, uni_calcs, binary_calcs) {
  //   for (const levelObj of final_result) {
  //     const { documents } = levelObj;

  //     for (let doc of documents) {
  //       const { user_address: address } = doc;

  //       for (const uni_calc of uni_calcs) {
  //         if (uni_calc.address === address) {
  //           Object.assign(doc, { ...uni_calc });
  //         }
  //       }

  //       for (const binary_calc of binary_calcs) {
  //         if (binary_calc.address === address) {
  //           Object.assign(doc, { ...binary_calc });
  //         }
  //       }
  //     }
  //   }

  //   return final_result;
  // }

  const getResponse = useCallback(
    debounce(async () => {
      if (!appState?.accountSigned) return;

      setLvlData([]);
      setAnimateTree(false);

      const params = [
        // {
        //   type: "POST",
        //   url: "/api/referral/binary_comission_count_user",
        //   body: {
        //     address: userData?.address?.toLowerCase(),
        //   },
        // },
        // {
        //   type: "POST",
        //   url: "/api/referral/uni_comission_count_user",
        //   body: {
        //     address: userData?.address?.toLowerCase(),
        //   },
        // },
        {
          type: "POST",
          url: "/api/referral/get_referral_global_data",
          body: {},
        },
        // {
        //   type: "POST",
        //   url: "/api/referral/get_referral_tree",
        //   body: {},
        // },
        // {
        //   type: "POST",
        //   url: "/api/referral/get_referral_options",
        //   body: {
        //     name: "Uni",
        //   },
        // },
      ];

      try {
        let responses = await axiosMultyUtility(params);
        // const mergedResult = mergeArrays(
        //   responses[3]?.data?.final_result,
        //   responses[3]?.data?.uni_calcs ?? [],
        //   responses[3]?.data?.binary_calcs ?? []
        // );

        // setReferralLeftRight((prev) => ({
        //   ...prev,
        //   ...responses[0]?.data?.results,
        //   uni: responses[1]?.data?.results,
        // }));
        setReferralTotal(responses[0]?.data);
        // setReferralTreeData(responses[1]?.data.final_result);
        // setReferralTreeCalcs({
        //   binary: responses[3]?.data?.binary_calcs ?? [],
        //   uni: responses[3]?.data?.uni_calcs ?? [],
        // });
        // const lvlOptions =
        //   responses[4]?.data?.object_value?.uniData?.lvlOptions
        //     ?.maxCommPercentage;
        // setLvlData(lvlOptions);
        // setUniLVLData(lvlOptions);
      } catch (error) {
        console.error("Error fetching referral data:", error);
      }

      setAnimateTree(true);
    }, 300),
    [appState?.accountSigned, userData?.address, account]
  );

  const binaryComissionCountUser = useCallback(
    debounce(async (address) => {
      try {
        const response = await axiosUtility(
          "/api/referral/binary_comission_count_user",
          {
            address: address?.toLowerCase(),
          },
          "POST"
        );
        setReferralLeftRight((prev) => ({
          ...prev,
          ...response?.data?.results,
          uni: response?.data?.results,
        }));
      } catch (error) {
        console.error("Error fetching binary commission count:", error);
        throw error;
      }
    }, 1000),
    []
  );

  useEffect(() => {
    getResponse();
  }, [getResponse]);

  useEffect(() => {
    if (appState?.userData?.address) {
      setReferralAddress(appState?.userData?.address);
      setActiveTreeUser({
        user_address: appState?.userData?.address,
      });
    }
  }, [appState?.userData?.address]);

  // useEffect(() => {
  //   if (appState?.accountSigned) {
  //     generateTreeTableData(referralTableType, binaryTreePageUni);
  //   }
  // }, [
  //   appState?.accountSigned,
  //   referralTableType,
  //   binaryTreePageUni,
  //   referralBinaryType,
  // ]);

  const getReferralHistory = async (type) => {
    try {
      setReferralHistoryTableLoading(true);

      const response = await axiosUtility(
        type === "uni"
          ? `/api/referral/get_referral_uni_transactions`
          : `/api/referral/get_referral_binary_transactions`,
        {
          limit: 25,
          page: rebatesCurrentPageUni,
        },
        "POST"
      );

      setRebatesPaginationTotalUni(response?.data?.total_page);
      setRebatesTableData(response?.data?.transaction);
      setReferralHistoryTableLoading(false);
    } catch (e) {
      setReferralHistoryTableLoading(false);
      console.log(e?.response);
    }
  };

  useEffect(() => {
    if (appState?.accountSigned) {
      getReferralHistory(referralHistoryType ?? "uni");
    }
  }, [appState?.accountSigned, rebatesCurrentPageUni, referralHistoryType]);

  const referralHistoryTableEmpty = {
    label: "No Referral History",
    icon: <NoHistoryIcon />,
  };

  let referralRebatesTotal = [
    // {
    //   title: "PairLink Connections",
    //   value: referralTotal?.binary_users,
    // },
    // {
    //   title: "PairLink Comission This Month",
    //   value:
    //     referralTotal?.binary_comission_this_month &&
    //     referralTotal?.binary_comission_this_month.length > 0 + " AONE"
    //       ? referralTotal?.binary_comission_this_month[0]?.totalAmount?.toLocaleString(
    //           "en-US",
    //           { minimumFractionDigits: 2, maximumFractionDigits: 2 }
    //         )
    //       : 0 + " AONE",
    // },
    // {
    //   title: "PairLink Comission Total",
    //   value:
    //     referralTotal?.binary_comission_total &&
    //     referralTotal?.binary_comission_total.length > 0
    //       ? referralTotal?.binary_comission_total[0]?.totalAmount?.toLocaleString(
    //           "en-US",
    //           { minimumFractionDigits: 2, maximumFractionDigits: 2 }
    //         ) + " AONE"
    //       : 0 + " AONE",
    // },
    {
      title: "LayerLink Connections",
      value: referralTotal?.uni_users,
    },
    {
      title: "LayerLink Reward This Month",
      value:
        referralTotal?.uni_comission_this_month &&
        referralTotal?.uni_comission_this_month.length > 0
          ? referralTotal?.uni_comission_this_month[0]?.totalAmount?.toLocaleString(
              "en-US",
              { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            ) + " AONE"
          : 0 + " AONE",
    },
    {
      title: "LayerLink Reward Total",
      value:
        referralTotal?.uni_comission_total &&
        referralTotal?.uni_comission_total.length > 0
          ? referralTotal?.uni_comission_total[0]?.totalAmount?.toLocaleString(
              "en-US",
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            ) + " AONE"
          : 0 + " AONE",
    },
  ];

  // const referralHistoryRightButtons = (
  //   <div className={`referral-inner-table-more`}>
  //     <div
  //       className={`referral-table-more-svg ${
  //         referralHistoryType === "uni" ? "referral-table-more-svg_active" : ""
  //       }`}
  //       onClick={() => {
  //         setReferralHistoryType("uni");
  //       }}
  //     >
  //       LayerLink
  //     </div>
  //     <div
  //       className={`referral-table-more-svg ${
  //         referralHistoryType === "binary"
  //           ? "referral-table-more-svg_active"
  //           : ""
  //       }`}
  //       onClick={() => setReferralHistoryType("binary")}
  //     >
  //       PairLink
  //     </div>
  //   </div>
  // );

  const referralTreeMainAddressData = useMemo(() => {
    const matchingUni = referralTreeCalcs?.uni?.find(
      (item) => item.address === referralAddress
    );

    const matchingBinary = referralTreeCalcs?.binary?.find(
      (item) => item.address === referralAddress
    );

    const obj = {
      ...referralLeftRight,
      name: userData?.meta?.name,
      external_address: userData?.account_owner,
      user_address: referralAddress,
      total_staked: userData?.stakedTotal,
    };

    if (matchingUni) {
      obj.uni = matchingUni?.amount;
    }

    if (matchingBinary) {
      const { address, ...otherProps } = matchingBinary;

      obj.total_right = referralLeftRight?.total_right;
      obj.total_left = referralLeftRight?.left_total;
      obj.all_amount_sum = referralLeftRight?.all_amount_sum;
      obj.total_staked = userData?.stakedTotal;
      obj.users_sum_left = otherProps?.users_sum_left;
      obj.users_sum_right = otherProps?.users_sum_right;
    }

    return obj;
  }, [
    userData?.stakedToday,
    userData?.stakedTotal,
    userData?.meta?.name,
    referralAddress,
    referralLeftRight,
    referralTreeCalcs,
    referralAddress,
  ]);

  const referralTreeActiveUserData = useMemo(() => {
    const matchingUni = referralTreeCalcs?.uni?.find(
      (item) => item.address === activeTreeUser.user_address
    );

    const matchingBinary = referralTreeCalcs?.binary?.find(
      (item) => item.address === activeTreeUser.user_address
    );

    if (matchingUni) {
      obj.uni = matchingUni?.amount;
    }

    const obj = {
      ...referralLeftRight,
      name: parentName === "Unknown" ? "*****" : parentName,
      isParent: true,
    };

    if (matchingBinary) {
      const {
        address,
        // total_right,
        // left_total,
        // all_amount_sum,
        total_staked_amount,
        users_sum_left,
        users_sum_right,
      } = matchingBinary;

      obj.user_address = address;
      obj.external_address = address;
      obj.total_right = referralLeftRight.total_right;
      obj.total_left = referralLeftRight.left_total;
      obj.all_amount_sum = referralLeftRight.all_amount_sum;
      obj.total_staked = total_staked_amount;
      obj.users_sum_left = users_sum_left;
      obj.users_sum_right = users_sum_right;
    }

    return obj;
  }, [activeTreeUser, referralAddress]);

  const referralHistoryTableHead =
    referralHistoryType === "uni"
      ? referralHistoryThUni
      : referralHistoryThBinary;

  const referralHistoryTableType =
    referralHistoryType === "uni"
      ? "referral-history"
      : "referral-history-binary";

  return (
    <>
      <ReferralUI
        referralLeftRight={referralLeftRight}
        setReferralLeftRight={setReferralLeftRight}
        binaryComissionCountUser={binaryComissionCountUser}
        referralTableType={referralTableType}
        referralAddress={referralAddress}
        referralTreeActiveAddress={
          parentOrChild === "parent"
            ? referralTreeActiveUserData
            : activeTreeUser
        }
        referralTreeActive={false/*animateTree*/}
        referralTreeMainAddressData={referralTreeMainAddressData}
        referralBinaryType={referralBinaryType}
        referralTreeBtnsLeft={tableVisualType}
        referralTreeBtnsRight={tableType}
        referralTreeData={referralTreeData}
        //referralTreeUserClick={referralTreeUserClick}
        //referralTreeUserBackClick={referralTreeUserBackClick}
        referralBackActive={activeTreeUser.user_address !== referralAddress}
        referralTreeAddClick={referralTreeAdd}
        referralTreeTableHead={referralTreeTableTh}
        referralTreeTableData={referralTableData?.list}
        rebatesTableData={rebatesTableData}
        //referralHistoryButtonsRight={referralHistoryRightButtons}
        referralHistoryTableHead={referralHistoryTableHead}
        referralHistoryTableType={referralHistoryTableType}
        referralHistoryTableEmpty={referralHistoryTableEmpty}
        referralHistoryTableLoading={referralHistoryTableLoading}
        referralHistoryPaginationCurrent={rebatesCurrentPageUni}
        referralHistoryPaginationTotal={rebatesPaginationTotalUni}
        referralHistoryPaginationEvent={(page) => {
          setRebatesCurrentPageUni(page);
        }}
        referralRebatesTotal={referralRebatesTotal}
        handleLevelSystem={() => setLevelSystemPopupActive(true)}
        isActive={isActive}
        uniLVLData={uniLVLData}
        disabledAccount={!userData?.active && userData?.step === "6"}
        tableFilterData={tableFilterData}
        setTableFilterOutcomingData={setTableFilterOutcomingData}
        helpSupportClick={props.helpSupportClick}
      />
    </>
  );
};

export default React.memo(Referral);
