import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

// svg
import Notifications from "../../../../assets/svg/Notifications";
import MetaMask from "../../../../assets/svg/MetaMask2";
import { Warning } from "../../../../assets/svg";

// components
import { Button } from "../../../UI/button/Button";
export const NavbarHelper = ({
  type,
  sideBar,
  sideBarOpen,
  verified,
  account,
  onClick,
  modules,
  initialRegister,
  setInitialRegister,
  loginWithEmail,
  loggedWithEmail,
  width,
  handleDeposit,
  handleTransfer,
  handleWithdraw,
  isSigned,
  AccountVersion
}) => {
  const [activeLink, setActiveLink] = useState("");
  const [transactionType, setTransactionType] = useState("");

  function maskAddress(address) {
    const lengthToShow = width < 540 ? 3 : 6;
    if (address?.length <= lengthToShow * 2) {
      return address;
    }
    const start = address?.substring(0, lengthToShow);
    const end = address?.substring(address?.length - lengthToShow);
    return `${start}...${end}`;
  }

  const maskedAccount = maskAddress(account);

  let element = null;

  useEffect(() => {
    if (sideBarOpen) {
      setTransactionType("");
    }
  }, [sideBarOpen]);

  if (type === "navbar") {
    element = (
      <>
        {modules.dashboard === "true" && (
          <NavLink
            className={`${activeLink === "/dashboard" ? "active" : ""} link`}
            to="/dashboard"
            onClick={() => setActiveLink("/dashboard")}
          >
             Dashboard
          </NavLink>
        )}
        {isSigned && (
          <NavLink
            className={`${activeLink === "/top-up" ? "active" : ""} link`}
            to="/top-up"
            onClick={() => setActiveLink("/top-up")}
          >
            Top Up
          </NavLink>
        )}
        {isSigned && (
          <div
            className={`${transactionType === "transfer" ? "active" : ""} link`}
            onClick={() => {
              setTransactionType("transfer");
              handleTransfer("main", "ATAR");
            }}
            style={{ cursor: "pointer" }}
          >
            Transfer
          </div>
        )}
        {isSigned && (
          <div
            className={`${transactionType === "deposit" ? "active" : ""} link`}
            onClick={() => {
              setTransactionType("deposit");
              handleDeposit("deposit");
            }}
            style={{ cursor: "pointer" }}
          >
            Deposit
          </div>
        )}
        {isSigned && (
          <div
            className={`${transactionType === "withdraw" ? "active" : ""} link`}
            onClick={() => {
              setTransactionType("withdraw");
              handleWithdraw("main", "ATAR");
            }}
            style={{ cursor: "pointer" }}
          >
            Withdraw
          </div>
        )}
        {/* {modules.trade === "true" && (
          <NavLink
            className={`${activeLink === "/trade" ? "active" : ""} link`}
            to="/trade"
            onClick={() => setActiveLink("/trade")}
          >
            Trade
          </NavLink>
        )} */}
        {modules.staking === "true" && (
          <NavLink
            className={`${activeLink === "/staking" ? "active" : ""} link`}
            to="/staking"
            onClick={() => setActiveLink("/staking")}
          >
            Staking
          </NavLink>
        )}

{console.log("AccountVersion value:", AccountVersion)}
{console.log("AccountVersion type:", typeof AccountVersion)}

         {modules.staking === "true" && AccountVersion !== 2 && (
          <NavLink
            className={`${activeLink === "/staking-old" ? "active" : ""} link`}
            to="/staking-old"
            onClick={() => setActiveLink("/staking-old")}
          >
          Pioneer Staking
          </NavLink>
        )}
        {modules.referral === "true" && (
          <NavLink
            className={`${activeLink === "/referral" ? "active" : ""} link`}
            to="/referral"
            onClick={() => setActiveLink("/referral")}
          >
            Referral
          </NavLink>
        )}
        <NavLink
          className={`${activeLink === "/extensions" ? "active" : ""} link`}
          to="/extensions"
          onClick={() => setActiveLink("/extensions")}
        >
          Extensions
        </NavLink>
      </>
    );
  }

  if (type === "notification") {
    element = (
      <>
        {modules.notify === "true" && (
          <span
            onClick={onClick}
            className={`${
              sideBar === "notifications" && sideBarOpen && "activeNotify"
            } notify`}
          >
            <Notifications className="notificationSvg" />
          </span>
        )}
      </>
    );
  }

  if (type === "connect") {
    element = (
      <div className={`connect`}>
        {account ? (
          <Button
            label={
              <span className="addressWrapper">
                {verified ? (
                  <MetaMask className="MetaMask" width="24" />
                ) : (
                  <Warning className="Warning" />
                )}
                <p className="address">{maskedAccount}</p>
              </span>
            }
            onClick={initialRegister ? () => setInitialRegister(true) : onClick}
            type="btn-secondary"
            element="button"
            size="btn-sm"
            arrow="arrow-right"
          />
        ) : (
          <div className="withEmailContainer">
            {!loggedWithEmail && (
              <Button
                element="button"
                label="Login"
                onClick={() => loginWithEmail(true)}
                type="btn-secondary"
                size="btn-sm"
                customStyles={{ marginRight: "10px" }}
              />
            )}
            <Button
              element="button"
              label={loggedWithEmail ? "My Account" : "Connect"}
              onClick={
                initialRegister && !loggedWithEmail
                  ? () => setInitialRegister(true)
                  : onClick
              }
              type="btn-primary"
              size="btn-sm"
              customStyles={{ whiteSpace: "nowrap" }}
            />
          </div>
        )}
      </div>
    );
  }
  return element;
};
