import React, { useEffect, useState, createRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Staking as StakingUI } from "../components/Staking/Staking";
import { Calculator } from "./Calculator/Calculator";
import { toast } from "react-toastify";
import Web3 from "web3";
import { Button } from "./UI/button/Button";
import { Popup } from "./UI/popup/Popup";

// hooks
import { useConnectMeta } from "../hooks/use-connect";
import { useStake } from "../hooks/use-stake";
import { useTableParameters } from "../hooks/useTableParameters";
import { useMobileWidth } from "../hooks/useMobileWidth";
import { useOnScreen } from "../hooks/useOnScreen";
import { useApp } from "../hooks/use-app";
import axios from "../api/axios";
import { axiosUtility } from "../utils/axiosUtility";

import { AddSquareIcon } from "../assets/svg";
import { accountSummaryData } from "../dummyComponents/staking";

const Staking = ({ translates, helpSupportClick }) => {
  const Router = process.env.REACT_APP_STAKING_CONTRACT_ADDRESS;
  const tokenAddress = process.env.REACT_APP_TOKEN_ADDRESS;

  const [createStakingPopUpActive, setCreateStakingPopUpActive] =
    useState(false);
  const [approveResponse, setApproveResponse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [fetchCount, setFetchCount] = useState(0);
  const [apyPercent, setApyPercent] = useState(0);
  const [walletBalance, setWalletBalance] = useState(0);
  const [unstakeLoading, setUnstakeLoading] = useState(false);
  const [harvestLoading, setHarvestLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [balanceStakeLoading, setBalanceStakeLoading] = useState(false);
  const [stakingLoading, setStakingLoading] = useState(false);
  const [currencyStakes, setCurrencyStakes] = useState([]);
  const [currencyStakesLoading, setCurrencyStakesLoading] = useState(false);
  const [rangeName, setRangeName] = useState("");
  const [watchReferral, setWatchReferral] = useState(true);
  const [referralCodeChecked, setReferralCodeChecked] = useState(false);
  const [referralCodeAlreadyUsed, setReferralCodeAlreadyUsed] = useState(false);
  const [checkReferralCodeState, setCheckReferralCodeState] = useState({
    loading: false,
    message:
      "Referral code is required, please check referral code before staking",
    status: "warning",
  });
  const [referralState, setReferralState] = useState({
    value: "",
    loading: false,
    message: "",
    status: "",
  });

  const infiniteScrollRef = createRef();
  const isLoadMoreButtonOnScreen = useOnScreen(infiniteScrollRef);

  const providerType = useSelector((state) => state.connect.providerType);
  const exchangeRate = useSelector((state) => state.appState?.rates?.atr?.usd);
  const sideBarOpen = useSelector((state) => state.appState.sideBarOpen);
  const appState = useSelector((state) => state.appState);
  const triedReconnect = useSelector((state) => state.appState?.triedReconnect);
  const hasMoreData = useSelector((state) => state.stake.hasMoreData);
  const tiers = useSelector((state) => state.appState?.tiers);
  const tokenBalance = useSelector((state) => state.connect.tokenBalance);
  const accountsData = useSelector((state) => state?.appState?.accountsData);
  const mainAccount = useSelector((state) => state?.appState?.userData);
  const lastConnectionType = useSelector(
    (state) => state.connect.lastConnectionType
  );
  const ranges = useSelector((state) => state.appState?.ranges);
  const currentTier = useSelector(
    (state) => state.appState?.userData?.tier?.value
  );

  const {
    depositAmount,
    balance,
    stakersInfo,
    stackContractInfo,
    timeperiod,
    stakersRecord,
    isAllowance,
    timeperiodDate,
  } = useSelector((state) => state.stake);

  const { width } = useMobileWidth();
  const { updateState } = useApp();
  const { active, account } = useConnectMeta();
  const dispatch = useDispatch();
  const { durationOptions } = useTableParameters("staking");
  const {
    approve,
    stake,
    unstake,
    harvest,
    setMaxWithdrawal: handleMaxClick,
    handleTimeperiodDate,
    handleDepositAmount,
    handleTimePeriod,
    getStackerInfo,
    checkAllowance,
  } = useStake({ Router, tokenAddress });

  const isActive = appState?.userData?.active;

  useEffect(() => {
    if (!referralState.value) {
      setCheckReferralCodeState((prev) => ({
        ...prev,
        loading: false,
        status: "warning",
        message:
          "Referral code is required, please check referral code before staking",
      }));
      setReferralCodeChecked(false);
      return;
    }

    if (watchReferral) {
      setCheckReferralCodeState((prev) => ({
        ...prev,
        loading: true,
      }));
      axios
        .post("/api/referral/check_referral_available", {
          referral_address: referralState.value,
          user_address: account,
        })
        .then((res) => {
          setTimeout(() => {
            setCheckReferralCodeState((prev) => ({
              ...prev,
              loading: false,
              status: "success",
              message: "This referral code is available.",
            }));
            setReferralCodeChecked(true);
          }, 500);
        })
        .catch((e) => {
          let message =
            "Referral code is required, please check referral code before staking";

          if (e?.response?.data?.message === "no space") {
            setReferralCodeAlreadyUsed(true);
            return;
          }

          if (e?.response?.data === "Referral code incorrect") {
            setTimeout(() => {
              setCheckReferralCodeState((prev) => ({
                ...prev,
                loading: false,
                status: "warning",
                message: e?.response?.data,
              }));
              setReferralCodeChecked(false);
            }, 500);

            return;
          }

          setTimeout(() => {
            setCheckReferralCodeState((prev) => ({
              ...prev,
              loading: false,
              status: "warning",
              message: message,
            }));
            setReferralCodeChecked(false);
          }, 500);
        });
    }
  }, [referralState.value, mainAccount, account, watchReferral]);

  useEffect(() => {
    getWalletBalance();
    if (
      account &&
      ((triedReconnect && active) ||
        providerType === "walletConnect" ||
        lastConnectionType === "email")
    ) {
      getStackerInfo(0 + 5 * fetchCount, 10 + 5 * fetchCount)
        .then(() => {
          setIsFetching(false);
          setLoading(false);
        })
        .catch((error) => {
          setIsFetching(false);
          setLoading(false);
        });
    }
  }, [account, triedReconnect, active, fetchCount]);

  useEffect(() => {
    if (
      account &&
      ((triedReconnect && active) || providerType === "walletConnect")
    ) {
      checkAllowance();
    }

    let minRange = ranges.noviceNavigator.min;
    
    if (Number(depositAmount) < minRange) {
      setReferralCodeChecked(false);
      setReferralState({
        value: "",
        loading: false,
        message: "",
        status: "",
      });
    }
  }, [account, triedReconnect, active, depositAmount]);

  // useEffect to trigger refetchStakersRecord on component mount and when dependencies change
  useEffect(() => {
    if (account) {
      refetchStakersRecord();
    }
  }, [account, fetchCount]); // Dependencies that trigger the refetch

  const th = [
    {
      name: "Staked Amount",
      width: 18,
      mobileWidth: width > 400 ? 25 : 100,
      id: 0,
    },
    {
      name: "Duration",
      width: 18,
      mobileWidth: width > 400 ? 25 : 100,
      id: 1,
    },
    {
      name: "Rewards",
      width: 18,
      mobileWidth: 25,
      id: 2,
    },
    {
      name: "Stake Date",
      width: 18,
      id: 3,
    },
    {
      name: "Unstake Date",
      width: 18,
      id: 4,
    },
    {
      name: "APR",
      width: 10,
      mobileWidth: width > 400 ? 25 : false,
      id: 5,
    },
    {
      name: "",
      width: 0,
      id: 6,
      mobileWidth: 35,
      className: "table-button-none",
      onClick: (index) => {
        if (accountsData[2]?.balance < 0) {
          toast.error("Balance is less than 0. Cannot unstake.", {
            autoClose: false,
          });
          return;
        }
        setUnstakeLoading(true);
        unstake(
          index,
          () => {
            setUnstakeLoading(false);
            axios
              .post("api/transactions/unstake_transaction", {
                address: account,
                index,
              })
              .then((res) => {
                refetchStakersRecord();
              })
              .catch((e) => {
                console.log(e);
              });
          },
          () => {
            setUnstakeLoading(false);
          }
        );
      },
    },
    {
      name: "",
      width: 0,
      id: 7,
      mobileWidth: 20,
      className: "table-button-none",
      onClick: (index) => {
        setHarvestLoading(true);
        harvest(
          index,
          (receipt) => {
            setHarvestLoading(false);
            axios
              .post("api/transactions/harvest_transaction", {
                receipt,
              })
              .then((res) => {
                refetchStakersRecord();
              })
              .catch((e) => {
                console.log(e);
              });
          },
          () => {
            setHarvestLoading(false);
          }
        );
      },
    },
  ];

  const currencyStakesTableHead = [
    {
      name: "Staked Amount",
      width: 15,
      mobileWidth: width > 400 ? 25 : 100,
      id: 0,
    },
    {
      name: "Period",
      width: 15,
      mobileWidth: width > 400 ? 25 : 100,
      id: 1,
    },
    {
      name: "Percentage",
      width: 15,
      mobileWidth: width > 400 ? 25 : false,
      id: 2,
    },
    {
      name: "Reward",
      width: 15,
      mobileWidth: width > 400 ? 25 : false,
      id: 3,
    },
    {
      name: "Till Today By Seconds",
      width: 15,
      id: 4,
    },
    {
      name: "Till Unstake Date",
      width: 15,
      id: 5,
    },
    {
      name: "Unstake Date",
      width: 15,
      id: 6,
    },
  ];

  useEffect(() => {
    if (isLoadMoreButtonOnScreen) {
      setIsFetching(true);
      setFetchCount((prevCount) => prevCount + 1);
    }
  }, [isLoadMoreButtonOnScreen]);

  useEffect(() => {
    async function getCurrencyStakes() {
      setCurrencyStakesLoading(true);
      axios
        .post("/api/transactions/get_currency_stakes", {})
        .then((res) => {
          setCurrencyStakes(res?.data);
          setCurrencyStakesLoading(false);
        })
        .catch((e) => {
          setCurrencyStakesLoading(false);
        });
    }
    getCurrencyStakes();
  }, []);

  useEffect(() => {
    if (
      tiers &&
      depositAmount &&
      providerType === "walletConnect"
    ) {
      let prevStakedAmount = 0;

      for (let i = 0; i < stakersRecord.length; i++) {
        let stakedAmount = Number(stakersRecord[i].amount);
        prevStakedAmount = prevStakedAmount + stakedAmount;
      }

      function findTierId(amount, tiers) {
        for (const tier of tiers) {
          let minStakeAmt = Web3.utils.fromWei(tier?.minStakeAmt, "ether");
          let maxStakeAmt = Web3.utils.fromWei(tier?.maxStakeAmt, "ether");

          if (
            amount  >= minStakeAmt &&
            amount <= maxStakeAmt
          ) {
            return tier?.tierId;
          }
        }

        return "0";
      }

      let result = findTierId(parseInt(depositAmount), tiers);
      let apy = tiers?.filter((item) => item.tierId === result);
      let divider = 1;

      if (timeperiod === 5) {
        divider = 2;
      } else {
        divider = 3;
      }

      if (!parseInt(result)) {
        setApyPercent(0);
      } else {
        setApyPercent(
          parseFloat(apy[0]?.bonuses[timeperiod].bonus / 10) / divider
        );
      }
    } 
  }, [depositAmount, tiers, timeperiod, providerType, stakersRecord]);

  useEffect(() => {
    const getNextTier = async (depositAmount) => {
      await axios
      .post("/api/accounts/get_next_tier", {
        amount: depositAmount
      })
      .then((res) => {
        if (res) {
          setRangeName(res.data.nextTier);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    };

    getNextTier(depositAmount);
  }, [depositAmount]);

  const getWalletBalance = async () => {
    let response = await axiosUtility("/api/accounts/get_account", {}, "POST");

    setWalletBalance(
      response?.data?.data?.accountBalances.find(
        (obj) => obj.account_category === "main"
      ).balance
    );
  };

  async function handleAutomaticReferral() {
    try {
      const parts = referralState?.value?.split("_");
      const firstPart = parts[0];
      setReferralState((prev) => ({
        ...prev,
        value: firstPart,
      }));
      setReferralCodeAlreadyUsed(false);
    } catch (e) {
      toast.error(e?.response?.data ?? "something went wrong", {
        autoClose: false,
      });
    }
  }

  async function refetchStakersRecord() {
    try {
      dispatch({
        type: "UPDATE_STAKE_STATE",
        payload: {
          stakersRecord: [], // Reset the stakersRecord in Redux
          stakersInfo: [], // Reset the stakersInfo in Redux
        },
      });
      setLoading(true);
      setIsFetching(false);
      await getStackerInfo(0, 10 + 5 * fetchCount);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  }

  const handleConnect = () => {
    if (sideBarOpen) {
      dispatch({
        type: "SET_SIDE_BAR",
        payload: { sideBarOpen: !sideBarOpen },
      });
    } else {
      dispatch({
        type: "SET_SIDE_BAR",
        payload: { sideBarOpen: !sideBarOpen, sideBar: "connect" },
      });
    }
  };

  const handlePopUpOpen = () => {
    setCreateStakingPopUpActive(true);
    getWalletBalance();
  };

  const tableEmptyData = {
    label: "Stake to earn AONE reward",
    button: (
      <Button
        element={"referral-button"}
        label={"Create Staking"}
        icon={<AddSquareIcon />}
        onClick={handlePopUpOpen}
        customStyles={{ height: "44px", flexDirection: "row" }}
      />
    ),
  };

  function roundUpToTwoDecimals(number) {
    const roundedNumber = Math.ceil(number * 100) / 100;
    return roundedNumber.toFixed(2);
  }

  const handleCalculatorSubmit = async (stakeAfterApprove) => {
    setApproveResponse(null);
    if (!account) {
      handleConnect();
    }

    async function handleStake() {
      stake(
        //roundUpToTwoDecimals(+depositAmount / +appState?.rates?.["atr"]?.usd),
        roundUpToTwoDecimals(+depositAmount),
        async () => {
          let minRange = ranges.noviceNavigator.min;

          if (
            (Number(depositAmount)  >= minRange) &&
            referralCodeChecked &&
            referralState.value
          ) {
            await axios
            .post("api/referral/register_referral", {
              referral_address: referralState.value,
              user_address: account,
              side: "auto",
            })
            .then(async () => {
              await axios
              .post("/api/accounts/manage_extensions", {
                address: account,
                setup: false,
                type: "referral"
              })
              .then(async (res) => {
                if (res?.data?.account) {
                  dispatch({
                    type: "UPDATE_ACTIVE_EXTENSIONS",
                    payload: res.data.account.extensions,
                  });

                  await axios
                  .post(
                    "/api/accounts/activate-account",
                    {
                      address: account,
                    },
                    {
                      timeout: 120000,
                    }
                  )
                  .then((res) => {
                    if (res.data?.account) {
                      updateState();
                    }
                  })
                  .catch((e) => {});
                }
              })
              .catch((e) => {
                toast.error(e?.response?.data?.message ?? "Something went wrong!", {
                  autoClose: false,
                });
              });
            })
            .catch((err) => {
              if (err?.response?.data) {
                toast.error(err?.response?.data, {
                  autoClose: false,
                });

                setStakingLoading(false);
                return;
              }

              setStakingLoading(false);
              toast.error("something went wrong", {
                autoClose: false,
              });
            });
          } else {
            await axios
            .post(
              "/api/accounts/activate-account",
              {
                address: account,
              },
              {
                timeout: 120000,
              }
            )
            .then((res) => {
              if (res.data?.account) {
                updateState();
              }
            })
            .catch((e) => {});
          }

          setStakingLoading(false);
          refetchStakersRecord();
          handleDepositAmount("");
          handleTimePeriod(5);
          setTimeout(() => {
            setCreateStakingPopUpActive(false);
          }, 3000);
        },
        () => {
          setStakingLoading(false);
          toast.error("Staking failed, please try again.", {
            autoClose: false,
          });
        }
      );
    }

    setStakingLoading(true);
    if (account && isAllowance) {
      approve(
        () => {
          setStakingLoading(false);
          toast.success("Approved successfully, please stake desired amount.", {
            autoClose: 8000,
          });
          if (stakeAfterApprove) {
            setStakingLoading(true);
            handleStake();
          }
        },
        () => {
          setBalanceStakeLoading(false);
          setStakingLoading(false);
          toast.error("Approval failed, please try again.", {
            autoClose: false,
          });
        },
      // +depositAmount / (appState?.rates?.atr?.usd ?? 2)
        +depositAmount 
      );
    }
    if (account && !isAllowance) {
      handleStake();
    }
  };

  const handleClose = () => {
    handleTimePeriod(5);
    handleDepositAmount("");
    setCreateStakingPopUpActive(false);
  };

  async function handleWalletSubmit() {
    setBalanceStakeLoading(true);
    const makeWithdrawal = () => {
      axios
        .post("/api/transactions/make_withdrawal", {
          address_to: account,
        // amount: +depositAmount / appState?.rates?.["atr"]?.usd,
         amount: +depositAmount,
          accountType: "ATAR",
          rate: appState?.rates?.["atr"]?.usd,
          fee: 0
        })
        .then((res) => {
          //let hash = res?.data?.hash;

          if (res?.data.success) {
            toast.success(
              "AONE tokens successfully withdrawn. Now you can continue staking.",
              {
                autoClose: 8000,
              }
            );
            handleCalculatorSubmit(true);
          }
        })
        .catch((e) => {
          toast.error(
            e?.response?.data?.message ??
              "Withdrawal failed, please try again.",
            {
              autoClose: false,
            }
          );
          setBalanceStakeLoading(false);
        });
    };

    try {
      // wc
      if (
        depositAmount >
        accountsData.find((acc) => acc.account_category === "main").balance
      ) {
        setBalanceStakeLoading(false);
        return toast.error("Insufficient funds", { autoClose: false });
      }
      
      makeWithdrawal();
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <StakingUI
        account={account}
        walletBalance={walletBalance}
        stackContractInfo={stackContractInfo}
        loading={loading || balanceStakeLoading}
        accountSummaryData={accountSummaryData(stakersInfo, balance)}
        tableHead={th}
        currencyStakesTableHead={currencyStakesTableHead}
        stakersRecord={stakersRecord}
        tableEmptyData={tableEmptyData}
        handlePopUpOpen={handlePopUpOpen}
        hasMoreData={hasMoreData}
        infiniteScrollRef={infiniteScrollRef}
        isFetching={isFetching}
        unstakeLoading={unstakeLoading}
        harvestLoading={harvestLoading}
        isActive={isActive}
        currencyStakes={currencyStakes}
        currencyStakesLoading={currencyStakesLoading}
        balanceStakeLoading={balanceStakeLoading}
        translates={translates}
        helpSupportClick={helpSupportClick}
      />
      {referralCodeAlreadyUsed && (
        <Popup
          popUpElement={
            <div className="confirm-list">
              <p>
                Are you ready to finalize your registration for the specified
                placement?
              </p>
              <Button
                element={"button"}
                size={"btn-lg"}
                type={"btn-primary"}
                label={"Confirm”"}
                active={true}
                customStyles={{
                  width: "100%",
                }}
                onClick={() => {
                  handleAutomaticReferral();
                }}
              />
            </div>
          }
          label={"Referral code is already used"}
          handlePopUpClose={() => setReferralCodeAlreadyUsed(false)}
        />
      )}
      {createStakingPopUpActive && (
        <Popup
          popUpElement={
            <Calculator
              {...{
                durationOptions,
                handleCalculatorSubmit,
                handleMaxClick,
                loading,
                isAllowance,
                walletBalance,
                tokenBalance,
                exchangeRate,
                account,
                timeperiod,
                handleTimePeriod,
                depositAmount,
                handleDepositAmount,
                timeperiodDate,
                handleTimeperiodDate,
                stakingLoading,
                translates: translates,
                handleWalletSubmit,
                hasReferralActive: mainAccount.referralStatus,
                rates: appState?.rates,
                apyPercent,
              }}
              approveResponse={approveResponse}
              isActive={isActive}
              rangeName={rangeName}
              currentTier={currentTier}
              referralState={referralState}
              setReferralState={setReferralState}
              referralCodeChecked={referralCodeChecked}
              checkReferralCodeState={checkReferralCodeState}
            />
          }
          label={"Staking Calculator"}
          handlePopUpClose={handleClose}
          description={"Stake AONE to earn AONE reward"}
        />
      )}
    </>
  );
};

export default Staking;
